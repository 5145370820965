<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToCompanies" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Company Details</h1>
      </div>
    </template>
    <form @submit.prevent="saveCompany">
      <div v-if="company !== null">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 pb-5">
          <div>
            <InputField label="Name" id="name" v-model="company.name" :value="company.name" :disable-validation="true" />
          </div>
          <div>
            <SelectInput label="Company Type" id="type" :options="companyTypeOptions" v-model="company.company_type" :value="company.company_type" :disable-validation="true" />
          </div>
          <div>
            <IntegerInput label="EIN" id="ein" :value="company.ein" v-model.trim="company.ein" :disable-validation="true" />
          </div>
          <div v-if="company.company_type != 'broker'">
            <IntegerInput label="FHA ID" id="fha_id" :value="company.fha_id" v-model.trim="company.fha_id" :disable-validation="true" />
          </div>
        </div>
        <div>
          <label for="is_active" class="font-semibold text-[#212121]">Active</label>
          <CheckboxInput v-model="company.is_active" :value="company.is_active" />
        </div>
      </div>
      <div class="flex justify-end gap-2 mb-5">
        <Button type="submit" :disabled="!isFormValid">Save</Button>
      </div>
    </form>
    <Card title="Company Licenses">
      <template v-slot:action>
        <div class="flex items-center">
          <div>
            <button type="button" class="standard-btn" @click="openAddLicenseDialog">Add License</button>
          </div>
        </div>
      </template>
      <DataTable :headers="headers" :data="licensesData" @action="handleAction" />
    </Card>
  </Card>
  <Dialog :title="isLicenseEditMode ? 'Edit License' : 'Add New License'" :isOpen="isLicenseDialogOpen" @confirm="handleConfirmLicenseDialog" @close="handleCloseLicenseDialog">
    <form @submit.prevent="handleConfirmLicenseDialog">
      <div class="form-group">
        <SelectInput label="State" id="state" :options="stateOptions" v-model="licenseForm.state" :value="licenseForm.state" :validation="{ required: true }" />
        <span v-if="!licenseForm.state && validationErrors.state" class="text-sm text-red">{{ validationErrors.state }}</span>
      </div>
      <br />
      <div class="form-group">
        <RadioInput label="Is License Active?" id="status" :options="radioInputOptions" v-model="licenseForm.status" :value="licenseForm.status" />
      </div>
      <br />
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 pb-5">
        <div class="form-group">
          <DateInput label="Expiration Date" id="expiration_date" v-model="licenseForm.expirationDate" :value="licenseForm.expirationDate" :validation="{ required: true }" />
          <span v-if="!licenseForm.expirationDate && validationErrors.expirationDate" class="text-sm text-red">{{ validationErrors.expirationDate }}</span>
        </div>
        <div class="form-group">
          <InputField label="License No" id="license-no" v-model="licenseForm.licenseNo" :value="licenseForm.licenseNo" :disable-validation="true" />
          <span v-if="!licenseForm.licenseNo && validationErrors.licenseNo" class="text-sm text-red">{{ validationErrors.licenseNo }}</span>
        </div>
      </div>
      <div class="form-actions flex justify-end">
        <Button type="submit">{{ isLicenseEditMode ? 'Save Changes' : 'Add License' }}</Button>
      </div>
    </form>
  </Dialog>
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this product?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>
<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import { US_STATES, USER_HIERARCHIES } from '@/constants';
  import { isSuperUser } from '@/utils';

  export default {
    name: 'CompanyDetail',
    components: {
      Card,
      RadioInput,
      Confirmation,
      DateInput,
      Dialog,
      Button,
      IntegerInput,
      InputField,
      SelectInput,
      CheckboxInput,
      DataTable,
    },
    data() {
      return {
        company: {
          id: null,
          name: '',
          company_type: 'broker',
          ein: 0,
          fha_id: 0,
          is_active: true,
        },
        isLoading: false,
        companyTypeOptions: [
          { label: 'Broker', value: 'broker' },
          { label: 'Lender', value: 'lender' },
        ],
        stateOptions: US_STATES,
        isLicenseDialogOpen: false,
        isDeleteConfirmationOpen: false,
        isLicenseEditMode: false,
        licenseForm: {
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
        },
        validationErrors: {
          state: '',
          expirationDate: '',
          licenseNo: '',
        },
        headers: [
          { key: 'state', label: 'State' },
          { key: 'status', label: 'Status' },
          { key: 'expirationDate', label: 'Expiration Date' },
          { key: 'licenseNo', label: 'License No' },
          { key: 'action', label: 'Action' },
        ],
        licensesData: [],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        userHierarchies: USER_HIERARCHIES,
      };
    },

    async created() {
      if (!isSuperUser()) {
        this.goToCompanies();
        return;
      }
      const companyId = this.$route.params.id;
      if (companyId) {
        this.fetchCompanyData(companyId);
      } else {
        if (!this.company) {
          this.company = {
            name: '',
            company_type: 'broker',
            is_active: false,
          };
        }
      }
    },
    computed: {
      isFormValid() {
        const { name, ein, fha_id, company_type } = this.company;
        const areFieldsFilled = name && ein && (company_type == 'broker' || (company_type == 'lender' && fha_id));
        return areFieldsFilled;
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCompanyData(companyId) {
        this.setLoading(true);
        try {
          await apiService.get(`/company/companies/${companyId}/`).then((response) => {
            this.company = response.data;
            this.licensesData = response.data.licenses.map((license, key) => ({
              id: key + 1,
              dataId: license.id,
              state: license.state,
              active: license.isActive,
              expirationDate: license.expirationDate,
              status: license.isActive ? 'Active' : 'InActive',
              licenseNo: license.licenseNo,
              actions: ['edit', 'delete'],
            }));
          });
        } catch (error) {
          this.$root.showSnackbar(`${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async saveCompany() {
        let payload = {
          ...this.company,
          licenses: this.licensesData,
        };
        if (this.company.company_type == 'broker') payload['fha_id'] = null;

        try {
          await apiService.put(`/company/companies/${this.company.id}/`, payload).then(() => {
            this.$root.showSnackbar('Company updated successfully!', 'success');
            this.$router.push({ name: 'Companies' });
          });
        } catch (error) {
          let errorMessage = '';
          if (error.response && error.response.data) {
            const errorData = error.response.data;

            const firstKey = Object.keys(errorData)[0];
            errorMessage = Array.isArray(errorData[firstKey]) ? errorData[firstKey][0] : errorData[firstKey];
          } else {
            errorMessage = error.message;
          }

          this.$root.showSnackbar(`Error: ${errorMessage}`, 'error');
        }
      },
      resetForm() {
        this.licenseForm = {
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
        };
        this.validationErrors = {
          state: '',
          expirationDate: '',
          licenseNo: '',
        };
      },

      validateForm() {
        this.validationErrors = {
          state: !this.licenseForm.state ? 'State is required' : '',
          expirationDate: !this.licenseForm.expirationDate ? 'Expiration Date is required' : '',
          licenseNo: !this.licenseForm.licenseNo ? 'License No is required' : '',
        };

        return !this.validationErrors.state && !this.validationErrors.expirationDate && !this.validationErrors.licenseNo;
      },

      openAddLicenseDialog() {
        this.resetForm();
        this.isLicenseEditMode = false;
        this.isLicenseDialogOpen = true;
      },

      openEditLicenseDialog(license) {
        this.selectedLicense = license;
        this.licenseForm = {
          ...license,
          expirationDate: license.expirationDate,
          status: license.status == 'Active' ? true : false,
        };
        this.isLicenseEditMode = true;
        this.isLicenseDialogOpen = true;
      },

      handleCloseLicenseDialog() {
        this.isLicenseDialogOpen = false;
      },

      async handleConfirmDeleteConfirmation() {
        const filteredData = this.licensesData.filter((license) => license.id !== this.selectedLicense.id);
        let id = 0;
        this.licensesData = filteredData.map((license) => {
          return {
            ...license,
            id: id++,
          };
        });
      },

      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },

      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditLicenseDialog(item);
        } else if (action === 'delete') {
          this.selectedLicense = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmLicenseDialog() {
        if (!this.validateForm()) {
          return;
        }
        if (this.isLicenseEditMode) {
          this.licensesData = this.licensesData.map((license) => {
            if (this.selectedLicense.id == license.id) {
              return {
                ...license,
                state: this.licenseForm.state,
                active: this.licenseForm.status,
                expirationDate: this.licenseForm.expirationDate,
                status: this.licenseForm.status ? 'Active' : 'InActive',
                licenseNo: this.licenseForm.licenseNo,
              };
            }
            return license;
          });
        } else {
          const newLicenseData = {
            id: this.licensesData.length,
            dataId: -1,
            state: this.licenseForm.state,
            active: this.licenseForm.status,
            expirationDate: this.licenseForm.expirationDate,
            status: this.licenseForm.status ? 'Active' : 'InActive',
            licenseNo: this.licenseForm.licenseNo,
            actions: ['edit', 'delete'],
          };
          let data = this.licensesData;
          data.push(newLicenseData);
          this.licensesData = data;
        }

        this.isLicenseDialogOpen = false;
        this.resetForm();
      },
      goToCompanies() {
        this.$router.push({ name: 'Companies' });
      },
    },
  };
</script>
