<template>
  <div class="flex h-screen justify-between">
    <Sidebar :show="showSidebar" />
    <div class="main-content" :style="{ width: mainContentWidth }">
      <Header @toggleSidebar="toggleSidebar" />
      <div class="p-[10px] overflow-y-auto" style="height: calc(100vh - 70px)">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './components/header/Header.vue';
  import Sidebar from './components/sidebar/Sidebar.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layout',
    components: {
      Header,
      Sidebar,
    },
    data() {
      return {
        showSidebar: false,
      };
    },
    computed: {
      mainContentWidth() {
        return this.showSidebar ? 'calc(100% - 70px)' : 'calc(100% - 270px)';
      },
    },
    methods: {
      toggleSidebar() {
        this.showSidebar = !this.showSidebar;
      },
    },
  };
</script>

<style scoped>
  .main-content {
    background: #f9f9fb;
  }
</style>
