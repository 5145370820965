export const BORROWER_TYPES = {
  CO_BOROWER: 'Co-Borrower',
  NON_BORROWING_SPOUSE: 'Non-Borrowing Spouse',
};

export const USER_HIERARCHIES = {
  SUPER_USER: 'Super User',
  COMPANY_ADMINISTRATOR: 'Company Administrator',
  COMPANY_USER: 'Company User',
};

export const USER_HIERARCHY_OPTIONS = [
  { label: 'Super User', value: 'Super User' },
  { label: 'Company Administrator', value: 'Company Administrator' },
  { label: 'Company User', value: 'Company User' },
];

export const COMPANY_TYPES = {
  broker: 'Broker',
  lender: 'Lender',
};

export const INTEREST_RATE_TYPES = {
  fixed: 'Fixed',
  adjustable: 'Adjustable',
};

export const INDEX_NAMES = {
  '1_month': '1 Month CMT',
  '1_year': '1 Year CMT',
  '10_year': '10 Year CMT',
  not_applicable: 'Not Applicable',
};

export const INTEREST_RATE_TYPE_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Adjustable', value: 'adjustable' },
];

export const INDEX_NAMES_OPTIONS = [
  { label: '1 Month CMT', value: '1_month' },
  { label: '1 Year CMT', value: '1_year' },
  { label: '10 Year CMT', value: '10_year' },
];

export const RADIO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const MARGINS = [
  { label: '1.5%', value: 1.5 },
  { label: '1.675%', value: 1.675 },
  { label: '1.75%', value: 1.75 },
  { label: '1.875%', value: 1.875 },
  { label: '2%', value: 2 },
  { label: '2.125%', value: 2.125 },
  { label: '2.25%', value: 2.25 },
  { label: '2.375%', value: 2.375 },
  { label: '2.5%', value: 2.5 },
  { label: '2.625%', value: 2.625 },
  { label: '2.75%', value: 2.75 },
  { label: '2.875%', value: 2.875 },
  { label: '3%', value: 3 },
];
export const PAYOFF_TYPES = [
  { label: 'Mandatory Obligation', value: 'Mandatory Obligation' },
  { label: 'Tax Payoff', value: 'Tax Payoff' },
  { label: 'Non-mandatory Payoff', value: 'Non-mandatory Payoff' },
  { label: 'Unseasoned HELOC', value: 'Unseasoned HELOC' },
];

export const COMPANY_USER_ROLE_OPTIONS = [
  'Loan officer',
  'Loan officer Manager',
  'Processor',
  'Processor Manager',
  'Underwriter',
  'Underwriter Manager',
  'Closer',
  'Closer Manager',
  'Funder',
  'Funder Manager',
  'Shipper',
  'Shipper Manager',
  'Post-Closer',
  'Post-Closer Manager',
];

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const DIRECTION_OPTIONS = [
  { label: 'E', value: 'E' },
  { label: 'W', value: 'W' },
  { label: 'S', value: 'S' },
  { label: 'N', value: 'N' },
  { label: 'NE', value: 'NE' },
  { label: 'NW', value: 'NW' },
  { label: 'SE', value: 'SE' },
  { label: 'SW', value: 'SW' },
];

export const STREET_TYPES = [
  { label: 'ALLEY', value: 'ALY' },
  { label: 'ANNEX', value: 'ANX' },
  { label: 'ARCADE', value: 'ARC' },
  { label: 'AVENUE', value: 'AVE' },
  { label: 'BAYOU', value: 'BYU' },
  { label: 'BEACH', value: 'BCH' },
  { label: 'BEND', value: 'BND' },
  { label: 'BLUFF', value: 'BLF' },
  { label: 'BLUFFS', value: 'BLFS' },
  { label: 'BOTTOM', value: 'BTM' },
  { label: 'BOULEVARD', value: 'BLVD' },
  { label: 'BRANCH', value: 'BR' },
  { label: 'BRIDGE', value: 'BRG' },
  { label: 'BROOK', value: 'BRK' },
  { label: 'BROOKS', value: 'BRKS' },
  { label: 'BURG', value: 'BG' },
  { label: 'BURGS', value: 'BGS' },
  { label: 'BYPASS', value: 'BYP' },
  { label: 'CAMP', value: 'CP' },
  { label: 'CANYON', value: 'CYN' },
  { label: 'CAPE', value: 'CPE' },
  { label: 'CAUSEWAY', value: 'CSWY' },
  { label: 'CENTER', value: 'CTR' },
  { label: 'CENTERS', value: 'CTRS' },
  { label: 'CIRCLE', value: 'CIR' },
  { label: 'CIRCLES', value: 'CIRS' },
  { label: 'CLIFF', value: 'CLF' },
  { label: 'CLIFFS', value: 'CLFS' },
  { label: 'CLUB', value: 'CLB' },
  { label: 'COMMON', value: 'CMN' },
  { label: 'COMMONS', value: 'CMNS' },
  { label: 'CONCOURSE', value: 'CONC' },
  { label: 'CORNER', value: 'COR' },
  { label: 'CORNERS', value: 'CORS' },
  { label: 'COURSE', value: 'CRSE' },
  { label: 'COURT', value: 'CT' },
  { label: 'COURTS', value: 'CTS' },
  { label: 'COVE', value: 'CV' },
  { label: 'COVES', value: 'CVS' },
  { label: 'CREEK', value: 'CRK' },
  { label: 'CRESCENT', value: 'CRES' },
  { label: 'CREST', value: 'CRST' },
  { label: 'CROSSING', value: 'XING' },
  { label: 'CROSSROAD', value: 'XRD' },
  { label: 'CROSSROADS', value: 'XRDS' },
  { label: 'CURVE', value: 'CURV' },
  { label: 'DALE', value: 'DL' },
  { label: 'DAM', value: 'DM' },
  { label: 'DIVIDE', value: 'DV' },
  { label: 'DRIVE', value: 'DR' },
  { label: 'DRIVES', value: 'DRS' },
  { label: 'ESTATE', value: 'EST' },
  { label: 'ESTATES', value: 'ESTS' },
  { label: 'EXPRESSWAY', value: 'EXPY' },
  { label: 'EXTENSION', value: 'EXT' },
  { label: 'EXTENSIONS', value: 'EXTS' },
  { label: 'FALL', value: 'FALL' },
  { label: 'FALLS', value: 'FLS' },
  { label: 'FERRY', value: 'FRY' },
  { label: 'FIELD', value: 'FLD' },
  { label: 'FIELDS', value: 'FLDS' },
  { label: 'FLAT', value: 'FLT' },
  { label: 'FLATS', value: 'FLTS' },
  { label: 'FORD', value: 'FRD' },
  { label: 'FORDS', value: 'FRDS' },
  { label: 'FOREST', value: 'FRST' },
  { label: 'FORGE', value: 'FRG' },
  { label: 'FORGES', value: 'FRGS' },
  { label: 'FORK', value: 'FRK' },
  { label: 'FORKS', value: 'FRKS' },
  { label: 'FORT', value: 'FT' },
  { label: 'FREEWAY', value: 'FWY' },
  { label: 'GARDEN', value: 'GDN' },
  { label: 'GARDENS', value: 'GDNS' },
  { label: 'GATEWAY', value: 'GTWY' },
  { label: 'GLEN', value: 'GLN' },
  { label: 'GLENS', value: 'GLNS' },
  { label: 'GREEN', value: 'GRN' },
  { label: 'GREENS', value: 'GRNS' },
  { label: 'GROVE', value: 'GRV' },
  { label: 'GROVES', value: 'GRVS' },
  { label: 'HARBOR', value: 'HBR' },
  { label: 'HARBORS', value: 'HBRS' },
  { label: 'HAVEN', value: 'HVN' },
  { label: 'HEIGHTS', value: 'HTS' },
  { label: 'HIGHWAY', value: 'HWY' },
  { label: 'HILL', value: 'HL' },
  { label: 'HILLS', value: 'HLS' },
  { label: 'HOLLOW', value: 'HOLW' },
  { label: 'INLET', value: 'INLT' },
  { label: 'ISLAND', value: 'IS' },
  { label: 'ISLANDS', value: 'ISS' },
  { label: 'ISLE', value: 'ISLE' },
  { label: 'JUNCTION', value: 'JCT' },
  { label: 'JUNCTIONS', value: 'JCTS' },
  { label: 'KEY', value: 'KY' },
  { label: 'KEYS', value: 'KYS' },
  { label: 'KNOLL', value: 'KNL' },
  { label: 'KNOLLS', value: 'KNLS' },
  { label: 'LAKE', value: 'LK' },
  { label: 'LAKES', value: 'LKS' },
  { label: 'LAND', value: 'LAND' },
  { label: 'LANDING', value: 'LNDG' },
  { label: 'LANE', value: 'LN' },
  { label: 'LIGHT', value: 'LGT' },
  { label: 'LIGHTS', value: 'LGTS' },
  { label: 'LOAF', value: 'LF' },
  { label: 'LOCK', value: 'LCK' },
  { label: 'LOCKS', value: 'LCKS' },
  { label: 'LODGE', value: 'LDG' },
  { label: 'LOOP', value: 'LOOP' },
  { label: 'MALL', value: 'MALL' },
  { label: 'MANOR', value: 'MNR' },
  { label: 'MANORS', value: 'MNRS' },
  { label: 'MEADOW', value: 'MDW' },
  { label: 'MEADOWS', value: 'MDWS' },
  { label: 'MEWS', value: 'MEWS' },
  { label: 'MILL', value: 'ML' },
  { label: 'MILLS', value: 'MLS' },
  { label: 'MISSION', value: 'MSN' },
  { label: 'MOTORWAY', value: 'MTWY' },
  { label: 'MOUNT', value: 'MT' },
  { label: 'MOUNTAIN', value: 'MTN' },
  { label: 'MOUNTAINS', value: 'MTNS' },
  { label: 'NECK', value: 'NCK' },
  { label: 'ORCHARD', value: 'ORCH' },
  { label: 'OVERPASS', value: 'OPAS' },
  { label: 'PARK', value: 'PARK' },
  { label: 'PARKS', value: 'PARK' },
  { label: 'PARKWAY', value: 'PKWY' },
  { label: 'PARKWAYS', value: 'PKWY' },
  { label: 'PASS', value: 'PASS' },
  { label: 'PASSAGE', value: 'PSGE' },
  { label: 'PATH', value: 'PATH' },
  { label: 'PIKE', value: 'PIKE' },
  { label: 'PINE', value: 'PNE' },
  { label: 'PINES', value: 'PNES' },
  { label: 'PLACE', value: 'PL' },
  { label: 'PLAIN', value: 'PLN' },
  { label: 'PLAINS', value: 'PLNS' },
  { label: 'PLAZA', value: 'PLZ' },
  { label: 'POINT', value: 'PT' },
  { label: 'POINTS', value: 'PTS' },
  { label: 'PORT', value: 'PRT' },
  { label: 'PORTS', value: 'PRTS' },
  { label: 'PRAIRIE', value: 'PR' },
  { label: 'RADIAL', value: 'RADL' },
  { label: 'RAMP', value: 'RAMP' },
  { label: 'RANCH', value: 'RNCH' },
  { label: 'RAPID', value: 'RPD' },
  { label: 'RAPIDS', value: 'RPDS' },
  { label: 'REST', value: 'RST' },
  { label: 'RIDGE', value: 'RDG' },
  { label: 'RIDGES', value: 'RDGS' },
  { label: 'RIVER', value: 'RIV' },
  { label: 'ROAD', value: 'RD' },
  { label: 'ROADS', value: 'RDS' },
  { label: 'ROUTE', value: 'RTE' },
  { label: 'ROW', value: 'ROW' },
  { label: 'RUE', value: 'RUE' },
  { label: 'RUN', value: 'RUN' },
  { label: 'SHOAL', value: 'SHL' },
  { label: 'SHOALS', value: 'SHLS' },
  { label: 'SHORE', value: 'SHR' },
  { label: 'SHORES', value: 'SHRS' },
  { label: 'SKYWAY', value: 'SKWY' },
  { label: 'SPRING', value: 'SPG' },
  { label: 'SPRINGS', value: 'SPGS' },
  { label: 'SPUR', value: 'SPUR' },
  { label: 'SPURS', value: 'SPUR' },
  { label: 'SQUARE', value: 'SQ' },
  { label: 'SQUARES', value: 'SQS' },
  { label: 'STATION', value: 'STA' },
  { label: 'STRAVENUE', value: 'STRA' },
  { label: 'STREAM', value: 'STRM' },
  { label: 'STREET', value: 'ST' },
  { label: 'STREETS', value: 'STS' },
  { label: 'SUMMIT', value: 'SMT' },
  { label: 'TERRACE', value: 'TER' },
  { label: 'THROUGHWAY', value: 'TRWY' },
  { label: 'TRACE', value: 'TRCE' },
  { label: 'TRACK', value: 'TRAK' },
  { label: 'TRAFFICWAY', value: 'TRFY' },
  { label: 'TRAIL', value: 'TRL' },
  { label: 'TRAILER', value: 'TRLR' },
  { label: 'TUNNEL', value: 'TUNL' },
  { label: 'TURNPIKE', value: 'TPKE' },
  { label: 'UNDERPASS', value: 'UPAS' },
  { label: 'UNION', value: 'UN' },
  { label: 'UNIONS', value: 'UNS' },
  { label: 'VALLEY', value: 'VLY' },
  { label: 'VALLEYS', value: 'VLYS' },
  { label: 'VIADUCT', value: 'VIA' },
  { label: 'VIEW', value: 'VW' },
  { label: 'VIEWS', value: 'VWS' },
  { label: 'VILLAGE', value: 'VLG' },
  { label: 'VILLAGES', value: 'VLGS' },
  { label: 'VILLE', value: 'VL' },
  { label: 'VISTA', value: 'VIS' },
  { label: 'WALK', value: 'WALK' },
  { label: 'WALKS', value: 'WALK' },
  { label: 'WALL', value: 'WALL' },
  { label: 'WAY', value: 'WAY' },
  { label: 'WAYS', value: 'WAYS' },
  { label: 'WELL', value: 'WL' },
  { label: 'WELLS', value: 'WLS' },
];

export const RADIO_INPUT_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const PROPERTY_TYPE_OPTIONS = [
  { label: '2 to 4 Units', value: '2 to 4 Units' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Condominium', value: 'Condominium' },
  { label: 'Cooperative', value: 'Cooperative' },
  { label: 'Farm', value: 'Farm' },
  { label: 'Land', value: 'Land' },
  { label: 'Manufactured Condo', value: 'Manufactured Condo' },
  { label: 'Manufactured Home', value: 'Manufactured Home' },
  { label: 'Multi-Family 4+', value: 'Multi-Family 4+' },
  { label: 'Single Family', value: 'Single Family' },
  { label: 'Site Condominium', value: 'Site Condominium' },
  { label: 'Townhouse', value: 'Townhouse' },
];
