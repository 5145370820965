<template>
  <Card title="FHA Case Number Details">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <SelectInput label="Loan Type:" id="loanType" :options="loanTypeOptions" v-model="formData.loanType" :value="formData.loanType" :disable-validation="true" />
      </div>
    </div>
    <!-- Show below fields when only loan type is HECM to HECM -->
    <div v-if="formData.loanType === 'HECM to HECM'" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <InputField
          label="Previous FHA Case Number"
          id="prevFhaCaseNumber"
          ref="prevFhaCaseNumber"
          :value="formData.prevFhaCaseNumber"
          v-model.trim="formData.prevFhaCaseNumber"
          :disable-validation="true"
        />
      </div>
      <div>
        <MoneyInput
          label="Prior IMIP Paid"
          id="priorImipPaid"
          ref="priorImipPaid"
          :value="formData.priorImipPaid"
          v-model.trim="formData.priorImipPaid"
          :disable-validation="true"
        />
      </div>
      <div>
        <MoneyInput
          label="Prior Max Claim Amount"
          id="priorMaxClaimAmount"
          ref="priorMaxClaimAmount"
          :value="formData.priorMaxClaimAmount"
          v-model.trim="formData.priorMaxClaimAmount"
          :disable-validation="true"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <InputField
          label="FHA Case Number"
          id="fhaCaseNumber"
          ref="fhaCaseNumber"
          :value="formData.fhaCaseNumber"
          v-model.trim="formData.fhaCaseNumber"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Date Case # Assigned"
          id="caseAssignedDate"
          ref="caseAssignedDate"
          :value="formatDate(formData.caseAssignedDate)"
          v-model.trim="formData.caseAssignedDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Date Sent to Counseling"
          id="sentToCounselingDate"
          ref="sentToCounselingDate"
          :value="formatDate(formData.sentToCounselingDate)"
          v-model.trim="formData.sentToCounselingDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Date of Counseling"
          id="counselingDate"
          ref="counselingDate"
          :value="formatDate(formData.counselingDate)"
          v-model.trim="formData.counselingDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="RESPA Application Date"
          id="respaApplicationDate"
          ref="respaApplicationDate"
          :value="formatDate(formData.respaApplicationDate)"
          v-model.trim="formData.respaApplicationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Application Date"
          id="applicationDate"
          ref="applicationDate"
          :value="formatDate(formData.applicationDate)"
          v-model.trim="formData.applicationDate"
          :disable-validation="true"
        />
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Purpose of Loan</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
        <div>
          <CheckboxInput label="Additional Income" id="additionalIncome" :value="formData.additionalIncome" @change="updateField('additionalIncome')" />
        </div>
        <div>
          <CheckboxInput label="Home Improvements" id="homeImprovements" :value="formData.homeImprovements" @change="updateField('homeImprovements')" />
        </div>
        <div>
          <CheckboxInput label="Payment of Taxes" id="paymentOfTaxes" :value="formData.paymentOfTaxes" @change="updateField('paymentOfTaxes')" />
        </div>
        <div>
          <CheckboxInput label="Payment of Insurance" id="paymentInsurance" :value="formData.paymentOfInsurance" @change="updateField('paymentOfInsurance')" />
        </div>
        <div>
          <CheckboxInput label="Leisure" id="leisure" :value="formData.leisure" @change="updateField('leisure')" />
        </div>
        <div>
          <CheckboxInput label="Medical" id="medical" :value="formData.medical" @change="updateField('medical')" />
        </div>
        <div>
          <CheckboxInput
            label="Extinguish Forward Mortgage"
            id="extinguishForwardMortgage"
            :value="formData.extinguishForwardMortgage"
            @change="updateField('extinguishForwardMortgage')"
          />
        </div>
        <div>
          <CheckboxInput label="Other" id="other" :value="formData.other" @change="updateField('other')" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
  <div v-if="formData.id">
    <TabCard class="my-15">
      <ul class="tabs flex items-center">
        <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
          {{ tabOption.title }}
        </li>
      </ul>
    </TabCard>
    <div class="content-container">
      <component :is="activeTabComponent" v-model:caseNumberId="formData.id" />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import RealEstateOwned from './components/RealEstateOwned/index.vue';
  import ExistingFHALoans from './components/ExistingFHALoans/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'CaseNumber',
    components: {
      Button,
      Card,
      DateInput,
      TabCard,
      InputField,
      MoneyInput,
      SelectInput,
      CheckboxInput,
      RealEstateOwned,
      ExistingFHALoans,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        formData: {
          loanType: null,
          fhaCaseNumber: null,
          prevFhaCaseNumber: null,
          priorImipPaid: null,
          priorMaxClaimAmount: null,
          caseAssignedDate: null,
          sentToCounselingDate: null,
          counselingDate: null,
          respaApplicationDate: null,
          applicationDate: null,
          additionalIncome: null,
          homeImprovements: null,
          paymentOfTaxes: null,
          paymentOfInsurance: null,
          leisure: null,
          medical: null,
          extinguishForwardMortgage: null,
          other: null,
        },
        localData: {
          loanType: null,
          fhaCaseNumber: null,
          prevFhaCaseNumber: null,
          priorImipPaid: null,
          priorMaxClaimAmount: null,
          caseAssignedDate: null,
          sentToCounselingDate: null,
          counselingDate: null,
          respaApplicationDate: null,
          applicationDate: null,
          additionalIncome: null,
          homeImprovements: null,
          paymentOfTaxes: null,
          paymentOfInsurance: null,
          leisure: null,
          medical: null,
          extinguishForwardMortgage: null,
          other: null,
        },
        loanTypeOptions: [
          { label: 'HECM Traditional', value: 'HECM Traditional' },
          { label: 'HECM to HECM', value: 'HECM to HECM' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        tabOptions: [
          {
            title: 'Schedule of Real Estate Owned',
            component: RealEstateOwned,
          },
          { title: 'Existing FHA Loans', component: ExistingFHALoans },
        ],
        activeTab: { title: 'Schedule of Real Estate Owned', isActive: true },
      };
    },
    async created() {
      await this.fetchCaseNumber();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCaseNumber() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/case_numbers/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) this.localData = response.data[0];
          else this.resetLocalData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateForm() {
        return true;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/case_numbers/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/case_numbers/`, {
              ...this.formData,
              loan: this.loanId,
            });
          this.$root.showSnackbar(`Case number information has been updated successfully!`, 'success');
          await this.fetchCaseNumber();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      updateField(field) {
        this.formData[field] = !this.formData[field];
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
      resetLocalData() {
        this.localData = {
          loanType: null,
          fhaCaseNumber: null,
          prevFhaCaseNumber: null,
          priorImipPaid: null,
          priorMaxClaimAmount: null,
          caseAssignedDate: null,
          sentToCounselingDate: null,
          counselingDate: null,
          respaApplicationDate: null,
          applicationDate: null,
          additionalIncome: null,
          homeImprovements: null,
          paymentOfTaxes: null,
          paymentOfInsurance: null,
          leisure: null,
          medical: null,
          extinguishForwardMortgage: null,
          other: null,
        };
      },
    },
    watch: {
      loanId: {
        handler(newValue, oldValue) {
          if (newValue && oldValue) {
            this.fetchCaseNumber();
          }
        },
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
    },
  };
</script>
