<template>
  <Card title="Expenses Breakdown">
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Property Charges</th>
            <td class="text-left px-6 py-4">${{ data.propertyCharge.monthly }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Utilities / Maintenance {{ data.livingAreaSquareFootage }} sqft. @ 0.14/sqft.</th>
            <td class="text-left px-6 py-4">${{ data.utilitiesMaintenance.monthly }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Installment Debt</th>
            <td class="text-left px-6 py-4">${{ data.installmentDebt.monthly }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Revolving Debt</th>
            <td class="text-left px-6 py-4">${{ data.revolvingDebt.monthly }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Charge offs</th>
            <td class="text-left px-6 py-4">${{ data.chargeOffs.monthly }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Collections</th>
            <td class="text-left px-6 py-4">${{ data.collectionDebt.monthly }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Automobile Debt</th>
            <td class="text-left px-6 py-4">${{ data.automobileDebt.monthly }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Home Equity Line of Credit</th>
            <td class="text-left px-6 py-4">${{ data.helocDebt.monthly }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Mortgage</th>
            <td class="text-left px-6 py-4">${{ data.mortgageDebt.monthly }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Total Expenses</th>
            <td class="text-left px-6 py-4">${{ data.totalExpenses.monthly }}</td>
          </tr>
        </tbody>
      </table>
    </div></Card
  >
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Expenses',
    components: {
      Card,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    async created() {
      await this.fetchExpenses();
    },
    data() {
      return {
        data: this.initialData(),
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchExpenses() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/loans/${this.loanId}/expenses/`);
          this.data = {
            ...this.data,
            ...response.data.expenses,
          };
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      initialData() {
        return {
          propertyCharge: { yearly: 0, monthly: 0 },
          livingAreaSquareFootage: 0,
          utilitiesMaintenance: { monthly: 0, yearly: 0 },
          installmentDebt: { monthly: 0, yearly: 0 },
          revolvingDebt: { monthly: 0, yearly: 0 },
          chargeOffs: { monthly: 0, yearly: 0 },
          collectionDebt: { monthly: 0, yearly: 0 },
          automobileDebt: { monthly: 0, yearly: 0 },
          helocDebt: { monthly: 0, yearly: 0 },
          mortgageDebt: { monthly: 0, yearly: 0 },
          totalExpenses: { monthly: 0, yearly: 0 },
        };
      },
      resetData() {
        this.data = this.initialData();
      },
    },
    watch: {
      loanId: {
        handler() {
          this.fetchExpenses();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
