<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <div class="relative flex items-center">
      <input
        type="text"
        :id="id"
        :value="inputValue"
        @input="validateInput"
        @keypress="validateNumber"
        class="pl-3"
        :class="['float-input', { invalid: !isValid && !disableValidation }]"
      />
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'FloatInput',
    props: {
      label: String,
      id: String,
      value: {
        type: [String, Number],
        default: 0,
      },
      disableValidation: Boolean,
    },
    data() {
      return {
        inputValue: this.value?.toString() || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput(event) {
        this.isDirty = true;
        this.inputValue = event.target.value.replace(/^0+(?!\.|$)/, '');

        if (this.inputValue === '') {
          this.inputValue = '0';
        }

        const regex = /^\d+(\.\d{0,2})?$/;
        this.isValid = regex.test(this.inputValue);
      },
      validateNumber(event) {
        const charCode = event.charCode;
        const charStr = String.fromCharCode(charCode);

        if (
          (charCode < 48 || charCode > 57) && // Not a digit
          charStr !== '.' // Not a decimal point
        ) {
          event.preventDefault();
        } else if (charStr === '.' && this.inputValue.includes('.')) {
          event.preventDefault();
        } else {
          const decimalIndex = this.inputValue.indexOf('.');
          const cursorPosition = event.target.selectionStart;
          const selectionEnd = event.target.selectionEnd;
          const selectedTextLength = selectionEnd - cursorPosition;

          if (decimalIndex !== -1 && cursorPosition > decimalIndex) {
            const decimalPart = this.inputValue.split('.')[1];
            const newDecimalPartLength = decimalPart.length - selectedTextLength + 1;

            if (newDecimalPartLength > 2) {
              event.preventDefault();
            }
          }
        }
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required and must be a valid number with up to two decimal places.`;
        }
        return '';
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue !== null && newValue !== undefined ? newValue.toString() : '';
      },
      inputValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    mounted() {
      this.inputValue = this.value?.toString() ?? '';
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  input {
    padding-left: 15px;
  }
</style>
