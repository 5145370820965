<template>
  <Card>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField label="First Name" id="firstName" ref="firstName" :value="formData.firstName" v-model.trim="formData.firstName" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Middle Name" id="middleName" ref="middleName" :value="formData.middleName" v-model.trim="formData.middleName" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Last Name" id="lastName" ref="lastName" :value="formData.lastName" v-model.trim="formData.lastName" :disable-validation="true" />
      </div>
      <div>
        <PhoneNumberInputField label="Home Phone" id="homePhone" ref="homePhone" :value="formData.homePhone" v-model.trim="formData.homePhone" :disable-validation="true" />
      </div>
      <div>
        <PhoneNumberInputField
          label="Mobile Phone"
          id="mobilePhone"
          ref="mobilePhone"
          :value="formData.mobilePhone"
          v-model.trim="formData.mobilePhone"
          :disable-validation="true"
        />
      </div>
      <div>
        <InputField label="Email Address" id="email" ref="email" :value="formData.email" v-model.trim="formData.email" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Address Line 1" id="addressLine1" ref="addressLine1" :value="formData.addressLine1" v-model.trim="formData.addressLine1" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Address Line 2" id="addressLine2" ref="addressLine2" :value="formData.addressLine2" v-model.trim="formData.addressLine2" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Zip Code" id="zipCode" ref="zipCode" :value="formData.zip" v-model.trim="formData.zip" :disable-validation="true" />
      </div>
      <div>
        <InputField label="City" id="city" ref="city" :value="formData.city" v-model.trim="formData.city" :disable-validation="true" />
      </div>
      <div>
        <SelectInput label="State" id="state" :options="stateOptions" v-model="formData.state" :value="formData.state" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Relationship" id="relationship" ref="relationship" :value="formData.relationship" v-model.trim="formData.relationship" :disable-validation="true" />
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Relationship Details</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput
            label="Information Release Authorization"
            id="notApplicable"
            :value="formData.informationReleaseAuthorization"
            @change="updateField('informationReleaseAuthorization')"
          />
        </div>
        <div>
          <CheckboxInput label="Alternate Contact" id="notApplicable" :value="formData.alternateContact" @change="updateField('alternateContact')" />
        </div>
        <div>
          <CheckboxInput label="Power of Attorney" id="notApplicable" :value="formData.powerOfAttorney" @change="updateField('powerOfAttorney')" />
        </div>
        <div>
          <CheckboxInput label="Guardian" id="notApplicable" :value="formData.guardian" @change="updateField('guardian')" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { US_STATES } from '@/constants/index.js';
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AlternateContact',
    components: {
      Card,
      Button,
      InputField,
      CheckboxInput,
      PhoneNumberInputField,
      SelectInput,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        formData: {
          firstName: '',
          middleName: '',
          lastName: '',
          relationship: '',
          addressLine1: '',
          addressLine2: '',
          zip: '',
          city: '',
          state: '',
          homePhone: '',
          mobilePhone: '',
          email: '',
          informationReleaseAuthorization: false,
          alternateContact: false,
          powerOfAttorney: false,
          guardian: false,
        },
        localData: {
          firstName: '',
          middleName: '',
          lastName: '',
          relationship: '',
          addressLine1: '',
          addressLine2: '',
          zip: '',
          city: '',
          state: '',
          homePhone: '',
          mobilePhone: '',
          email: '',
          informationReleaseAuthorization: false,
          alternateContact: false,
          powerOfAttorney: false,
          guardian: false,
        },
        stateOptions: US_STATES,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchAlternateContact() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/alternate_contacts/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          if (this.localData.id) await apiService.patch(`/loan/alternate_contacts/${this.localData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/alternate_contacts/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Alternate Contact information has been updated successfully!`, 'success');
          await this.fetchAlternateContact();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      updateField(field) {
        this.formData[field] = !this.formData[field];
      },
      resetLocalData() {
        this.localData = {
          firstName: '',
          middleName: '',
          lastName: '',
          relationship: '',
          addressLine1: '',
          addressLine2: '',
          zip: '',
          city: '',
          state: '',
          homePhone: '',
          mobilePhone: '',
          email: '',
          informationReleaseAuthorization: false,
          alternateContact: false,
          powerOfAttorney: false,
          guardian: false,
        };
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchAlternateContact();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
