<template>
  <Card title="Document Manager">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <Card v-for="(setting, index) in settingsOptions" :key="index">
        <template v-slot:title>
          <div class="flex items-center gap-2">
            <component :is="setting.iconComponent" />
            <h1 class="text-lg font-semibold leading-none">{{ setting.title }}</h1>
          </div>
        </template>
        <template v-slot:action>
          <button class="p-2 rounded bg-primary" @click="navigate(setting.page)">
            <img src="../../assets/image/settings/up-right-arrow.svg" />
          </button>
        </template>
        <p class="text-sm mt-2">
          {{ setting.description }}
        </p>
      </Card>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import Card from '@/components/Card/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import TemplateIcon from './components/Icons/TemplateIcon.vue';
  import PackageIcon from './components/Icons/PackageIcon.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Document',
    components: {
      Card,
      PageHeader,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
    },
    data() {
      return {
        settingsOptions: [
          {
            title: 'Package Management',
            page: 'DocumentPackage',
            iconComponent: PackageIcon,
            description: 'You can manage document packages here.',
          },
          {
            title: 'Template Management',
            page: 'DocumentTemplate',
            iconComponent: TemplateIcon,
            description: 'You can manage document templates here.',
          },
        ],
      };
    },
    computed: {},
    methods: {
      ...mapActions(['setLoading']),
      navigate(newPage) {
        if (newPage) {
          this.$router.push({ name: `${newPage}` });
        }
      },
    },
  };
</script>

<style scoped></style>
