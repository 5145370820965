<template>
  <Card>
    <div>
      <h3 class="mb-3 text-lg font-semibold">Legal Information</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        <div>
          <RadioInput
            label="Borrower is incapacitated and cannot sign?"
            id="id1"
            :options="radioInputOptions"
            :value="formData.isIncapacitated"
            v-model="formData.isIncapacitated"
          />
        </div>
        <div>
          <RadioInput label="Borrower is incompetent?" id="id2" :options="radioInputOptions" :value="formData.isIncompetent" v-model="formData.isIncompetent" />
        </div>
        <div>
          <RadioInput
            label="Are there outstanding judgements?"
            id="id3"
            :options="radioInputOptions"
            :value="formData.outstandingJudgments"
            v-model="formData.outstandingJudgments"
          />
        </div>
        <div>
          <RadioInput label="Unresolved Bankruptcy?" id="id4" :options="radioInputOptions" :value="formData.unresolvedBankruptcy" v-model="formData.unresolvedBankruptcy" />
        </div>
        <div>
          <RadioInput label="Default on Federal Debt?" id="id5" :options="radioInputOptions" :value="formData.defaultOnFederalDebt" v-model="formData.defaultOnFederalDebt" />
        </div>
        <div>
          <RadioInput label="Co - Maker or Endorser on Note?" id="id6" :options="radioInputOptions" :value="formData.endorserOnNote" v-model="formData.endorserOnNote" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Financial Information</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        <div>
          <RadioInput
            label="Required to bring money to closing?"
            id="id7"
            :options="radioInputOptions"
            :value="formData.requiredToBringMoney"
            v-model="formData.requiredToBringMoney"
          />
        </div>
        <div>
          <RadioInput label="Did you borrow the money?" id="id8" :options="radioInputOptions" :value="formData.borrowedTheMoney" v-model="formData.borrowedTheMoney" />
        </div>
        <div>
          <RadioInput
            label="Intend to buy financial product?"
            id="id9"
            :options="radioInputOptions"
            :value="formData.purchaseFinancialProduct"
            v-model="formData.purchaseFinancialProduct"
          />
        </div>
        <div>
          <RadioInput
            label="Is the property the primary residence?"
            id="id11"
            :options="radioInputOptions"
            :value="formData.primaryResidence"
            v-model="formData.primaryResidence"
          />
        </div>
        <div>
          <RadioInput label="Existing FHA Loan?" id="id10" :options="radioInputOptions" :value="formData.fHAInsuranceClaimFound" v-model="formData.fHAInsuranceClaimFound" />
        </div>
      </div>
      <div v-if="formData.fHAInsuranceClaimFound">
        <br />
        <Card title="FHA Loans">
          <div class="flex justify-end items-center">
            <Button variant="primary" class="my-2" @click="openAddFHALoanDialog">Add FHA Loan</Button>
          </div>
          <DataTable :headers="fhaLoanTableheaders" :data="formData.existingFHALoans" @action="handleAction" />
        </Card>
      </div>
    </div>
    <div class="flex justify-end gap-2 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
  <Dialog :title="isEditFHALoanMode ? 'Edit FHA Loan' : 'Add New FHA Loan'" :isOpen="isFHADialogOpen" @confirm="handleConfirmFHALoaneDialog" @close="handleCloseFHALoanDialog">
    <form @submit.prevent="handleConfirmFHALoaneDialog">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div class="form-group">
          <InputField label="Fully Property Address" id="fha-loan-address" v-model="fhaLoanForm.address" :value="fhaLoanForm.address" :disable-validation="true" />
        </div>
        <div class="form-group">
          <SelectInput
            label="Status"
            id="fha-loan-status"
            :options="fhaLoanStatusOptions"
            v-model="fhaLoanForm.status"
            :value="fhaLoanForm.status"
            :validation="{ required: true }"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        <div class="form-group">
          <IntegerInput
            label="Account number"
            id="fha-loan-account-number"
            :value="fhaLoanForm.accountNumber"
            v-model.trim="fhaLoanForm.accountNumber"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <InputField label="Creditor Name" id="fha-loan-creditor" v-model="fhaLoanForm.creditorName" :value="fhaLoanForm.creditorName" :disable-validation="true" />
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        <div class="form-group">
          <MoneyInput label="Amount" id="fha-loan-amount" ref="fha-loan-amount" :value="fhaLoanForm.amount" v-model.trim="fhaLoanForm.amount" :disable-validation="true" />
        </div>
        <div class="form-group">
          <MoneyInput label="Balance" id="fha-loan-balance" ref="fha-loan-balance" :value="fhaLoanForm.balance" v-model.trim="fhaLoanForm.balance" :disable-validation="true" />
        </div>
      </div>
      <div class="form-actions flex justify-end mt-2">
        <Button type="submit" :disabled="!isFHAFormValid">{{ isEditFHALoanMode ? 'Save Changes' : 'Add FHA Loan' }}</Button>
      </div>
    </form>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Declaration',
    components: {
      Button,
      Card,
      RadioInput,
      DataTable,
      IntegerInput,
      Dialog,
      MoneyInput,
      InputField,
      SelectInput,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        isEditFHALoanMode: false,
        isFHADialogOpen: false,
        selectedFHALoan: null,
        fhaLoanStatusOptions: [
          { label: 'Sold', value: 'Sold' },
          { label: 'Pending Sale', value: 'Pending' },
          { label: 'Rental', value: 'Rental' },
        ],
        fhaLoanTableheaders: [
          { key: 'address', label: 'Address' },
          { key: 'status', label: 'Status' },
          { key: 'accountNumber', label: 'Account Number' },
          { key: 'creditorName', label: 'Creditor Name' },
          { key: 'amount', label: 'Amount' },
          { key: 'balance', label: 'Balance' },
          { key: 'action', label: 'Action' },
        ],
        fhaLoanForm: {
          address: '',
          status: '',
          accountNumber: 0,
          creditorName: '',
          amount: 0,
          balance: 0,
        },
        localData: {
          isIncapacitated: null,
          isIncompetent: null,
          outstandingJudgments: null,
          unresolvedBankruptcy: null,
          defaultOnFederalDebt: null,
          endorserOnNote: null,
          requiredToBringMoney: null,
          borrowedTheMoney: null,
          purchaseFinancialProduct: null,
          fHAInsuranceClaimFound: null,
          primaryResidence: null,
          existingFHALoans: [],
        },
        formData: {
          isIncapacitated: null,
          isIncompetent: null,
          outstandingJudgments: null,
          unresolvedBankruptcy: null,
          defaultOnFederalDebt: null,
          endorserOnNote: null,
          requiredToBringMoney: null,
          borrowedTheMoney: null,
          purchaseFinancialProduct: null,
          fHAInsuranceClaimFound: null,
          primaryResidence: null,
          existingFHALoans: [],
        },
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    computed: {
      isFHAFormValid() {
        const { address, status, accountNumber, amount, balance, creditorName } = this.fhaLoanForm;
        return address && status && accountNumber && amount && balance && creditorName;
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchDeclaration() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/declarations/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
          this.localData.existingFHALoans = response.data.existingFHALoans.map((loan, key) => ({
            ...loan,
            id: key,
            dataId: loan.id,
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      resetFMALoanForm() {
        this.fhaLoanForm = {
          address: '',
          status: '',
          accountNumber: 0,
          creditorName: '',
          amount: 0,
          balance: 0,
        };
      },
      openAddFHALoanDialog() {
        this.resetFMALoanForm();
        this.isEditFHALoanMode = false;
        this.isFHADialogOpen = true;
      },
      openEditFHALoanDialog(fhaLoan) {
        this.selectedFHALoan = fhaLoan;
        this.fhaLoanForm = {
          ...fhaLoan,
        };
        this.isEditFHALoanMode = true;
        this.isFHADialogOpen = true;
      },
      handleCloseFHALoanDialog() {
        this.isFHADialogOpen = false;
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditFHALoanDialog(item);
        } else if (action === 'delete') {
          this.selectedFHALoan = item;
          this.handleFHALoanDelete();
        }
      },
      async handleConfirmFHALoaneDialog() {
        if (this.isEditFHALoanMode) {
          this.formData.existingFHALoans = this.formData.existingFHALoans.map((fhaLoan) => {
            if (this.selectedFHALoan.id == fhaLoan.id) {
              return this.fhaLoanForm;
            }
            return fhaLoan;
          });
        } else {
          const newFHALoan = {
            ...this.fhaLoanForm,
            id: this.formData.existingFHALoans.length,
            dataId: -1,
            actions: ['edit', 'delete'],
          };
          let data = [...this.formData.existingFHALoans];
          data.push(newFHALoan);
          this.formData.existingFHALoans = data;
        }

        this.isFHADialogOpen = false;
        this.resetFMALoanForm();
      },
      handleFHALoanDelete() {
        const filteredData = this.formData.existingFHALoans.filter((fhaLoan) => fhaLoan.id !== this.selectedFHALoan.id);
        let id = 0;
        this.formData.existingFHALoans = filteredData.map((fhaLoan) => {
          return {
            ...fhaLoan,
            id: id++,
          };
        });
      },
      handleCloseFHALoanDeleteConfirmation() {
        this.isDeleteFHALoanConfirmationOpen = false;
      },
      async handleSaveChanges() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          let payload = { ...this.formData };
          payload.existingFHALoans = this.formData.fHAInsuranceClaimFound ? this.formData.existingFHALoans : [];
          if (this.localData.id) {
            await apiService.patch(`/loan/declarations/${this.localData.id}/`, payload);
          } else {
            payload.borrower = this.borrowerId;
            await apiService.post(`/loan/declarations/`, payload);
          }
          this.$root.showSnackbar(`Declaration information has been updated successfully!`, 'success');
          await this.fetchDeclaration();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      updateField(field, value) {
        if (this.localFormData[field] == value) {
          this.localFormData[field] = null;
        } else {
          this.localFormData[field] = value;
        }
      },
      resetLocalData() {
        this.localData = {
          isIncapacitated: null,
          isIncompetent: null,
          outstandingJudgments: null,
          unresolvedBankruptcy: null,
          defaultOnFederalDebt: null,
          endorserOnNote: null,
          requiredToBringMoney: null,
          borrowedTheMoney: null,
          purchaseFinancialProduct: null,
          fHAInsuranceClaimFound: null,
          primaryResidence: null,
          existingFHALoans: [],
        };
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchDeclaration();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
