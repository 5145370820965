<template>
  <div class="modal-overlay" v-if="isOpen">
    <div class="modal-content">
      <div class="modal-header">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103988 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C48 20.8483 47.3792 17.7274 46.1731 14.8156C44.967 11.9038 43.1992 9.25804 40.9706 7.02944C38.742 4.80083 36.0962 3.033 33.1844 1.82689C30.2726 0.620778 27.1517 0 24 0ZM24 43.2C20.2026 43.2 16.4905 42.0739 13.3331 39.9642C10.1756 37.8545 7.71473 34.8559 6.26152 31.3475C4.80832 27.8392 4.4281 23.9787 5.16893 20.2543C5.90977 16.5298 7.73839 13.1087 10.4236 10.4235C13.1087 7.73838 16.5298 5.90976 20.2543 5.16892C23.9787 4.42809 27.8392 4.80831 31.3475 6.26151C34.8559 7.71471 37.8545 10.1756 39.9642 13.333C42.0739 16.4905 43.2 20.2026 43.2 24C43.2 29.0921 41.1772 33.9757 37.5765 37.5764C33.9758 41.1771 29.0922 43.2 24 43.2Z"
            fill="#9CA3AF"
          />
          <path
            d="M24 16.8C25.3255 16.8 26.4 15.7255 26.4 14.4C26.4 13.0745 25.3255 12 24 12C22.6745 12 21.6 13.0745 21.6 14.4C21.6 15.7255 22.6745 16.8 24 16.8Z"
            fill="#9CA3AF"
          />
          <path
            d="M24 19.2C23.3635 19.2 22.753 19.4528 22.303 19.9029C21.8529 20.353 21.6 20.9635 21.6 21.6V33.6C21.6 34.2365 21.8529 34.8469 22.303 35.297C22.753 35.7471 23.3635 36 24 36C24.6365 36 25.247 35.7471 25.6971 35.297C26.1471 34.8469 26.4 34.2365 26.4 33.6V21.6C26.4 20.9635 26.1471 20.353 25.6971 19.9029C25.247 19.4528 24.6365 19.2 24 19.2Z"
            fill="#9CA3AF"
          />
        </svg>

        <button @click="close" class="close-button">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.88139 5.0014L9.80943 1.07321C9.86905 1.01563 9.91661 0.946747 9.94932 0.87059C9.98204 0.794433 9.99926 0.712524 9.99998 0.629641C10.0007 0.546758 9.9849 0.464561 9.95352 0.387847C9.92213 0.311133 9.87578 0.241438 9.81717 0.182829C9.75856 0.124219 9.68887 0.0778693 9.61215 0.0464831C9.53544 0.015097 9.45324 -0.000696663 9.37036 2.35686e-05C9.28748 0.0007438 9.20557 0.0179636 9.12941 0.0506782C9.05325 0.0833928 8.98437 0.130947 8.92679 0.190566L4.9986 4.11861L1.07056 0.190566C0.953064 0.0756557 0.794997 0.0117148 0.630657 0.0126198C0.466317 0.0135249 0.308964 0.0792028 0.192746 0.1954C0.0765278 0.311598 0.0108224 0.468939 0.00988831 0.633279C0.00895421 0.797619 0.072867 0.955697 0.187757 1.07321L4.11595 5.0014L0.187757 8.9296C0.128733 8.98733 0.0817466 9.05619 0.0495195 9.1322C0.0172923 9.20821 0.000464139 9.28986 9.46651e-06 9.37242C-0.000445206 9.45498 0.0154827 9.53681 0.0468707 9.61318C0.0782587 9.68954 0.124483 9.75891 0.182868 9.81729C0.241252 9.87566 0.310637 9.92188 0.387005 9.95325C0.463372 9.98462 0.545205 10.0005 0.627765 10.0001C0.710325 9.9996 0.791971 9.98276 0.867977 9.95052C0.943983 9.91827 1.01284 9.87128 1.07056 9.81224L4.9986 5.8842L8.92679 9.81224C9.04867 9.93412 9.20847 9.99514 9.36811 9.99514C9.52776 9.99514 9.68771 9.93412 9.80943 9.81224C9.92646 9.69519 9.9922 9.53644 9.9922 9.37092C9.9922 9.2054 9.92646 9.04666 9.80943 8.9296L5.88139 5.0014Z"
              fill="#9CA3AF"
            />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer">
        <Button @click="confirm" variant="danger">Yes, I'm sure</Button>
        <Button @click="close">No, cancel</Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../Button/index.vue';

  export default {
    name: 'ConfirmationModal',
    components: {
      Button,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
    },
    methods: {
      confirm() {
        this.$emit('confirm');
        this.$emit('close', 'confirmed');
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .modal-content {
    background: white;
    padding: 20px 40px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-icon {
    font-size: 48px;
    color: #a0a7b4;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-body {
    font-size: 13px;
    color: #6b7280;
    margin-bottom: 20px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .confirm-button {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .cancel-button {
    background: #5bc0de;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
</style>
