<template>
  <Card title="Users">
    <template v-slot:action>
      <div class="flex items-center">
        <div>
          <Button @click="handleAddUser" class="btn btn-primary">Add User</Button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" @action="handleAction" />
  </Card>
  <!-- Delete User Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this user?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { isCompanyUser } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Users',
    components: {
      Button,
      Card,
      Confirmation,
      DataTable,
    },
    data() {
      return {
        headers: [
          { key: 'company', label: 'Company' },
          { key: 'email', label: 'Email' },
          { key: 'name', label: 'Name' },
          { key: 'hierarchy', label: 'User Hierarchy' },
          { key: 'status', label: 'Status' },
          { key: 'action', label: 'Action' },
        ],
        companyData: [],
        tableData: [],
        selectedUser: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      // Company User hierarchy cannot access to this page, so redirect to dashboard
      if (isCompanyUser()) {
        this.redirectToDashboard();
        return;
      }

      this.setLoading(true);
      await this.fetchCompanies();
      await this.fetchUsers();
      this.setLoading(false);
    },
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      async fetchCompanies() {
        try {
          const response = await apiService.get('/company/companies/');
          this.companyData = [...response.data];
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        }
      },
      async fetchUsers() {
        try {
          const response = await apiService.get('/company/users/');
          this.tableData = response.data.map((item) => ({
            id: item.id,
            company: this.companyData.find((company) => company.id === item.company)?.name,
            email: item.email,
            name: item.firstName + ' ' + item.lastName,
            hierarchy: item.hierarchy,
            admin: item.is_superuser,
            status: item.is_active ? 'Active' : 'Deactivate',
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        }
      },
      handleAddUser() {
        this.$router.push({ name: 'AddUser' });
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.$router.push({ name: 'UserDetail', params: { id: item.id } });
        } else if (action === 'delete') {
          this.selectedUser = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/company/users/${this.selectedUser.id}/`);
          this.$root.showSnackbar('User has been deleted successfully', 'success');
          await this.fetchUsers();
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
    },
  };
</script>
