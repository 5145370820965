<template>
  <Card title="Settings">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <Card v-for="(setting, index) in settingsOptions" :key="index">
        <template v-slot:title>
          <div class="flex items-center gap-2">
            <component :is="setting.iconComponent" />
            <h1 class="text-lg font-semibold leading-none">{{ setting.title }}</h1>
          </div>
        </template>
        <template v-slot:action>
          <button class="p-2 rounded bg-primary" @click="navigate(setting.page)">
            <img src="../../assets/image/settings/up-right-arrow.svg" />
          </button>
        </template>
        <p class="text-sm mt-2">
          {{ setting.description }}
        </p>
      </Card>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import Card from '@/components/Card/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import SettingsIcon from './components/Icons/SettingsIcon.vue';
  import DollarIcon from './components/Icons/DollarIcon.vue';
  import AgentIcon from './components/Icons/AgentIcon.vue';
  import TrustIcon from './components/Icons/TrustIcon.vue';
  import PayIcon from './components/Icons/PayIcon.vue';
  import { USER_HIERARCHIES } from '@/constants';
  import { isCompanyAdmin, isCompanyUser } from '@/utils';
  // import ServiceIcon from './components/Icons/ServiceIcon.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Settings',
    components: {
      Card,
      PageHeader,
    },
    data() {
      const initialOptions = !isCompanyAdmin()
        ? []
        : [
            {
              title: 'Company Settings',
              iconComponent: SettingsIcon,
              page: 'CompanySettings',
              description: 'You can change company related settings.',
            },
          ];
      const settingsOptions = [
        ...initialOptions,
        {
          title: 'Fees',
          iconComponent: DollarIcon,
          page: 'Fees',
          description: 'Fee templates settings.',
        },
        {
          title: 'Settlement Agents',
          iconComponent: AgentIcon,
          page: 'SettlementAgents',
          description: 'Settlement agent templates settings.',
        },
        {
          title: 'Trustees',
          iconComponent: TrustIcon,
          page: 'Trustees',
          description: 'Trustee templates settings.',
        },
        {
          title: 'Payees',
          iconComponent: PayIcon,
          page: 'Payees',
          description: 'Payee templates settings.',
        },
        // {
        //   title: 'Service Provider',
        //   iconComponent: ServiceIcon,
        //   description: 'Service provider settings.',
        // },
      ];
      return {
        settingsOptions,
        activeTab: { title: 'Products', isActive: true },
        userHierarchies: USER_HIERARCHIES,
      };
    },
    created() {
      // Company User cannot access here
      if (isCompanyUser()) {
        this.redirectToDashboard();
        return;
      }
    },
    computed: {},
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      navigate(newPage) {
        if (newPage) {
          this.$router.push({ name: `${newPage}` });
        }
      },
    },
  };
</script>
