export const DEFAULT_FONT_SIZE = 14;
export const DOCUMENT_GAP = 8;
export const PAGE_GAP = 12;

export const PAGE_MARGINS = {
  MEDIUM: '20mm',
  SMALL: '15mm',
  SLIM: '10mm',
  TINY: '5mm',
};

export const PAPER_SIZES = {
  LEGAL: [215.9, 355.6],
  LETTER: [215.9, 279.4],
};

export const MODEL_FIELDS = [
  {
    title: 'Borrower Information',
    key: 'borrower',
    items: [
      {
        title: 'First Name',
        key: 'firstName',
      },
      {
        title: 'Middle Name',
        key: 'middleName',
      },
      {
        title: 'Last Name',
        key: 'lastName',
      },
      {
        title: 'SSN',
        key: 'ssn',
      },
      {
        title: 'DOB',
        key: 'dob',
      },
      {
        title: 'Monthly Income',
        key: 'monthlyIncome',
      },
      {
        title: 'Real Estate Assets',
        key: 'realEstateAssets',
      },
      {
        title: 'Available Assets',
        key: 'availableAssets',
      },
      {
        title: 'Home Phone',
        key: 'homePhone',
      },
      {
        title: 'Years of Residence',
        key: 'yearsOfResidence',
      },
      {
        title: 'Marital Status',
        key: 'maritalStatus',
      },
      {
        title: 'Non Real Estate Debt',
        key: 'nonRealEstateDebt',
      },
      {
        title: 'Answers to all "Declarations" questions',
        key: 'answers',
      },
    ],
  },
  {
    title: 'Alternative Contact',
    key: 'alternativeContact',
    items: [
      {
        title: 'First Name',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        key: 'lastName',
      },
      {
        title: 'Address',
        key: 'address',
      },
      {
        title: 'City',
        key: 'city',
      },
      {
        title: 'State',
        key: 'state',
      },
      {
        title: 'Zip',
        key: 'zip',
      },
      {
        title: 'Phone',
        key: 'phone',
      },
    ],
  },
  {
    title: 'Payoffs',
    key: 'payoffs',
    items: [
      {
        title: 'Creditor Name',
        key: 'creditorName',
      },
      {
        title: 'Creditor Address',
        key: 'creditorAddress',
      },
      {
        title: 'Creditor City',
        key: 'creditorCity',
      },
      {
        title: 'Creditor Zip',
        key: 'creditorZip',
      },
      {
        title: 'Creditor State',
        key: 'creditorState',
      },
      {
        title: 'Balance',
        key: 'balance',
      },
      {
        title: 'Account Number',
        key: 'accountNumber',
      },
    ],
  },
  {
    title: 'Property Information',
    key: 'propertyInformation',
    items: [
      {
        title: 'Property Address',
        key: 'propertyAddress',
      },
      {
        title: 'Property City',
        key: 'propertyCity',
      },
      {
        title: 'Property State',
        key: 'propertyState',
      },
      {
        title: 'Property Zip',
        key: 'propertyZip',
      },
      {
        title: 'Property Legal Description',
        key: 'propertyLegalDescription',
      },
      {
        title: 'Property Title Held As',
        key: 'propertyTitleHeldAs',
      },
    ],
  },
  {
    title: 'Appraisal Information',
    key: 'appraisal',
    items: [
      {
        title: 'Year Built',
        key: 'yearBuilt',
      },
      {
        title: 'Appraised Value',
        key: 'appraisedValue',
      },
      {
        title: 'Number of Units',
        key: 'numberOfUnits',
      },
    ],
  },
  {
    title: 'Title Information',
    key: 'title',
    items: [
      {
        title: 'Title Held As',
        key: 'titleHeldAs',
      },
      {
        title: 'Title Living Trust',
        key: 'titleLivingTrust',
      },
    ],
  },
  {
    title: 'Loan Information',
    key: 'loan',
    items: [
      {
        title: 'FHA Case Number',
        key: 'fhaCaseNumber',
      },
      {
        title: 'Loan Number',
        key: 'loanNumber',
      },
      {
        title: 'Loan Purpose',
        key: 'loanPurpose',
      },
      {
        title: 'Current FHA Insured Loans',
        key: 'currentFhaInsuredLoans',
      },
      {
        title: 'Current FHA Insured Loans Account Number',
        key: 'currentFhaInsuredLoansAccountNumber',
      },
      {
        title: 'Current FHA Insured Loans Balance',
        key: 'currentFhaInsuredLoansBalance',
      },
    ],
  },
  {
    title: 'Loan Officer',
    key: 'loanOfficer',
    items: [
      {
        title: 'Company NMLS',
        key: 'companyNmls',
      },
      {
        title: 'Loan Officer Name',
        key: 'loanOfficerName',
      },
      {
        title: 'Loan Officer NMLS',
        key: 'loanOfficerNmls',
      },
      {
        title: 'Loan Officer State License',
        key: 'loanOfficerStateLicense',
      },
      {
        title: 'Loan Officer Phone Number',
        key: 'loanOfficerPhoneNumber',
      },
    ],
  },
  {
    title: 'Company',
    key: 'company',
    items: [
      {
        title: 'Company',
        key: 'companyName',
      },
      {
        title: 'Company NMLS',
        key: 'companyNmls',
      },
      {
        title: 'Company State License',
        key: 'companyStateLicense',
      },
      {
        title: 'Company Address',
        key: 'companyAddress',
      },
    ],
  },
  {
    title: 'Loan Calculation',
    key: 'loanCalculation',
    items: [
      {
        title: 'Origination Fee',
        key: 'originationFee',
      },
      {
        title: 'IMIP',
        key: 'imip',
      },
      {
        title: 'Closing Costs',
        key: 'closingCosts',
      },
      {
        title: 'Principal Limit',
        key: 'principalLimit',
      },
      {
        title: 'UPB',
        key: 'upb',
      },
      {
        title: 'Cash Out',
        key: 'cashOut',
      },
      {
        title: 'Line of Credit',
        key: 'lineOfCredit',
      },
      {
        title: 'Initial Disbursement Limit',
        key: 'initialDisbursementLimit',
      },
      {
        title: 'LESA Year 1',
        key: 'lesaYear1',
      },
      {
        title: 'LESA Remaining',
        key: 'lesaRemaining',
      },
      {
        title: 'Total LESA',
        key: 'totalLesa',
      },
    ],
  },
  {
    title: 'Dates',
    key: 'dates',
    items: [
      {
        title: 'Application Date',
        key: 'applicationDate',
      },
      {
        title: 'Closing Date',
        key: 'closingDate',
      },
      {
        title: 'Scheduled Funding Date',
        key: 'scheduledFundingDate',
      },
    ],
  },
];
