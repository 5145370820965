<template>
  <Dialog :title="'Add Available Loan Products'" :isOpen="isOpen" @close="closeDialog" @confirm="emitConfirm">
    <div v-if="!products.length" class="bg-yellow-200 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
      <p class="font-bold">Attention!</p>
      <p>
        You need to add loan products from
        <span class="font-bold cursor-pointer underline hover:text-yellow-800" @click="navigateToCompanySettingsPage()">Company Settings</span> page.
      </p>
    </div>
    <div v-else>
      <div v-for="(product, index) in productsData" :key="index" class="my-2">
        <CheckboxInput :label="product.name" :value="product.default" v-model="product.default" />
      </div>
      <div class="flex justify-end gap-4 mt-5">
        <Button variant="primary" @click="emitConfirm"> Update Product List </Button>
        <Button variant="secondary" @click="closeDialog"> Cancel </Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Dialog from '@/components/Dialog/index.vue';
  import Button from '@/components/Button/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';

  export default {
    name: 'ProductsDialog',
    components: {
      Dialog,
      Button,
      CheckboxInput,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      products: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        productsData: [],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      handleProductsData() {
        this.productsData = this.products.map((product) => ({ id: product.id, name: product.name, default: product.default }));
      },
      navigateToCompanySettingsPage() {
        this.$router.push({ name: 'CompanySettings' });
      },
      closeDialog() {
        this.$emit('close');
      },
      async emitConfirm() {
        let success = false;
        try {
          this.setLoading(true);
          const patchPromises = this.productsData
            .map((product, index) => {
              const originalProduct = this.products[index];
              if (product.default !== originalProduct.default) {
                return apiService.patch(`/loan/products/${product.id}/`, { default: product.default });
              }
              return null;
            })
            .filter((promise) => promise !== null);
          await Promise.all(patchPromises);
          success = true;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
        if (success) this.$emit('confirm');
      },
    },
    watch: {
      isOpen: {
        handler(newValue) {
          if (newValue) {
            this.handleProductsData();
          }
        },
      },
    },
    computed: {},
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
