<template>
  <button :class="buttonClass" class="w-auto" @click="handleClick" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Button',
    props: {
      type: {
        type: String,
        default: 'button',
      },
      variant: {
        type: String,
        default: 'primary',
      },
      size: {
        type: String,
        default: 'medium',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      buttonClass() {
        return ['standard-btn', this.variantClass, this.sizeClass, { 'btn-disabled': this.disabled }];
      },
      variantClass() {
        switch (this.variant) {
          case 'primary':
            return 'btn-primary';
          case 'secondary':
            return 'btn-secondary';
          case 'danger':
            return 'btn-danger';
          case 'outline':
            return 'btn-outline';
          default:
            return 'btn-primary';
        }
      },
      sizeClass() {
        switch (this.size) {
          case 'small':
            return 'btn-small';
          case 'large':
            return 'btn-large';
          default:
            return 'btn-medium';
        }
      },
    },
    methods: {
      handleClick(event) {
        if (!this.disabled) {
          this.$emit('confirm', event); // Emit a custom event
        }
      },
    },
  };
</script>

<style scoped>
  .standard-btn.btn-primary {
    background-color: #104862;
    color: #fff;
  }

  .standard-btn.btn-secondary {
    background-color: #e78724;
    color: #fff;
  }

  .standard-btn.btn-danger {
    background-color: #ff3737;
    color: #fff;
  }

  .standard-btn.btn-outline {
    background-color: transparent;
    border: 1px solid #104862;
    color: #104862;
  }

  /* .standard-btn.btn-small {
  padding: 5px 10px;
  font-size: 12px;
}

.standard-btn.btn-medium {
  padding: 10px 20px;
  font-size: 14px;
}

.standard-btn.btn-large {
  padding: 15px 30px;
  font-size: 16px;
} */

  .standard-btn.btn-disabled {
    background-color: #d6d6d6;
    color: #999;
    cursor: not-allowed;
  }
</style>
