<template>
  <Card title="Real Estate Owned">
    <template v-slot:action>
      <div class="flex gap-1">
        <Button :variant="primary" @click="openAddDialog">Add Real Estate Owned</Button>
      </div>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <Dialog :title="`${isOpenDialog} Real Estate Owned Property`" :isOpen="isOpenDialog != ''" @close="handleCloseDialog" @confirm="handleConfirmDialog">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
      <div>
        <SelectInput
          label="Property Type"
          id="propertyType"
          v-model="formData.propertyType"
          :value="formData.propertyType"
          :options="PROPERTY_TYPE_OPTIONS"
          :disable-validation="true"
        />
        <span v-if="validationFormErrors.propertyType.length > 0" class="text-sm text-red">
          {{ validationFormErrors.propertyType }}
        </span>
      </div>
      <div>
        <InputField label="Property Address" id="propertyAddress" :value="formData.propertyAddress" v-model.trim="formData.propertyAddress" :disable-validation="true" />
        <span v-if="validationFormErrors.propertyAddress.length > 0" class="text-sm text-red">
          {{ validationFormErrors.propertyAddress }}
        </span>
      </div>
      <div>
        <MoneyInput label="Present Market Value" id="presentMarketValue" :value="formData.presentMarketValue" v-model="formData.presentMarketValue" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput
          label="Amount Of Mortgages And Liens"
          id="amountOfMortgagesAndLiens"
          :value="formData.amountOfMortgagesAndLiens"
          v-model="formData.amountOfMortgagesAndLiens"
          :disable-validation="true"
        />
      </div>
      <div>
        <MoneyInput label="Gross Rental Income" id="grossRentalIncome" :value="formData.grossRentalIncome" v-model="formData.grossRentalIncome" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Mortgage Payments" id="mortgagePayments" :value="formData.mortgagePayments" v-model="formData.mortgagePayments" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput
          label="Insurance Maintenance Taxes"
          id="insuranceMaintenanceTaxes"
          :value="formData.insuranceMaintenanceTaxes"
          v-model="formData.insuranceMaintenanceTaxes"
          :disable-validation="true"
        />
      </div>
      <div>
        <MoneyInput label="Net Rental Income" id="netRentalIncome" :value="formData.netRentalIncome" v-model="formData.netRentalIncome" :disable-validation="true" />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5 pt-5 border-t">
      <Button variant="primary" style="width: fit-content" @click="handleConfirmDialog"> Save </Button>
      <Button variant="secondary" style="width: fit-content" @click="handleCloseDialog"> Close </Button>
    </div>
  </Dialog>
  <!-- Delete Real Estate Owned Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this Real Estate Owned Property?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import { PROPERTY_TYPE_OPTIONS } from '@/constants';

  const initialData = {
    propertyType: '',
    propertyAddress: '',
    presentMarketValue: 0,
    amountOfMortgagesAndLiens: 0,
    grossRentalIncome: 0,
    mortgagePayments: 0,
    insuranceMaintenanceTaxes: 0,
    netRentalIncome: 0,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RealEstateOwned',
    components: {
      Button,
      Card,
      Confirmation,
      DataTable,
      Dialog,
      InputField,
      MoneyInput,
      SelectInput,
    },
    setup() {
      return {
        PROPERTY_TYPE_OPTIONS,
      };
    },
    props: {},
    async created() {
      await this.fetchRealEstateOwneds();
    },
    data() {
      return {
        tableHeaders: [
          { key: 'propertyType', label: 'Property Type' },
          { key: 'propertyAddress', label: 'Property Address' },
          { key: 'presentMarketValue', label: 'Present Market Value', type: 'money' },
          { key: 'presentMarketValue', label: 'Present Market Value', type: 'money' },
          { key: 'amountOfMortgagesAndLiens', label: 'Amount Of Mortgages And Liens', type: 'money' },
          { key: 'grossRentalIncome', label: 'Gross Rental Income', type: 'money' },
          { key: 'mortgagePayments', label: 'Mortgage Payments', type: 'money' },
          { key: 'insuranceMaintenanceTaxes', label: 'Insurance Maintenance Taxes', type: 'money' },
          { key: 'netRentalIncome', label: 'Net Rental Income', type: 'money' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        realEstateOwneds: [],
        realEstateOwnedsOptions: [],
        isOpenDialog: '',
        formData: {},
        validationFormErrors: {
          propertyType: '',
          propertyAddress: '',
        },
        selectedRealEstateOwned: null,
        isDeleteConfirmationOpen: false,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchRealEstateOwneds() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/real_estate_owned_properties/`);
          this.realEstateOwneds = response.data;
        } catch (error) {
          this.realEstateOwneds = [];
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.payoffs = [];
        } finally {
          this.setLoading(false);
        }
      },
      updateTableData() {
        let newTableData = [];
        this.realEstateOwneds.forEach((realEstateOwned) => {
          newTableData.push({
            ...realEstateOwned,
            actions: ['edit', 'delete'],
          });
        });
        this.tableData = newTableData;
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.realEstateOwneds.find((el) => el.id == id);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.formData.id)
            await apiService.patch(`/loan/real_estate_owned_properties/${this.formData.id}/`, {
              loan: this.$route.params.id,
              ...this.formData,
            });
          else
            await apiService.post(`/loan/real_estate_owned_properties/`, {
              loan: this.$route.params.id,
              ...this.formData,
            });
          this.$root.showSnackbar(`Real Estate Owned information has been updated for the property successfully!`, 'success');
          await this.fetchRealEstateOwneds();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedRealEstateOwned = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      validateForm() {
        this.validationFormErrors = {
          propertyType: !this.formData.propertyType ? 'This field is required.' : '',
          propertyAddress: !this.formData.propertyAddress ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.propertyType.length && !this.validationFormErrors.propertyAddress.length;
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/real_estate_owned_properties/${this.selectedRealEstateOwned.id}/`);
          this.$root.showSnackbar('Real estate owned property information has been deleted successfully', 'success');
          await this.fetchRealEstateOwneds();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete real estate owned property information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
    },
    watch: {
      realEstateOwneds: {
        handler() {
          this.updateTableData();
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {},
  };
</script>
