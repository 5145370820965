<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Loading',
    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>

<style>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .spinner {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #e78724;
    width: 45px;
    height: 45px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
