<template>
  <div v-if="loadFailed" class="flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
      <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
      <h2 class="text-2xl font-semibold text-gray-800 mb-2">Failed to load template</h2>
      <p class="text-gray-600 mb-4">It's failed to load existing template data.</p>
      <div class="flex justify-center gap-4">
        <Button @click="backToTemplateList">Go to list</Button>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col h-full">
    <div class="flex justify-between items-center border border-gray bg-white rounded-lg p-2 mb-2">
      <div class="flex items-center">
        <button @click="backToTemplateList" class="mr-3">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <PageHeader :title="`${isUpdate ? 'Update' : 'Create'} Template`" />
      </div>
    </div>
    <div class="flex flex-col min-h-0 grow border border-gray bg-white rounded-lg p-3">
      <div class="flex flex-col">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <InputField
              label="Template Title *"
              id="name"
              ref="name"
              placeholder="Enter tempalte title"
              v-model.trim="formData.name"
              :value="formData.name"
              :disable-validation="true"
            />
          </div>
          <div class="flex justify-between items-end gap-2">
            <div>
              <label for="fileUpload" class="font-semibold text-[#212121]">Choose PDF *</label>
              <input type="file" id="fileUpload" @change="onFileChange" accept="application/pdf" class="block w-full px-3 py-2" />
            </div>
            <Button variant="primary" @click="handleSave" style="width: fit-content" :disabled="disabledSave"> {{ isUpdate ? 'Save Changes' : 'Create' }} </Button>
          </div>
        </div>
      </div>

      <div class="flex flex-col grow min-h-0 border-t border-gray-200 mt-4">
        <div v-if="!isUpdate && !this.pdfLink" class="flex justify-center w-full m-auto">
          <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
            <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
            <h2 class="text-2xl font-semibold text-gray-800 mb-2">No PDF Data</h2>
            <p class="text-gray-600 mb-4">You should choose template file.</p>
          </div>
        </div>
        <PDFEditor v-else v-model:pdfLink="this.pdfLink" v-model:fieldsData="this.fields" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import PDFEditor from '../../components/PDFEditor/index.vue';

  export default {
    name: 'PDFTemplateForm',
    components: {
      Button,
      PageHeader,
      InputField,
      PDFEditor,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
    },
    data() {
      return {
        pdfLink: null,
        isUpdate: typeof this.$route.params.id !== 'undefined',
        loadFailed: false,
        formData: {
          name: '',
          templateFile: null,
        },
        fields: [],
      };
    },
    computed: {
      disabledSave() {
        return (!this.formData.templateFile && !this.isUpdate) || !this.formData.name.length;
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      backToTemplateList() {
        this.$router.push({ name: 'DocumentTemplate' });
      },
      async fetchTemplate() {
        this.setLoading(true);
        try {
          const templateId = this.$route.params.id;
          const response = await apiService.get(`/documents/templates/${templateId}/`);
          this.loadFailed = false; // Load template data succeed
          this.handleTemplateData(response.data);
        } catch (error) {
          this.loadFailed = true; // Load template data failed
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTemplateData(template) {
        this.formData = {
          name: template.name,
        };
        this.fields = template.fields;
        this.pdfLink = template.templateFileUrl; // Set PDF Data from existing PDF file url
      },
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
          this.formData.templateFile = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            const arrayBuffer = e.target.result;

            // Convert ArrayBuffer to Blob and then to Object URL
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const objectUrl = URL.createObjectURL(blob);
            this.pdfLink = objectUrl; // Set pdf link for selected PDF file's
          };
          reader.readAsArrayBuffer(file);
        } else {
          this.$root.showSnackbar(`Please select a valid PDF file.`, 'error');
        }
      },
      async handleSave() {
        this.setLoading(true);
        try {
          const data = new FormData();
          data.append('type', 'PDF');
          data.append('name', this.formData.name);
          data.append('fields', JSON.stringify(this.fields));
          if (this.formData.templateFile) data.append('templateFile', this.formData.templateFile);

          if (this.isUpdate) {
            await apiService.patch(`/documents/templates/${this.$route.params.id}/`, data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          } else {
            await apiService.post('/documents/templates/', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          }
          this.$root.showSnackbar(`Document template ${this.isUpdate ? 'updated' : 'created'} successfully`, 'success');
          if (!this.isUpdate) this.backToTemplateList();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const firstErrorKey = Object.keys(error.response.data)[0];
            const firstErrorMessage = error.response.data[firstErrorKey][0];
            this.$root.showSnackbar(`Failed to ${this.isUpdate ? 'update' : 'create'} document template: ${firstErrorMessage}`, 'error');
          } else {
            this.$root.showSnackbar(
              `Failed to ${this.isUpdate ? 'update' : 'create'} document template: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`,
              'error'
            );
          }
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      '$route.params.id': {
        async handler(newId, oldId) {
          if (newId !== oldId) {
            await this.fetchTemplate();
          }
        },
        immediate: true,
      },
    },
  };
</script>
