<template>
  <Editor :api-key="apiKey" ref="editorRef" :init="editorSettings" v-model="content" @editor-init="onEditorInit" @input="updateContent" />
</template>

<script>
  import apiService from '@/api/apiService';
  import Editor from '@tinymce/tinymce-vue';

  export default {
    name: 'NewTextEditor',
    components: { Editor },
    props: {
      value: String,
    },
    created() {
      this.fetchDocument();
    },
    data() {
      return {
        apiKey: process.env.VUE_APP_TINYMCE_API_KEY,
        content: this.value,
        interpolationRef: {},
        editorSettings: {
          height: 400,
          menubar: true,
          readonly: false,
          plugins: [
            'advlist autolink link image lists charmap preview anchor pagebreak',
            'searchreplace wordcount visualblocks code fullscreen',
            'insertdatetime media table emoticons help powerpaste',
          ],
          toolbar:
            'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify |' +
            'bullist numlist outdent indent | link image | print preview media fullscreen |' +
            'forecolor backcolor emoticons | help | template',
          setup: (editor) => {
            editor.ui.registry.addMenuButton('template', {
              text: 'Template',
              search: {
                placeholder: 'Search...',
              },
              fetch: (callback, fetchContext) => {
                const latestInterpolation = this.interpolationRef;
                const pattern = fetchContext.pattern.toLowerCase();
                const items = Object.keys(latestInterpolation)
                  .filter((key) => latestInterpolation[key].name.toLowerCase().includes(pattern))
                  .map((key) => ({
                    type: 'menuitem',
                    text: latestInterpolation[key].name,
                    onAction: () => editor.insertContent(this.getInterpolationHTMLFromKey(key) + ' : '),
                  }));

                callback(
                  items.length > 0
                    ? items
                    : [
                        {
                          type: 'menuitem',
                          text: 'No results found',
                          onAction: () => {},
                        },
                      ]
                );
              },
            });

            editor.ui.registry.addButton('insertPlaceholder', {
              text: 'Insert Placeholder',
              onAction: () => {
                editor.windowManager.open({
                  title: 'Insert Placeholder',
                  body: {
                    type: 'panel',
                    items: Object.keys(this.interpolationRef).map((key) => ({
                      type: 'input',
                      name: key,
                      label: this.interpolationRef[key].name,
                      placeholder: `{{${key}}}`,
                    })),
                  },
                  buttons: [
                    {
                      type: 'cancel',
                      text: 'Close',
                    },
                    {
                      type: 'submit',
                      text: 'Insert',
                      primary: true,
                    },
                  ],
                  onSubmit: (api) => {
                    const data = api.getData();
                    const selectedPlaceholder = Object.keys(data).find((key) => data[key] !== '');
                    if (selectedPlaceholder) {
                      editor.insertContent(`{{${selectedPlaceholder}}} : `);
                    }
                    api.close();
                  },
                });
              },
            });
          },
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          branding: false,
        },
      };
    },
    methods: {
      fetchDocument() {
        apiService
          .get(`/template/fields/`)
          .then((response) => {
            const apiData = response.data;
            this.interpolationRef = Object.keys(apiData).reduce((acc, key) => {
              acc[key] = { name: key };
              return acc;
            }, {});
          })
          .catch((error) => {
            this.$root.showSnackbar(`${error.response ? error.response.data.message : error.message}`, 'error');
          });
      },
      onEditorInit(editor) {
        this.$emit('editor-init', editor);
      },
      updateContent(content) {
        this.$emit('input', content);
      },
      getInterpolationHTMLFromKey(key) {
        return `{{${key}}}`;
      },
      parseContent(content) {
        const placeholders = {};
        const regex = /{{(.*?)}}/g;
        let match;
        while ((match = regex.exec(content))) {
          placeholders[match[1]] = this.interpolationRef[match[1]]?.name || match[1];
        }
        return placeholders;
      },
      handleSubmit() {
        const content = this.content;
        const placeholders = this.parseContent(content);
        const result = {
          template: content,
          placeholders: placeholders,
        };
        console.log('Submitted content:', result);
      },
    },
    watch: {
      value(newVal) {
        this.content = newVal;
      },
    },
  };
</script>

<style scoped>
  /* Add any required styles here */
</style>
