<template>
  <Dialog :title="'Edit Income Source'" :isOpen="isOpen" @close="closeDialog" @confirm="emitConfirm">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <SelectInput label="Income Type" :options="incomeTypeOptions" :value="formData.incomeType" v-model.trim="formData.incomeType" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Monthly Income" :value="formData.monthlyIncome" v-model.trim="formData.monthlyIncome" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Annual Income" :value="formData.annualIncome" v-model.trim="formData.annualIncome" :disable-validation="true" />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="emitConfirm" :disabled="!isValidForm"> Save Changes </Button>
      <Button variant="secondary" @click="closeDialog"> Cancel </Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/Dialog/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';

  export default {
    name: 'IncomeDialog',
    components: {
      Dialog,
      SelectInput,
      MoneyInput,
      Button,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      editItem: {
        type: Object,
        required: true,
      },
      incomeTypeOptions: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        formData: {
          incomeType: 'Employment',
          monthlyIncome: 0,
          annualIncome: 0,
        },
      };
    },
    watch: {
      editItem: {
        handler(newValue) {
          this.formData = { ...newValue };
        },
        immediate: true,
      },
      'formData.monthlyIncome': {
        handler(newValue) {
          if (newValue !== this.formData.annualIncome / 12) {
            this.formData.annualIncome = newValue * 12;
          }
        },
        immediate: true,
      },
      'formData.annualIncome': {
        handler(newValue) {
          if (newValue !== this.formData.monthlyIncome * 12) {
            this.formData.monthlyIncome = newValue / 12;
          }
        },
        immediate: true,
      },
    },
    computed: {
      isValidForm() {
        return this.formData.monthlyIncome > 0 && this.formData.annualIncome > 0;
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close');
      },
      emitConfirm() {
        this.$emit('confirm', this.formData);
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
