<template>
  <Card title="Conditions">
    <template v-slot:action>
      <div class="flex items-center">
        <!-- <input v-model="searchQuery" class="search-input mr-[15px]" placeholder="Search ..." /> -->
        <div>
          <button class="standard-btn" @click="openAddConditionDialog">Add Condition</button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" @action="handleAction" />
  </Card>
  <!-- Add/Edit Condition Dialog -->
  <Dialog :title="isEditMode ? 'Edit Condition' : 'Add New Condition'" :isOpen="isConditionDialogOpen" @close="handleCloseConditionDialog">
    <form @submit.prevent="handleConfirmConditionDialog">
      <div class="mb-4">
        <RadioInput label="Cleared" id="cleared" :options="RADIO_INPUT_OPTIONS" v-model="formData.cleared" :value="formData.cleared" />
      </div>
      <div class="mb-4">
        <SelectInput
          label="Condition Type"
          id="conditionType"
          :options="conditionTypeOptions"
          v-model="formData.conditionType"
          :value="formData.conditionType"
          :disable-validation="true"
        />
        <span v-if="!formData.conditionType && validationErrors.conditionType" class="text-sm text-red">{{ validationErrors.conditionType }}</span>
      </div>
      <div class="mb-4">
        <TextareaInput label="Description" id="description" v-model="formData.description" :value="formData.description" :disable-validation="true" />
        <span v-if="!formData.description && validationErrors.description" class="text-sm text-red">{{ validationErrors.description }}</span>
      </div>
      <div class="flex justify-end">
        <Button type="submit">{{ isEditMode ? 'Save Changes' : 'Add Condition' }}</Button>
      </div>
    </form>
  </Dialog>
  <!-- Add Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete the condition?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { formatDate } from '@/utils';
  import { RADIO_INPUT_OPTIONS } from '@/constants';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Condition',
    components: {
      Button,
      Card,
      Confirmation,
      DataTable,
      Dialog,
      TextareaInput,
      RadioInput,
      SelectInput,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    setup() {
      return {
        RADIO_INPUT_OPTIONS,
        formatDate,
      };
    },
    async created() {
      await this.fetchConditions();
    },
    data() {
      return {
        formData: {
          cleared: false,
          conditionType: '',
          description: '',
        },
        validationErrors: {
          conditionType: '',
          description: '',
        },
        headers: [
          { key: 'cleared', label: 'Cleared' },
          { key: 'conditionType', label: 'Condition Type' },
          { key: 'description', label: 'Description' },
          { key: 'dateAddedFormatted', label: 'Date Added' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        selectedCondition: null,
        isConditionDialogOpen: false,
        isEditMode: false,
        isDeleteConfirmationOpen: false,
        conditionTypeOptions: [
          { label: 'General', value: 'General' },
          { label: 'Prior to Docs', value: 'Prior to Docs' },
          { label: 'Prior to Closing', value: 'Prior to Closing' },
          { label: 'Prior to Funding', value: 'Prior to Funding' },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchConditions() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/conditions/', {
            loan: this.loanId,
          });
          this.tableData = response.data.map((condition) => ({
            ...condition,
            dateAddedFormatted: this.formatDate(condition.dateAdded),
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch conditions: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddConditionDialog() {
        this.isEditMode = false;
        this.isConditionDialogOpen = true;
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditConditionDialog(item);
        } else if (action === 'delete') {
          this.selectedCondition = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      openEditConditionDialog(condition) {
        this.selectedCondition = condition;
        this.formData = {
          ...condition,
        };
        this.isEditMode = true;
        this.isConditionDialogOpen = true;
      },
      validateForm() {
        this.validationErrors = {
          conditionType: !this.formData.conditionType ? 'Condition type is required' : '',
          description: !this.formData.description ? 'Description is required' : '',
        };
        return !this.validationErrors.conditionType && !this.validationErrors.description;
      },
      async handleConfirmConditionDialog() {
        if (!this.validateForm()) return;
        const data = {
          ...this.formData,
          loan: this.loanId,
        };
        if (this.isEditMode) {
          this.setLoading(true);
          try {
            await apiService.patch(`/loan/conditions/${this.selectedCondition.id}/`, data);
            await this.fetchConditions();
            this.$root.showSnackbar('Condition has been updated successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to update condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else {
          this.setLoading(true);
          // Add the new Condition
          try {
            await apiService.post('/loan/conditions/', data);
            await this.fetchConditions();
            this.$root.showSnackbar('New condition has been created successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to create condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        this.handleCloseConditionDialog();
      },
      handleCloseConditionDialog() {
        this.isConditionDialogOpen = false;
        this.resetForm();
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          if (this.selectedCondition && this.selectedCondition.id) {
            await apiService.delete(`/loan/conditions/${this.selectedCondition.id}/`);
            await this.fetchConditions();
            this.$root.showSnackbar('Condition deleted successfully', 'success');
          } else {
            throw new Error('No Condition selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
          this.handleCloseDeleteConfirmation();
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
        this.selectedCondition = null;
      },
      resetForm() {
        this.formData = {
          cleared: false,
          conditionType: '',
          description: '',
        };
        this.validationErrors = {
          conditionType: '',
          description: '',
        };
      },
    },
    watch: {},
  };
</script>
<style scoped></style>
