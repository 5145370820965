<!-- src/App.vue -->
<template>
  <div id="app">
    <Loading :isLoading="isLoading" />
    <Snackbar ref="snackbar" />
    <router-view />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Snackbar from './components/Snackbar/index.vue';
  import Loading from './components/Loading/index.vue';
  export default {
    name: 'App',
    components: {
      Loading,
      Snackbar,
    },
    computed: {
      ...mapGetters(['isLoading']),
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      $route(to, from) {
        this.handleRouteChange();
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      showSnackbar(message, color) {
        this.$refs.snackbar.show(message, color);
      },
      handleRouteChange() {},
    },
  };
</script>
