<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
    </div>
    <div class="border rounded-lg">
      <div class="box">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2 border-b border-gray p-15">
          <div>
            <SelectInput label="Asset Type" :options="typeOptions" :value="formData.assetType" v-model.trim="formData.assetType" :disable-validation="true" />
          </div>
          <div>
            <InputField label="Description" :value="formData.description" :max-length="160" v-model.trim="formData.description" :disable-validation="true" />
          </div>
          <div>
            <MoneyInput label="Amount" :value="formData.amount" v-model.trim="formData.amount" :disable-validation="true" />
          </div>
          <div class="text-center flex flex-col">
            <label class="font-semibold text-[#212121]">Discount Rate (%)</label>
            <p class="text-[#212121] flex justify-center items-center h-full">
              {{ discountRates[formData.assetType] }}
            </p>
          </div>
          <div>
            <MoneyInput label="Discounted Value" :value="formData.discountedValue" v-model.trim="formData.discountedValue" :disable-validation="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <Button variant="primary" class="mr-15" @click="handleAddAssetType" :disabled="!isValidForm"> Add Assets for Dissipation </Button>
    </div>
    <!-- Edit Dialog Component -->
    <AssetDialog :is-open="isDialogOpen" :edit-item="editItem" :type-options="typeOptions" :discount-rates="discountRates" @close="closeDialog" @confirm="handleEditAssetType" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import AssetDialog from './components/AssetDialog/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Assets',
    components: {
      Card,
      DataTable,
      InputField,
      SelectInput,
      MoneyInput,
      Button,
      AssetDialog,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isValidForm: false,
        tableHeaders: [
          { key: 'assetType', label: 'Asset Type' },
          { key: 'description', label: 'Description' },
          { key: 'amount', label: 'Amount' },
          { key: 'discountedValue', label: 'Discounted Value' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        typeOptions: [
          { label: 'Checking', value: 'Checking' },
          { label: 'Savings', value: 'Savings' },
          { label: 'Certificate of Deposit', value: 'Certificate of Deposit' },
          { label: 'Roth IRA', value: 'Roth IRA' },
          {
            label: 'Assets Subject to Federal Taxes',
            value: 'Assets Subject to Federal Taxes',
          },
        ],
        formData: {
          assetType: 'Checking',
          amount: 0,
          description: '',
          discountedValue: 0,
        },
        discountRates: {
          Checking: 0,
          Savings: 0,
          'Certificate of Deposit': 0,
          'Roth IRA': 0,
          'Assets Subject to Federal Taxes': 15,
        },
        isDialogOpen: false,
        editItem: null,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfValidForm() {
        this.isValidForm = this.formData.description.length > 0 && this.formData.discountedValue > 0;
      },
      resetFormData() {
        this.formData = {
          assetType: 'Checking',
          amount: 0,
          description: '',
          discountedValue: 0,
        };
      },
      async fetchAssetTypes() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/assets/by-borrower/${this.borrowerId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            actions: ['edit', 'delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openDialog(item = null) {
        this.editItem = item;
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = false;
      },
      async handleAddAssetType() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/assets/`, {
            borrower: this.borrowerId,
            ...this.formData,
          });
          this.$root.showSnackbar(`Asset Type has been added for the borrower successfully!`, 'success');
          this.resetFormData();
          await this.fetchAssetTypes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to create asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleEditAssetType(data) {
        if (!this.borrowerId || !this.editItem) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/assets/${this.editItem.id}/`, {
            borrower: this.borrowerId,
            ...data,
          });
          this.$root.showSnackbar(`Asset Type has been updated for the borrower successfully!`, 'success');
          this.closeDialog();
          await this.fetchAssetTypes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to update asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/assets/${item.id}/`);
            this.$root.showSnackbar('Asset type has been deleted successfully', 'success');
            await this.fetchAssetTypes();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchAssetTypes();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
      'formData.assetType': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[newValue]) / 100;
          if (this.formData.amount !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * this.formData.amount;
          }
        },
        immediate: true,
      },
      'formData.amount': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * newValue;
          }
        },
        immediate: true,
      },
      'formData.discountedValue': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.amount * discountFactor) {
            this.formData.amount = newValue / discountFactor;
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
