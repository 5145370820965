<template>
  <div class="relative p-2 border rounded-lg shadow-md h-fit overflow-hidden">
    <div class="w-full h-72 rounded-lg mb-2 relative group">
      <canvas ref="pdfCanvas"></canvas>
      <div v-if="action" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-0 transition duration-300 ease-in-out group-hover:bg-opacity-75">
        <div class="opacity-0 transform scale-75 transition duration-300 ease-in-out group-hover:opacity-100 group-hover:scale-100">
          <button class="bg-blue-500 text-white rounded-lg w-8 h-8 mx-2" @click="editDocument">
            <font-awesome-icon icon="fas fa-pen-to-square" />
          </button>
          <button class="bg-red text-white rounded-lg w-8 h-8 mx-2" @click="handleOpenDeleteConfirmation">
            <font-awesome-icon icon="fas fa-trash" />
          </button>
        </div>
      </div>
    </div>
    <div class="relative group">
      <h2 class="text-center text-md font-semibold mb-2 truncate">{{ title }}</h2>
      <div class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
        {{ title }}
      </div>
    </div>
  </div>
  <!-- Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete the template?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>
<script>
  import * as pdfjsLib from 'pdfjs-dist/webpack';
  import Confirmation from '@/components/Confirmation/index.vue';

  export default {
    name: 'DocumentCard',
    components: {
      Confirmation,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      pdfLink: {
        type: String,
        required: true,
      },
      included: {
        type: Boolean,
        required: false,
        default: false,
      },
      action: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        isDeleteConfirmationOpen: false,
        pdf: null,
      };
    },
    mounted() {
      this.loadPdf();
    },
    methods: {
      async loadPdf() {
        try {
          let pdfToLoad = this.pdfLink;

          if (!pdfToLoad) {
            // If no pdfLink is provided, use the default PDF from the project folder
            pdfToLoad = '/1009.pdf';
          }
          const loadingTask = pdfjsLib.getDocument(pdfToLoad);
          const pdf = await loadingTask.promise;
          this.pdf = pdf;

          const page = await pdf.getPage(1); // Load only the first page
          const viewport = page.getViewport({ scale: 1.0 });

          const canvas = this.$refs.pdfCanvas;
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;

          // Apply blur if the default PDF is being shown
          if (!this.pdfLink) {
            canvas.style.filter = 'blur(1px)'; // Adjust blur level as needed
          } else {
            canvas.style.filter = 'none'; // Remove blur if actual pdfLink is provided
          }
        } catch (error) {
          console.error('Error loading PDF:', error);
        }
      },
      handleOpenDeleteConfirmation() {
        this.isDeleteConfirmationOpen = true;
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      handleConfirmDeleteConfirmation() {
        this.deleteDocument();
      },
      editDocument() {
        // Emit an event or handle the edit action here
        this.$emit('edit');
      },
      deleteDocument() {
        // Emit an event or handle the delete action here
        this.$emit('delete');
      },
    },
    watch: {
      pdfLink: 'loadPdf', // Reload the PDF when the link changes
    },
  };
</script>
<style scoped>
  canvas {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    border: 0.5px solid #ccc;
  }
</style>
