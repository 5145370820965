<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <input
      type="text"
      :id="id"
      v-model="internalValue"
      :class="{ invalid: !isValid && isDirty && !disableValidation }"
      @input="handleInput"
      @blur="validateInput"
      placeholder="(XXX) XXX-XXXX"
    />
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'PhoneNumberInputField',
    props: {
      label: String,
      id: String,
      value: String,
      disableValidation: Boolean,
    },
    data() {
      return {
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      formatInput(value) {
        const cleaned = value.replace(/\D/g, '');
        const part1 = cleaned.slice(0, 3);
        const part2 = cleaned.slice(3, 6);
        const part3 = cleaned.slice(6, 10);
        let formattedValue = part1 ? `(${part1}` : '';
        if (part2) {
          formattedValue += `) ${part2}`;
        }
        if (part3) {
          formattedValue += `-${part3}`;
        }
        return formattedValue;
      },
      handleInput(event) {
        this.internalValue = this.formatInput(event.target.value);
        this.$emit('update:modelValue', this.internalValue);
        this.isDirty = true;
      },
      validateInput() {
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        this.isValid = phonePattern.test(this.internalValue);
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue || '';
        this.validateInput();
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `Invalid ${this.label} format. Expected format: (XXX) XXX-XXXX.`;
        }
        return '';
      },
    },
  };
</script>

<style>
  .invalid {
    border-color: red;
  }
</style>
