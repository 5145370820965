<template>
  <div :class="{ sidebar: true, expanded: show }" class="bg-primary">
    <div class="flex justify-center py-[13px] border-b border-[#113F55]">
      <div class="logo-icon">
        <img v-if="show" src="../../../../assets/image/icon-logo.svg" alt="Logo Image" />
        <img v-else src="../../../../assets/image/logo.svg" alt="Logo Image" />
      </div>
    </div>
    <nav class="navbar px-[10px] py-[10px]">
      <ul>
        <li v-if="!show">
          <form @submit.prevent="handleSearchSubmit" class="relative w-full max-w-md flex mb-3">
            <input v-model="searchQuery" type="text" class="bg-white rounded-lg text-sm text-black-300 py-2 px-4" placeholder="Search" />
            <button type="submit" class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <font-awesome-icon icon="magnifying-glass" class="text-[#A8A8A8]" />
            </button>
          </form>
        </li>
        <li>
          <router-link to="/" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/dashboard-icon.svg" alt="icon" />
              </span>
              <p>Dashboard</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/reporting" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/reporting-icon.svg" alt="icon" />
              </span>
              <p>Reporting</p>
            </div>
          </router-link>
        </li>
        <li v-if="isSuperUser()">
          <router-link to="/companies" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/company-icon.svg" alt="icon" />
              </span>
              <p>Companies</p>
            </div>
          </router-link>
        </li>
        <li v-if="!isCompanyUser()">
          <router-link to="/users" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/account-icon.svg" alt="icon" />
              </span>
              <p>Users</p>
            </div>
          </router-link>
        </li>
        <li v-if="!isCompanyUser()">
          <router-link to="/settings" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/settings-icon.svg" alt="icon" />
              </span>
              <p>Settings</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/licenses" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/licenses-icon.svg" alt="icon" />
              </span>
              <p>My Licenses</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/find-loan" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/loan-icon.svg" alt="icon" />
              </span>
              <p>Find Loan</p>
            </div>
          </router-link>
        </li>
        <li v-if="isCompanyAdmin()">
          <router-link to="/document" class="nav-link flex" exact-active-class="active-exact">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/doc-icon.svg" alt="icon" />
              </span>
              <p>Document Manager</p>
            </div>
          </router-link>
        </li>
        <li>
          <a href="https://support.reversepilot.com/" class="nav-link flex" target="_blank" rel="noopener noreferrer">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/support-icon.svg" alt="icon" />
              </span>
              <p>Help Center</p>
            </div>
          </a>
        </li>
        <!-- Add more navigation links here -->
      </ul>
    </nav>
  </div>
  <Dialog :title="'Search Loan'" :isOpen="isOpenSearchDialog" @confirm="handleConfirmSearchDialog" @close="handleCloseSearchDialog">
    <div class="flex items-center mb-3">
      <input v-model="searchQuery" class="search-input w-full" placeholder="Search ..." />
    </div>
    <DataTable :headers="headers" :data="filteredTableData" @action="handleSearchAction" />
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Sidebar',
    components: {
      Dialog,
      DataTable,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    async created() {
      await this.fetchLoans();
    },
    data() {
      return {
        isOpenSearchDialog: false,
        searchQuery: '',
        headers: [
          { key: 'officerFullName', label: 'Name' },
          { key: 'productName', label: 'Product Name' },
          { key: 'status', label: 'Status' },
          { key: 'amount', label: 'Amount' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchLoans() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/loans/');
          this.tableData = response.data.map((loan) => ({
            id: loan.id,
            officerFullName: loan.officerFullName,
            productName: loan.productName,
            status: loan.status,
            amount: loan.amount ? `$${loan.amount}` : 'Not calculated',
            actions: ['view'],
          }));
        } catch (error) {
          this.tableData = [];
          this.$root.showSnackbar(`Failed to fetch loan data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSearchSubmit() {
        await this.fetchLoans();
        this.isOpenSearchDialog = true;
      },
      handleConfirmSearchDialog() {},
      handleCloseSearchDialog() {
        this.searchQuery = '';
        this.isOpenSearchDialog = false;
      },
      async handleSearchAction(action, item) {
        if (action === 'view') {
          this.$router.push({ name: 'Loan', params: { id: item.id } });
          this.handleCloseSearchDialog();
        }
      },
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((loan) => {
          return loan.officerFullName.toLowerCase().includes(query) || loan.productName.toLowerCase().includes(query) || loan.status.toLowerCase().includes(query);
        });
      },
    },
  };
</script>

<style scoped>
  .sidebar {
    width: 270px;
    transition: width 0.3s ease;
  }

  .expanded {
    width: 70px;
  }
  .sidebar.expanded .logo-icon {
    background: #fff;
    padding: 9px 3px;
  }
  .navbar {
    overflow-y: auto;
    height: calc(100vh - 70px);
  }
  .navbar li p {
    color: #fff;
    font-weight: 500;
    padding-left: 10px;
  }
  .nav-link {
    cursor: pointer;
    padding: 12px 14px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .nav-link.active-exact,
  .nav-link:hover {
    color: #fff;
    background-color: #e78724;
  }

  .sidebar.expanded .nav-link p {
    font-size: 0;
    display: none;
  }
  .sidebar.expanded .navbar {
    padding: 10px 10px;
  }

  .sidebar-search-input {
    background-color: white;
  }
</style>
