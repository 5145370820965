<template>
  <Card title="Payoffs">
    <template v-slot:action>
      <div class="flex gap-1">
        <Button :variant="primary" @click="openDialog">Add Payoff</Button>
      </div>
    </template>

    <div v-if="!existPayoff">
      <div class="flex justify-center items-center">
        <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
          <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-10 mx-auto mb-4" />
          <h2 class="text-lg font-semibold text-gray-800 mb-2">No Payoff yet</h2>
          <p class="text-gray-600 mb-4">You need to create new payoff.</p>
          <div class="flex justify-center gap-4">
            <Button :variant="primary" @click="openDialog">Add Payoff</Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pb-3 mb-3 border-b">
        <div>
          <SelectInput label="Choose Payoff" id="choosePayoff" v-model="selectedPayoff" :value="selectedPayoff" :options="payoffsOptions" :disable-validation="true" />
        </div>
      </div>

      <div v-if="selectedPayoff != -1">
        <Payoff :payoff-id="selectedPayoff" @delete="handleDeletePayoff" @refresh="fetchPayoffs" />
      </div>
    </div>
  </Card>
  <Dialog :title="'Add New Payoff'" :isOpen="isDialogOpen" @close="closeDialog" @confirm="handleAddPayoff">
    <PayoffForm @close="closeDialog" @confirm="handleAddPayoff" />
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  // import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  // import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  // import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Payoff from './components/PayOff/index.vue';
  import PayoffForm from './components/PayoffForm/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Payoffs',
    components: {
      Button,
      Card,
      Dialog,
      // InputField,
      SelectInput,
      // RadioInput,
      // DateInput,
      Payoff,
      PayoffForm,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDialogOpen: false,
        payoffs: [],
        payoffsOptions: [],
        selectedPayoff: -1,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      // Fetch Payoffs data by using API service
      async fetchPayoffs() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/payoffs/by-property/${this.propertyId}/`);
          this.payoffs = response.data;
        } catch (error) {
          this.payoffs = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.payoffs = [];
        } finally {
          this.setLoading(false);
        }
        this.updatePayoffsOptions();
      },
      // Update payoff options according to changed payoffs data.
      updatePayoffsOptions() {
        this.payoffsOptions = this.payoffs.map((el) => ({
          label: `${el.creditorName}`,
          value: el.id,
        }));
      },
      // Update selected payoff id.
      updateSelectedPayoff(mode) {
        if (mode == 'initialize') {
          this.selectedPayoff = this.payoffsOptions[0]?.value ?? -1;
        }
        if (mode == 'add' || mode == 'delete') {
          this.selectedPayoff = this.payoffsOptions[this.payoffsOptions.length - 1]?.value ?? -1;
        }
      },
      openDialog() {
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.isDialogOpen = false;
      },
      async handleAddPayoff(data) {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/payoffs/`, {
            property: this.propertyId,
            ...data,
          });
          this.$root.showSnackbar(`Payoff information has been added for the property successfully!`, 'success');
          await this.fetchPayoffs();
          this.updateSelectedPayoff('add');
          this.closeDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleDeletePayoff() {
        this.setLoading(true);
        try {
          if (this.selectedPayoff != -1) {
            await apiService.delete(`/loan/payoffs/${this.selectedPayoff}/`);
            await this.fetchPayoffs();
            this.updateSelectedPayoff('delete');
            this.$root.showSnackbar('Payoff deleted successfully', 'success');
          } else {
            throw new Error('No Payoff selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete Payoff: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      propertyId: {
        async handler() {
          await this.fetchPayoffs();
          this.updateSelectedPayoff('initialize');
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      existPayoff() {
        return this.payoffs.length > 0;
      },
    },
  };
</script>
