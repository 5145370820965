<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goBack" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Payees</h1>
      </div>
    </template>
    <template v-slot:action>
      <Button @click="openAddDialog">Add Payee</Button>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <!-- add or edit payee -->
  <Dialog :title="`${isOpenDialog} Payee`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div class="form-group">
        <InputField label="Name *" :maxLength="50" :value="formData.name" v-model="formData.name" :disable-validation="true" />
        <span v-if="validationFormErrors.name.length" class="text-sm text-red">
          {{ validationFormErrors.name }}
        </span>
      </div>
      <div v-if="isSuperUser()" class="form-group">
        <SelectInput label="Company" :value="formData.company" v-model="formData.company" :options="companyOptions" />
        <span v-if="validationFormErrors.company.length" class="text-sm text-red">
          {{ validationFormErrors.company }}
        </span>
      </div>
    </div>
    <div class="grid">
      <div class="form-group flex items-center">
        <CheckboxInput label="Active" :value="formData.isActive" v-model="formData.isActive" />
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog">Save Payee</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Payee Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this payee?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';

  const initialData = {
    name: '',
    company: null,
    isActive: false,
    is_delete: false,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Payees',
    components: {
      Button,
      Card,
      CheckboxInput,
      DataTable,
      Dialog,
      InputField,
      SelectInput,
      Confirmation,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    data() {
      return {
        tableHeaders: [
          { key: 'name', label: 'Name' },
          { key: 'company', label: 'Company' },
          { key: 'isActive', label: 'Active' },
          { key: 'action', label: 'Action' },
        ],
        payees: [],
        tableData: [],
        isOpenDialog: '',
        formData: {},
        companyOptions: [],
        validationFormErrors: {
          name: '',
          company: '',
        },
        selectedPayee: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      if (isCompanyUser()) {
        this.redirectToDashboard();
        return;
      }
      await this.fetchCompanies();
      await this.fetchPayees();
    },
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      async fetchCompanies() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/company/companies/');
          this.companyOptions = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        } catch (error) {
          this.companyOptions = [];
          this.$root.showSnackbar(`Failed to fetch company data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchPayees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/payees/`);
          this.payees = response.data;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.payees.find((el) => el.id == id);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      validateForm() {
        this.validationFormErrors = {
          name: !this.formData.name?.length ? 'This field is required.' : '',
          company: !this.formData?.company && isSuperUser() ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.name.length && !this.validationFormErrors.company.length;
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.isCompanyAdmin) {
            delete this.formData.company;
          }
          if (this.formData.id) {
            await apiService.patch(`/loan/payees/${this.formData.id}/`, { ...this.formData });
          } else {
            await apiService.post(`/loan/payees/`, {
              ...this.formData,
            });
          }
          this.$root.showSnackbar(`Payee information has been updated successfully!`, 'success');
          await this.fetchPayees();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
        this.validationFormErrors = {
          name: '',
          company: '',
        };
      },
      updateTableData() {
        let newTableData = [];
        this.payees.forEach((payee) => {
          newTableData.push({
            ...payee,
            company: this.companyOptions.find((company) => company.value === payee.company)?.label,
            actions: ['edit', 'delete'],
          });
        });
        this.tableData = newTableData;
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedPayee = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/payees/${this.selectedPayee.id}/`);
          this.$root.showSnackbar('Payee information has been deleted successfully', 'success');
          await this.fetchPayees();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete payee information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      goBack() {
        this.$router.push({ name: 'Settings' });
      },
    },
    computed: {},
    watch: {
      payees: {
        handler() {
          this.updateTableData();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }
</style>
