<template>
  <div class="dropdown-container inline-flex m-0 items-center font-medium leading-none p-2 rounded-full lg:!text-[10px] xl:!text-[12px] 2xl:!text-sm">
    <button :class="className" @click="toggleMenu" :disabled="disabled" ref="dropdown">
      <slot name="button" v-if="$slots.button"></slot>
      <div v-else>
        {{ title }}
      </div>
    </button>
    <div v-if="isOpen" class="dropdown-panel" @click="toggleMenu">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Dropdown',
    props: {
      className: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      handleClickOutside(event) {
        if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
          this.isOpen = false;
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  };
</script>

<style scoped>
  .dropdown-container {
    position: relative;
    display: inline-block;
  }
  .dropdown-panel {
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-height: 360px;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: 0;
    margin-top: 4px;
    z-index: 1;
    width: auto;
  }
</style>
