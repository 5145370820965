<template>
  <Card title="Loan Program Comparison">
    <template v-slot:action>
      <div class="flex items-center">
        <div>
          <button class="standard-btn" :disabled="false" @click="openDialog">Select Products</button>
        </div>
      </div>
    </template>

    <!-- Alert panel when there is no borrower for this loan -->
    <div v-if="noBorrower" class="flex justify-center items-center">
      <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <img src="../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
        <h2 class="text-2xl font-semibold text-gray-800 mb-2">Invalid Loan For Calculation</h2>
        <p class="text-gray-600 mb-4">There is no existing borrower for this loan, so not able to calculate.</p>
      </div>
    </div>

    <!-- Alert panel when there is no avilable product -->
    <div v-if="noAvailableProduct && !noBorrower" class="flex justify-center">
      <div class="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <img src="../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
        <h2 class="text-2xl font-semibold text-gray-800 mb-2">No Default Product</h2>
        <p class="text-gray-600 mb-4">There is no selected product, you need to select at least one.</p>
        <button class="standard-btn" :disabled="false" @click="openDialog">Select Products</button>
      </div>
    </div>

    <!-- Main table panel for calculation results for each products -->
    <div class="w-full overflow-x-auto" v-if="!noAvailableProduct && !noBorrower">
      <table class="border-collapse border border-gray w-full price-comparison-table">
        <tbody class="">
          <tr v-if="productsData.length > 0">
            <th></th>
            <th></th>
            <th v-for="(product, index) in productsData" :key="index" @dblclick="handleActivate(product.id)" :class="{ 'text-white bg-[#104862]': product.id == activeProduct }">
              <div class="flex justify-between w-full">
                <span>{{ product.name }}</span>
                <button @click="handleHideProduct(product.id)">
                  <font-awesome-icon icon="fas fa-eye-slash" />
                </button>
              </div>
            </th>
          </tr>

          <tr>
            <th rowspan="10">Interest Rate</th>
            <td>Interest Rate Type</td>
            <td v-for="(data, index) in productsData" :key="index">{{ interestRateTypes[data.interestRateType] }}</td>
          </tr>
          <tr>
            <td>Initial Index Name</td>
            <td v-for="(data, index) in productsData" :key="index">{{ indexNames[data.initialIndexName] }}</td>
          </tr>
          <tr>
            <td>Initial Index Rate</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.initialIndexRate) }}%</td>
          </tr>
          <tr>
            <td>Expected Index Name</td>
            <td v-for="(data, index) in productsData" :key="index">{{ indexNames[data.expectedIndexName] }}</td>
          </tr>
          <tr>
            <td>Expected Index Rate</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.expectedIndexRate) }}%</td>
          </tr>
          <tr>
            <td>Margin (%)</td>
            <td v-for="(data, index) in productsData" :key="index">
              <SelectInput
                v-if="data.interestRateType !== 'fixed'"
                :options="marginOptions"
                :value="data.margin"
                v-model="data.margin"
                :disable-validation="true"
                @change="handleProductVariableChange('margin', index)"
              />
              <div v-else>Not Applicable</div>
            </td>
          </tr>
          <tr>
            <td>Expected Rate</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.expectedRate) }}%</td>
          </tr>
          <tr>
            <td>MIP Rate (%)</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.mipRate) }}%</td>
          </tr>
          <tr>
            <td>Monthly Servicing Fee</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.monthlyServicingFee) }}</td>
          </tr>
          <tr>
            <td>Line of Credit Growth Rate</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.lineOfCreditGrowthRate) }}%</td>
          </tr>

          <tr>
            <th rowspan="3">Home Value</th>
            <td>Home Value</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.homeValue) }}</td>
          </tr>
          <tr>
            <td>Lending Limit</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lendingLimit) }}</td>
          </tr>
          <tr>
            <td>Max Claim Amount</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.maxClaimAmount) }}</td>
          </tr>

          <tr>
            <th rowspan="2">Closing Costs</th>
            <td>Initial Mortgage Insurance Premium</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.imip) }}</td>
          </tr>
          <tr>
            <td>Mandatory Obiligations</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.mandatoryObiligations) }}</td>
          </tr>

          <tr>
            <th rowspan="5">Payoffs And Set Asides</th>
            <td>Total Payoffs</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.totalPayoffs) }}</td>
          </tr>
          <tr>
            <td>Repair Set Aside</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.repairSetAsideAmount) }}</td>
          </tr>
          <tr>
            <td>Life Expectancy Set Aside</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lifeExpectancySetAside) }}</td>
          </tr>
          <tr>
            <td>Life Expectancy Set Aside 1st Year</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lifeExpectancySetAside1stYear) }}</td>
          </tr>
          <tr>
            <td>Remaining Life Expectancy Set Aside</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.remainingLifeExpectancySetAside) }}</td>
          </tr>

          <tr>
            <th rowspan="3">Cash Out Available</th>
            <td>Funds Available At Closing</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.fundsAvailableAtClosing) }}</td>
          </tr>
          <tr>
            <td>Line of Credit After Year 1</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lineOfCreditAfterYear1) }}</td>
          </tr>
          <tr>
            <td>Monthly Tenure Available</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.monthlyTenureAvailable) }}%</td>
          </tr>

          <tr>
            <th rowspan="3">Principal Limit</th>
            <td>Principal Limit</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.principalLimit) }}</td>
          </tr>
          <tr>
            <td>PLF</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.plf) }}%</td>
          </tr>
          <tr>
            <td>Servicing Fee Set Aside</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.servicingFeeSetAside) }}</td>
          </tr>

          <tr>
            <th rowspan="6">Cash Request</th>
            <td>Cash Request</td>
            <td v-for="(data, index) in productsData" :key="index">
              <MoneyInput
                :value="data.requestedCashoutAmountAtClosing"
                v-model="data.requestedCashoutAmountAtClosing"
                :disable-validation="true"
                @input-change="handleProductVariableChange('cashout', index)"
              />
            </td>
          </tr>
          <tr>
            <td>Monthly Payout Request</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.monthlyPayoutRequest) }}</td>
          </tr>
          <tr>
            <td>Term (months)</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formattedNumber(data.term) }}</td>
          </tr>
          <tr>
            <td>Initial Disbursement Limit</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.idl) }}</td>
          </tr>
          <tr>
            <td>Line of Credit Available 1st Year</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lineOfCreditAvailableYear1) }}</td>
          </tr>
          <tr>
            <td>Line of Credit Available After 1st year</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.lineOfCreditAfterYear1) }}</td>
          </tr>

          <tr>
            <th rowspan="1">Pricing Details</th>
            <td>Initial Balance, Utilization</td>
            <td v-for="(data, index) in productsData" :key="index">${{ formattedNumber(data.initialBalance) }}, {{ formattedNumber(data.utilization) }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
  <ProductsDialog :is-open="isDialogOpen" :products="products" @close="closeDialog" @confirm="handleDialogConfirm" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { debounce } from 'lodash';
  import apiService from '@/api/apiService';
  import { formatNumberWithCommas } from '@/utils/index.js';
  import Card from '@/components/Card/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import ProductsDialog from './components/ProductsDialog/index.vue';
  import { MARGINS, RADIO_OPTIONS, INTEREST_RATE_TYPE_OPTIONS, INTEREST_RATE_TYPES, INDEX_NAMES } from '@/constants';

  const initialProductData = {
    youngestBorrowerAge: -1,
    interestRateType: 'adjustable',
    initialIndexName: '1_month',
    initialIndexRate: 0,
    expectedIndexName: '1_month',
    expectedIndexRate: 0,
    margin: 1.5,
    expectedRate: 0,
    mipRate: 0.5,
    monthlyServicingFee: 0,
    lineOfCreditGrowthRate: 0,
    homeValue: 0,
    lendingLimit: 0,
    maxClaimAmount: 0,
    imip: 0,
    mandatoryObiligations: 0,
    totalPayoffs: 0,
    repairSetAsideAmount: 0,
    lifeExpectancySetAside: 0,
    lifeExpectancySetAside1stYear: 0,
    remainingLifeExpectancySetAside: 0,
    fundsAvailableAtClosing: 0,
    lineOfCreditAfterYear1: 0,
    monthlyTenureAvailable: 0,
    requestedCashoutAmountAtClosing: 0,
    monthlyPayoutRequest: 0,
    term: 0,
    idl: 0,
    lineOfCreditAvailableYear1: 0,
    initialBalance: 0,
    utilization: 0,
    principalLimit: 0,
    plf: 0,
    servicingFeeSetAside: 0,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Comparison',
    components: {
      Card,
      MoneyInput,
      SelectInput,
      ProductsDialog,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        activeProduct: null,
        products: [],
        productsData: [],
        noBorrower: false,
        noAvailableProduct: false,
        indexNames: INDEX_NAMES,
        interestRateTypes: INTEREST_RATE_TYPES,
        interestRateTypeOptions: INTEREST_RATE_TYPE_OPTIONS,
        marginOptions: MARGINS,
        resaRequiredOptions: RADIO_OPTIONS,
        hecmOptions: RADIO_OPTIONS,
        isDialogOpen: false,
        debouncedUpdateCalcResult: null,
      };
    },
    async created() {
      await this.fetchProducts();
      this.debouncedUpdateCalcResult = debounce(this.updateProductCalcResult, 1000);
    },
    methods: {
      ...mapActions(['setLoading']),
      async getActiveProduct() {
        try {
          const response = await apiService.get(`/loan/loans/${this.$route.params.id}/`);
          this.activeProduct = response.data.loanProduct;
        } catch (error) {
          this.activeProduct = null;
        }
      },
      async fetchProducts() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/products/');
          this.products = response.data;
          await this.fetchLoanCalculationResults();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchLoanCalculationResults() {
        if (!this.products.length) {
          this.productsData = [];
        } else {
          this.setLoading(true);
          try {
            const defaultProducts = this.products.filter((product) => product.default === true);
            const calculationPromises = defaultProducts.map((product) =>
              this.fetchCalculationResult({
                ...initialProductData,
                ...product,
                initialIndexRate: product.fixedRate ?? 7.92, // Only fixed loan product have fixed rate.
              })
            );

            // Use Promise.allSettled to handle each promise individually
            const results = await Promise.allSettled(calculationPromises);

            // Filter out successful responses and map to your desired structure
            this.productsData = results.reduce((accumulator, result, index) => {
              if (result.status === 'fulfilled') {
                accumulator.push({
                  ...initialProductData,
                  ...defaultProducts[index],
                  ...result.value.data,
                });
              }
              return accumulator;
            }, []);

            // Check if there are any rejections due to no borrower
            const noBorrowerResults = results.filter((result) => result.status === 'rejected' && result.reason.response?.status == 405);
            this.noBorrower = noBorrowerResults.length > 0;
          } catch (error) {
            this.$root.showSnackbar(`${error.message || 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        // If there is no products data, then no need to display anything.
        this.noAvailableProduct = !this.productsData.length;
      },
      async fetchCalculationResult(requestBody) {
        return await apiService.post('/loan/loan-calculation/', {
          ...requestBody,
          loan: this.loanId,
        });
      },
      async updateProductCalcResult(index) {
        try {
          this.setLoading(true);
          const calcResponse = await this.fetchCalculationResult({ ...this.productsData[index] });
          if (calcResponse.statusText === 'OK') {
            this.productsData[index] = { ...this.productsData[index], ...calcResponse.data };
          } else {
            console.error('Unexpected response status:', calcResponse.statusText);
            this.$root.showSnackbar(`Unexpected response status: ${calcResponse.statusText})`, 'error');
          }
        } catch (error) {
          console.error('Error updating product calculation result:', error);
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleProductVariableChange(field, index) {
        if (field === 'margin') {
          await this.updateProductCalcResult(index);
        } else if (field === 'cashout') {
          this.debouncedUpdateCalcResult(index);
        }
      },
      async handleActivate(productId) {
        if (productId == this.activeProduct) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/loans/${this.loanId}/activate_product/`, { productId: productId });
          this.$root.showSnackbar(`Loan's active product has been changed successfully!`, 'success');
          this.activeProduct = productId;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleHideProduct(productId) {
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/products/${productId}/`, { default: false });
          await this.fetchProducts();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Add product logic beginning.
      openDialog() {
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.isDialogOpen = false;
      },
      async handleDialogConfirm() {
        await this.fetchProducts();
        this.closeDialog();
      },
      formattedNumber(value) {
        return formatNumberWithCommas(value);
      },
    },
    watch: {
      loanId: {
        async handler(newValue, oldValue) {
          if (newValue) {
            await this.getActiveProduct();
          }
          if (newValue && oldValue) {
            await this.fetchProducts();
          }
        },
        immediate: true,
      },
    },
  };
</script>
<style scoped>
  th,
  td {
    border: 1px solid #ddd;
    padding: 1rem;
    text-align: left;
  }
  tr {
    border: 1px solid transparent;
  }
  .bg-lightblue {
    background-color: #f6f9fc;
  }
</style>
