<template>
  <Card title="Title Related Details">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
      <div>
        <TextareaInput
          label="Legal Description"
          id="legalDescription"
          ref="legalDescription"
          :value="formData.legalDescription"
          v-model.trim="formData.legalDescription"
          :disable-validation="true"
        />
      </div>
      <div>
        <TextareaInput label="Legal Vesting" id="legalVesting" ref="legalVesting" :value="formData.legalVesting" v-model.trim="formData.legalVesting" :disable-validation="true" />
      </div>
      <div>
        <InputField
          label="Brief Legal Description"
          id="briefLegalDescription"
          ref="briefLegalDescription"
          :value="formData.briefLegalDescription"
          v-model.trim="formData.briefLegalDescription"
          :disable-validation="true"
        />
      </div>
      <div>
        <InputField
          label="Notary Ackowledgement of Security Instrument"
          id="notaryAcknowledgementOfSecurityInstrument"
          ref="notaryAcknowledgementOfSecurityInstrument"
          :value="formData.notaryAcknowledgementOfSecurityInstrument"
          v-model.trim="formData.notaryAcknowledgementOfSecurityInstrument"
          :disable-validation="true"
        />
      </div>
      <div>
        <SelectInput label="Living Trust" :options="livingTrustOptions" v-model="formData.livingTrust" :value="formData.livingTrust" :disable-validation="true" />
      </div>
      <div>
        <SelectInput label="Estate" :options="estateOptions" v-model="formData.estate" :value="formData.estate" :disable-validation="true" />
      </div>
      <RadioInput label="Property is in PUD?" id="propertyIsInPUD" :value="formData.propertyIsInPUD" :options="radioInputOptions" v-model="formData.propertyIsInPUD" />
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import Button from '@/components/Button/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Title',
    components: {
      Button,
      Card,
      TextareaInput,
      InputField,
      SelectInput,
      RadioInput,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        localData: {
          legalDescription: null,
          legalVesting: null,
          briefLegalDescription: null,
          notaryAcknowledgementOfSecurityInstrument: null,
          livingTrust: null,
          estate: 'Fee Simple',
          propertyIsInPUD: null,
        },
        formData: {
          legalDescription: null,
          legalVesting: null,
          briefLegalDescription: null,
          notaryAcknowledgementOfSecurityInstrument: null,
          livingTrust: null,
          estate: 'Fee Simple',
          propertyIsInPUD: null,
        },
        livingTrustOptions: [
          { label: 'None', value: 'None' },
          { label: 'Living Trust', value: 'Living Trust' },
          { label: 'Anonymous Land Trust', value: 'Anonymous Land Trust' },
        ],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        estateOptions: [
          { label: 'Fee Simple', value: 'Fee Simple' },
          { label: 'Life Estate', value: 'Life Estate' },
          { label: 'Leasehold', value: 'Leasehold' },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchTitle() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/titles/by-property/${this.propertyId}/`);
          this.localData = response.data;
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/titles/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/titles/`, {
              property: this.propertyId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Title information has been updated successfully!`, 'success');
          await this.fetchTitle();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      resetLocalData() {
        this.localData = {
          legalDescription: null,
          legalVesting: null,
          briefLegalDescription: null,
          notaryAcknowledgementOfSecurityInstrument: null,
          livingTrust: null,
          estate: 'Fee Simple',
          propertyIsInPUD: null,
        };
      },
    },
    watch: {
      propertyId: {
        handler() {
          this.fetchTitle();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
