<template>
  <div class="flex items-center justify-between">
    <div>
      <PageHeader title="Welcome Back,  Laura Collins !" />
      <p class="text-[#8C9097] -mt-3 text-sm">Track your sales activity, leads and deals here.</p>
    </div>
    <SearchInput @submit-search="handleSearch" />
  </div>
  <div class="my-15 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
    <WelcomeCard />
    <ReportsCard />
  </div>
  <div class="my-15 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
    <RevenueAnalytics />
    <DealStatus />
    <RecentTransactions />
  </div>

  <Card title="My Loan">
    <template v-slot:action>
      <div class="flex items-center">
        <input class="search-input" placeholder="Search ..." />
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" />
  </Card>
</template>

<script>
  import PageHeader from './../../components/PageHeader/index.vue';
  import DataTable from './../../components/DataTable/index.vue';
  import SearchInput from './../../components/FormInput/SearchInput/input.vue';
  import Card from './../../components/Card/index.vue';
  import WelcomeCard from './components/WelcomeCard/index.vue';
  import ReportsCard from './components/ReportsCard/index.vue';
  import RecentTransactions from './components/RecentTransactions/index.vue';
  import DealStatus from './components/DealStatus/index.vue';
  import RevenueAnalytics from './components/RevenueAnalytics/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Dashboard',
    components: {
      PageHeader,
      DataTable,
      Card,
      SearchInput,
      WelcomeCard,
      ReportsCard,
      DealStatus,
      RecentTransactions,
      RevenueAnalytics,
    },
    data() {
      return {
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'loanProductName', label: 'Loan Product Name' },
          { key: 'loanDate', label: 'Date' },
          { key: 'status', label: 'Status' },
          { key: 'loanAmount', label: 'Loan Amount' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [
          {
            id: 1,
            name: 'Mayor Kelly ',
            loanProductName: 'Small business loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Adversed',
            loanAmount: '$25,00,00',
            actions: ['view'],
          },
          {
            id: 2,
            name: 'Andrew Garfield ',
            loanProductName: 'Personal loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'InActive',
            loanAmount: '$50,00,00',
            actions: ['view'],
          },
          {
            id: 3,
            name: 'Simon Cowel',
            loanProductName: 'Home loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'OnHold',
            loanAmount: '$2,00,000',
            actions: ['view'],
          },
          {
            id: 4,
            name: 'Mirinda Hers ',
            loanProductName: 'Unsecured loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Prospect',
            loanAmount: '$60,00',
            actions: ['view'],
          },
          {
            id: 5,
            name: 'Jacob Smith ',
            loanProductName: 'Education loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Origination',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 6,
            name: 'Mayor Kelly ',
            loanProductName: 'Loan against property',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Processing',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 7,
            name: 'Jacob Smith ',
            loanProductName: 'Small business loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Processing',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 8,
            name: 'Jacob Smith ',
            loanProductName: 'Personal loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Underwriting',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 9,
            name: 'Jacob Smith ',
            loanProductName: 'Home loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'ClearToClose',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 10,
            name: 'Jacob Smith ',
            loanProductName: 'Unsecured loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Closing',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 11,
            name: 'Jacob Smith ',
            loanProductName: 'Education loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Funding',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 12,
            name: 'Jacob Smith ',
            loanProductName: 'Loan against property',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Shipping',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 13,
            name: 'Jacob Smith ',
            loanProductName: 'Small business loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'Post Closing',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 14,
            name: 'Jacob Smith ',
            loanProductName: 'Small business loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'PrePurchaseQC',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 15,
            name: 'Jacob Smith ',
            loanProductName: 'Small business loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'PrePurchase',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
          {
            id: 16,
            name: 'Jacob Smith ',
            loanProductName: 'Unsecured loans',
            loanDate: 'Sep 15 - Oct 12, 2023',
            status: 'PurchaseCompleted',
            loanAmount: '$30,00,000',
            actions: ['view'],
          },
        ],
      };
    },
    methods: {
      handleSearch(query) {
        this.searchResult = query;
        console.log('Search query from App.vue:', query);
        // Add your search logic here, such as making an API call with the query
      },
    },
  };
</script>
