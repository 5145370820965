<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
    </div>
    <div class="border rounded-lg">
      <div class="box">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-15">
          <div>
            <SelectInput label="Income Type" id="incomeType" :options="incomeTypeOptions" v-model="formData.incomeType" :value="formData.incomeType" :disable-validation="true" />
          </div>
          <div>
            <MoneyInput label="Monthly Income" id="monthly" ref="monthly" :value="formData.monthlyIncome" v-model.trim="formData.monthlyIncome" :disable-validation="true" />
          </div>
          <div>
            <MoneyInput label="Annual Income" id="annual" ref="annual" :value="formData.annualIncome" v-model.trim="formData.annualIncome" :disable-validation="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-5">
      <Button variant="primary" style="width: fit-content" :disabled="!isValidForm" @click="handleAddIncome"> Add Income Source </Button>
    </div>
    <!-- Edit Dialog Component -->
    <IncomeDialog :is-open="isDialogOpen" :edit-item="editItem" :income-type-options="incomeTypeOptions" @close="closeDialog" @confirm="handleEditIncome" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import IncomeDialog from './components/IncomeDialog/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Income',
    components: {
      Card,
      MoneyInput,
      DataTable,
      SelectInput,
      Button,
      IncomeDialog,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isValidForm: false,
        tableHeaders: [
          { key: 'incomeType', label: 'Income Type' },
          { key: 'monthlyIncome', label: 'Monthy Income' },
          { key: 'annualIncome', label: 'Annual Income' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        formData: {
          incomeType: 'Employment',
          monthlyIncome: 0,
          annualIncome: 0,
        },
        incomeTypeOptions: [
          { label: 'Employment', value: 'Employment' },
          { label: 'Pension', value: 'Pension' },
          { label: 'Social Security', value: 'Social Security' },
          { label: 'Disability', value: 'Disability' },
          { label: 'Alimony', value: 'Alimony' },
          { label: 'IRA and 401k Income', value: 'IRA and 401k Income' },
          {
            label: 'Rental Income from Subject Property',
            value: 'Rental Income from Subject Property',
          },
          { label: 'Rental Income from REO', value: 'Rental Income from REO' },
          {
            label: 'Rental Income from Boarders',
            value: 'Rental Income from Boarders',
          },
          { label: 'Expected Income', value: 'Expected Income' },
          { label: 'Annuity Income', value: 'Annuity Income' },
          { label: 'Trust Account', value: 'Trust Account' },
          { label: 'Notes Receivable', value: 'Notes Receivable' },
        ],
        isDialogOpen: false,
        editItem: null,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfValidForm() {
        this.isValidForm = this.formData.monthlyIncome > 0 && this.formData.annualIncome > 0;
      },
      resetFormData() {
        this.formData = {
          incomeType: 'Employment',
          monthlyIncome: 0,
          annualIncome: 0,
        };
      },
      async fetchIncomes() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/incomes/by-borrower/${this.borrowerId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            actions: ['edit', 'delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      openDialog(item = null) {
        this.editItem = item;
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = false;
      },
      async handleAddIncome() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/incomes/`, {
            borrower: this.borrowerId,
            ...this.formData,
          });
          this.$root.showSnackbar(`Income has been added for the borrower successfully!`, 'success');
          await this.fetchIncomes();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleEditIncome(data) {
        if (!this.borrowerId || !this.editItem) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/incomes/${this.editItem.id}/`, {
            ...data,
            borrower: this.borrowerId, // Need to add this line later cause data contains borrower object.
          });
          this.$root.showSnackbar(`Income has been updated for the borrower successfully!`, 'success');
          this.closeDialog();
          await this.fetchIncomes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to update income: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/incomes/${item.id}/`);
            this.$root.showSnackbar('Income has been deleted successfully', 'success');
            await this.fetchIncomes();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete income: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchIncomes();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
      'formData.monthlyIncome': {
        handler(newValue) {
          const annual = (newValue * 12).toFixed(2);
          if (parseFloat(this.formData.annualIncome).toFixed(2) !== annual) {
            this.formData.annualIncome = parseFloat(annual);
          }
        },
        immediate: true,
      },
      'formData.annualIncome': {
        handler(newValue) {
          const monthly = (newValue / 12).toFixed(2);
          if (parseFloat(this.formData.monthlyIncome).toFixed(2) !== monthly) {
            this.formData.monthlyIncome = parseFloat(monthly);
          }
        },
        immediate: true,
      },
    },
  };
</script>
