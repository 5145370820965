<template>
  <Card title="Appraisal Details">
    <template v-slot:action>
      <div class="flex gap-1">
        <Button :variant="primary" @click="handleAddAppraisal">Add Appraisal</Button>
        <!-- <Button :variant="primary">Import Appraisal Details from XML</Button> -->
      </div>
    </template>
    <div v-if="!existAppraisal">
      <div class="flex justify-center items-center">
        <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
          <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-10 mx-auto mb-4" />
          <h2 class="text-lg font-semibold text-gray-800 mb-2">No Appraisals yet</h2>
          <p class="text-gray-600 mb-4">You need to create new appraisal.</p>
          <div class="flex justify-center gap-4">
            <Button :variant="primary" @click="handleAddAppraisal">Add Appraisal</Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pb-3 mb-4 border-b">
          <div>
            <SelectInput
              label="Choose Appraisal"
              id="chooseAppraisal"
              v-model="selectedAppraisal"
              :value="selectedAppraisal"
              :options="appraisalOptions"
              :disable-validation="true"
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
            <RadioInput
              label="Will be used for calculation?"
              id="useAppraisalForCalculations"
              v-model="formData.useAppraisalForCalculations"
              :value="formData.useAppraisalForCalculations"
              :options="radioInputOptions"
              @change="handleChangeAppraisalUsageForCalc"
            />
            <IntegerInput
              label="Number of Units"
              id="numberOfUnits"
              ref="numberOfUnits"
              v-model="formData.numberOfUnits"
              :value="formData.numberOfUnits"
              :disable-validation="true"
            />
          </div>
          <span v-if="noAppraisalForCalculation" class="text-sm text-red">You must select an appraisal to use for the loan calculation.</span>
        </div>
        <!-- Value -->
        <div class="mt-4">
          <h3 class="pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Value</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <MoneyInput
                label="Appraised Value"
                id="appraisedValue"
                ref="appraisedValue"
                v-model="formData.appraisedValue"
                :value="formData.appraisedValue"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Appraisal Less Land"
                id="appraisalLessLand"
                ref="appraisalLessLand"
                v-model="formData.appraisalLessLand"
                :value="formData.appraisalLessLand"
                :disable-validation="true"
              />
            </div>
            <div>
              <MoneyInput
                label="Appraisal Cost Now"
                id="appraisalCostNow"
                ref="appraisalCostNow"
                v-model="formData.appraisalCostNew"
                :value="formData.appraisalCostNew"
                :disable-validation="true"
              />
            </div>
          </div>
        </div>
        <!-- Land -->
        <div>
          <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Land</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <FloatInput
                label="Property (sqft)"
                id="propertySquareFootage"
                ref="propertySquareFootage"
                v-model="formData.propertySquareFootage"
                :value="formData.propertySquareFootage"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Property Acreage"
                id="propertyAcreage"
                ref="propertyAcreage"
                v-model="formData.propertyAcreage"
                :value="formData.propertyAcreage"
                :disable-validation="true"
              />
            </div>
          </div>
        </div>
        <!-- Appraiser Details -->
        <div>
          <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Appraiser Details</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <InputField label="First Name" id="firstName" ref="firstName" v-model="formData.firstName" :value="formData.firstName" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Middle Name" id="middleName" ref="middleName" v-model="formData.middleName" :value="formData.middleName" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Last Name" id="lastName" ref="lastName" v-model="formData.lastName" :value="formData.lastName" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Address Line 1" id="addressLine1" ref="addressLine1" v-model="formData.addressLine1" :value="formData.addressLine1" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Address Line 2" id="addressLine2" ref="addressLine2" v-model="formData.addressLine2" :value="formData.addressLine2" :disable-validation="true" />
            </div>
            <div>
              <InputField label="City" id="city" ref="city" v-model="formData.city" :value="formData.city" :disable-validation="true" />
            </div>
            <div>
              <InputField label="State" id="state" ref="state" v-model="formData.state" :value="formData.state" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Zip" id="zip" ref="zip" v-model="formData.zip" :value="formData.zip" :disable-validation="true" />
            </div>
            <div>
              <PhoneNumberInputField
                label="Mobile Number"
                id="mobileNumber"
                ref="mobileNumber"
                v-model="formData.mobileNumber"
                :value="formData.mobileNumber"
                :disable-validation="true"
              />
            </div>
            <div>
              <InputField label="Email Address" id="emailAddress" ref="emailAddress" v-model="formData.email" :value="formData.email" :disable-validation="true" />
            </div>
            <div>
              <InputField label="License Number" id="licenseNumber" ref="licenseNumber" v-model="formData.license" :value="formData.license" :disable-validation="true" />
            </div>
          </div>
        </div>
        <!-- Appraisal Analysis -->
        <div>
          <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Appraisal Analysis</h3>
          <div>
            <div class="mt-4">
              <RadioInput
                label="Does the appraisal report represent a consistent and fair analysis of the property?"
                id="representsFairAnalysis"
                v-model="formData.representsFairAnalysis"
                :value="formData.representsFairAnalysis"
                :options="radioInputOptions"
              />
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="representsFairAnalysisExplanation"
                  ref="representsFairAnalysisExplanation"
                  v-model="formData.representsFairAnalysisExplanation"
                  :value="formData.representsFairAnalysisExplanation"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <RadioInput
                  label="Are the comparables acceptable?"
                  id="comparablesAcceptable"
                  v-model="formData.comparablesAcceptable"
                  :value="formData.comparablesAcceptable"
                  :options="radioInputOptions"
                />
              </div>
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="comparablesAcceptableExplanation"
                  ref="comparablesAcceptableExplanation"
                  v-model="formData.comparablesAcceptableExplanation"
                  :value="formData.comparablesAcceptableExplanation"
                />
              </div>
            </div>
            <div class="mt-4">
              <RadioInput
                label="Are the adjustments acceptable both as to the items adjusted and the amount collected to each item adjusted?"
                id="floodInsuranceRequired"
                v-model="formData.adjustmentsAcceptable"
                :value="formData.adjustmentsAcceptable"
                :options="radioInputOptions"
              />
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="adjustmentsAcceptableExplanation"
                  ref="adjustmentsAcceptableExplanation"
                  v-model="formData.adjustmentsAcceptableExplanation"
                  :value="formData.adjustmentsAcceptableExplanation"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <RadioInput
                  label="Is the value acceptable for FHA/HUD purposes?"
                  id="valueAcceptableForFhaHudPurposes"
                  v-model="formData.valueAcceptableForFhaHudPurposes"
                  :value="formData.valueAcceptableForFhaHudPurposes"
                  :options="radioInputOptions"
                />
              </div>
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="valueAcceptableForFhaHudPurposesExplanation"
                  ref="valueAcceptableForFhaHudPurposesExplanation"
                  v-model="formData.valueAcceptableForFhaHudPurposesExplanation"
                  :value="formData.valueAcceptableForFhaHudPurposesExplanation"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <RadioInput
                  label="If not, should it be corrected?"
                  id="ifNotShouldItBeCorrected"
                  v-model="formData.ifNotShouldItBeCorrected"
                  :value="formData.ifNotShouldItBeCorrected"
                  :options="radioInputOptions"
                />
              </div>
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="ifNotShouldItBeCorrectedExplanation"
                  ref="ifNotShouldItBeCorrectedExplanation"
                  v-model="formData.ifNotShouldItBeCorrectedExplanation"
                  :value="formData.ifNotShouldItBeCorrectedExplanation"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <IntegerInput
                  label="Value for FHA/HUD purposes"
                  id="valueForFhaHudPurposes"
                  ref="valueForFhaHudPurposes"
                  v-model="formData.valueForFhaHudPurposes"
                  :value="formData.valueForFhaHudPurposes"
                  :options="radioInputOptions"
                />
              </div>
              <div class="mt-4">
                <TextareaInput
                  label="Explain"
                  id="valueForFhaHudPurposesExplanation"
                  v-model="formData.valueForFhaHudPurposesExplanation"
                  :value="formData.valueForFhaHudPurposesExplanation"
                />
              </div>
              <div class="mt-4">
                <TextareaInput label="Repair Conditions" id="repairConditions" ref="repairConditions" v-model="formData.repairConditions" :value="formData.repairConditions" />
                <TextareaInput label="Other Comments" id="otherComments" ref="otherComments" v-model="formData.otherComments" :value="formData.otherComments" />
              </div>
            </div>
          </div>
        </div>
        <!-- Improvements -->
        <div>
          <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Improvements</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <IntegerInput label="Month Built" id="monthBuilt" ref="monthBuilt" v-model="formData.monthBuilt" :value="formData.monthBuilt" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Year Built" id="yearBuilt" ref="yearBuilt" v-model="formData.yearBuilt" :value="formData.yearBuilt" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Structure Age" id="structureAge" ref="structureAge" v-model="formData.structureAge" :value="formData.structureAge" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput
                label="Remaining Economic Life"
                id="remainingEconomicLife"
                ref="remainingEconomicLife"
                v-model="formData.remainingEconomicLife"
                :value="formData.remainingEconomicLife"
                :disable-validation="true"
              />
            </div>
            <div>
              <InputField label="Dwelling Type" id="dwellingType" ref="dwellingType" v-model="formData.dwellingType" :value="formData.dwellingType" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Living Units" id="livingUnits" ref="livingUnits" v-model="formData.livingUnits" :value="formData.livingUnits" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Bedrooms" id="bedrooms" ref="bedrooms" v-model="formData.bedrooms" :value="formData.bedrooms" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Bathrooms" id="bathrooms" ref="bathrooms" v-model="formData.bathrooms" :value="formData.bathrooms" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Total Rooms" id="totalRooms" ref="totalRooms" v-model="formData.totalRooms" :value="formData.totalRooms" :disable-validation="true" />
            </div>
            <div>
              <FloatInput
                label="Living Area (sqft)"
                id="livingAreaSquareFootage"
                ref="livingAreaSquareFootage"
                v-model="formData.livingAreaSquareFootage"
                :value="formData.livingAreaSquareFootage"
                :disable-validation="true"
              />
              <span v-if="!formData.livingAreaSquareFootage && validationFormErrors.livingAreaSquareFootage" class="text-sm text-red">
                {{ validationFormErrors.livingAreaSquareFootage }}
              </span>
            </div>
            <div>
              <InputField
                label="Parking Facility"
                id="parkingFacility"
                ref="parkingFacility"
                v-model="formData.parkingFacility"
                :value="formData.parkingFacility"
                :disable-validation="true"
              />
            </div>
            <div>
              <InputField
                label="Foundation Type"
                id="foundationType"
                ref="foundationType"
                v-model="formData.foundationType"
                :value="formData.foundationType"
                :disable-validation="true"
              />
            </div>
            <div>
              <InputField
                label="Building Structure"
                id="buildingStructure"
                ref="buildingStructure"
                v-model="formData.buildingStructure"
                :value="formData.buildingStructure"
                :disable-validation="true"
              />
            </div>
            <div>
              <RadioInput
                label="Air Conditioning"
                id="airConditioning"
                ref="airConditioning"
                v-model="formData.airConditioning"
                :value="formData.airConditioning"
                :options="radioInputOptions"
              />
            </div>
          </div>
        </div>
        <!-- FHA Appraisal Logging -->
        <div>
          <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">FHA Appraisal Logging</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <InputField
                label="Flood Zone Code"
                id="floodZoneCode"
                ref="floodZoneCode"
                v-model="formData.floodZoneCode"
                :value="formData.floodZoneCode"
                :disable-validation="true"
              />
            </div>
            <div>
              <InputField
                label="Neighborhood Type"
                id="neighborhoodType"
                ref="neighborhoodType"
                v-model="formData.neighborhoodType"
                :value="formData.neighborhoodType"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Average Dwelling Price"
                id="averageDwellingPrice"
                ref="averageDwellingPrice"
                v-model="formData.averageDwellingPrice"
                :value="formData.averageDwellingPrice"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Percent Single Family"
                id="percentSingleFamily"
                ref="percentSingleFamily"
                v-model="formData.percentSingleFamily"
                :value="formData.percentSingleFamily"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Percent 2-4 Unit"
                id="percent2To4Unit"
                ref="percent2To4Unit"
                v-model="formData.percent2To4Unit"
                :value="formData.percent2To4Unit"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Percent Multifamily"
                id="percentMultifamily"
                ref="percentMultifamily"
                v-model="formData.percentMultiFamily"
                :value="formData.percentMultiFamily"
                :disable-validation="true"
              />
            </div>
            <div>
              <FloatInput
                label="Percent Commercial"
                id="percentCommercial"
                ref="percentCommercial"
                v-model="formData.percentCommercial"
                :value="formData.percentCommercial"
                :disable-validation="true"
              />
            </div>
            <div></div>
            <div>
              <RadioInput
                label="Presidential declared area?"
                id="presidentialDeclaredArea"
                ref="presidentialDeclaredArea"
                v-model="formData.presidentialDeclaredArea"
                :value="formData.presidentialDeclaredArea"
                :options="radioInputOptions"
              />
            </div>
            <div>
              <RadioInput label="Damaged?" id="damaged" :options="radioInputOptions" v-model="formData.damaged" :value="formData.damaged" />
            </div>
            <div>
              <RadioInput
                label="Danger Threshold Meet?"
                id="dangerThresholdMet"
                ref="dangerThresholdMet"
                :options="radioInputOptions"
                v-model="formData.dangerThresholdMet"
                :value="formData.dangerThresholdMet"
              />
            </div>
            <div>
              <RadioInput
                label="Declining Value Market?"
                id="decliningValueMarket"
                ref="decliningValueMarket"
                :options="radioInputOptions"
                v-model="formData.decliningMarketValue"
                :value="formData.decliningMarketValue"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end gap-4 mt-5 pt-5 border-t">
          <Button variant="primary" style="width: fit-content" @click="handleSaveChanges"> Save Changes </Button>
          <Button variant="secondary" style="width: fit-content" @click="handleDeleteAppraisal"> Delete Appraisal </Button>
        </div>
      </div>
    </div>
  </Card>
  <!-- Add Apprisal Dialog -->
  <AddAppraisalDialog :is-open="isOpenAddDialog" :on-close="handleCloseAddModal" :on-added="addedCallback" :propertyId="propertyId" />
  <!-- Add Appraisal Select Confirmation Modal -->
  <Confirmation
    :isOpen="isConfirmOpen"
    message="Are you sure you want to select this appraisal, whose appraised value is higher than that of the currently selected appraisal, for calculation?"
    @confirm="handleConfirmSelectAppraisalConfirmation"
    @close="handleCloseSelectAppraisalConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import FloatInput from '@/components/FormInput/FloatInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import { RADIO_OPTIONS } from '@/constants';
  import AddAppraisalDialog from './components/AddAppraisalDialog/index.vue';
  import { initialFormData } from './const';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Appraisal',
    components: {
      Button,
      Card,
      Confirmation,
      InputField,
      MoneyInput,
      FloatInput,
      IntegerInput,
      RadioInput,
      SelectInput,
      TextareaInput,
      PhoneNumberInputField,
      AddAppraisalDialog,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        items: [],
        isDraft: false,
        isOpenAddDialog: false,
        isConfirmOpen: false,
        formData: { ...initialFormData },
        radioInputOptions: RADIO_OPTIONS,
        appraisalOptions: [],
        selectedAppraisal: '',
        validationFormErrors: {
          livingAreaSquareFootage: '',
        },
      };
    },
    methods: {
      // Fetch all Appraisals for the Propety from the API
      ...mapActions(['setLoading']),
      async fetchAppraisals() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/appraisals/by-property/${this.propertyId}/`);
          this.items = [...response.data];
          this.updateAppraisalOptions();
        } catch (error) {
          if (error.response.status === 404) {
            this.items = [];
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Update appraisal options for the select input
      updateAppraisalOptions() {
        this.appraisalOptions = this.items.map((el, index) => ({
          label: `Appraisal ${index + 1}`,
          value: el.id.toString(),
        }));
      },
      // Update the selected appraisal based on mode
      updateSelectedAppraisal(mode) {
        const item = this.items.length > 0 ? (mode === 0 ? this.items[0] : this.items[this.items.length - 1]) : null;
        this.selectedAppraisal = item ? item.id.toString() : '';
      },
      updateFormDataFromItems() {
        const selectedItem = this.items.find((item) => item.id == this.selectedAppraisal);
        if (selectedItem) {
          this.formData = { ...selectedItem };
        } else {
          this.formData = { ...initialFormData };
        }
      },
      handleAddAppraisal() {
        this.isOpenAddDialog = true;
      },
      handleCloseAddModal() {
        this.isOpenAddDialog = false;
      },
      async addedCallback() {
        this.isOpenAddDialog = false;
        await this.fetchAppraisals();
        this.updateSelectedAppraisal();
      },
      checkIfDraft() {
        const selectedItem = this.items?.find((item) => item.id == this.selectedAppraisal);
        if (isEqual(this.formData, selectedItem) || !selectedItem) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      // Validate form data: Living Area Square Footage is required.
      validateForm() {
        this.validationFormErrors = {
          livingAreaSquareFootage: !this.formData.livingAreaSquareFootage ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.livingAreaSquareFootage;
      },
      async handleSaveChanges() {
        if (!this.propertyId) return;
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/appraisals/${this.formData.id}/`, {
            ...this.formData,
          });
          this.$root.showSnackbar(`Appraisal information has been updated successfully!`, 'success');
          await this.fetchAppraisals();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        const selectedItem = this.items?.find((item) => item.id == this.selectedAppraisal);
        this.formData = { ...(selectedItem || initialFormData) };
      },
      async handleDeleteAppraisal() {
        this.setLoading(true);
        try {
          if (this.selectedAppraisal != '') {
            await apiService.delete(`/loan/appraisals/${this.selectedAppraisal}/`);
            await this.fetchAppraisals();
            this.updateSelectedAppraisal(0);
            this.$root.showSnackbar('Appraisal deleted successfully', 'success');
          } else {
            throw new Error('No Appraisal selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete Appraisal: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleConfirmSelectAppraisalConfirmation() {
        await this.updateSelectedAppraisalForCalc(true);
      },
      handleCloseSelectAppraisalConfirmation(result) {
        if (result != 'confirmed') this.formData.useAppraisalForCalculations = false;
        this.isConfirmOpen = false;
      },
      async handleChangeAppraisalUsageForCalc(e) {
        const isSelected = e.target._value;
        if (!isSelected) this.updateSelectedAppraisalForCalc(isSelected);
        else {
          // find selected item
          const selectedItem = this.items.find((item) => item.useAppraisalForCalculations === true);
          const currentItem = this.items.find((item) => item.id === this.formData.id);
          if (selectedItem && selectedItem.appraisedValue < currentItem.appraisedValue) {
            this.isConfirmOpen = true;
          } else {
            this.updateSelectedAppraisalForCalc(isSelected);
          }
        }
      },
      async updateSelectedAppraisalForCalc(flag) {
        const action = flag ? 'select-for-calculation' : 'deselect-for-calculation';
        const successMessage = flag ? 'Appraisal has been selected for calculation!' : 'Appraisal has been deselected for calculation!';
        this.setLoading(true);
        try {
          await this.updateSelectAppraisalAPI(action);
          this.$root.showSnackbar(successMessage, 'success');
          await this.fetchAppraisals();
        } catch (error) {
          this.formData.useAppraisalForCalculations = !this.formData.useAppraisalForCalculations;
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async updateSelectAppraisalAPI(action) {
        return apiService.get(`/loan/appraisals/${this.formData.id}/${action}/`);
      },
    },
    watch: {
      // Watch for changes in selectedAppraisal to update formData
      selectedAppraisal() {
        this.updateFormDataFromItems();
        this.checkIfDraft();
      },
      propertyId: {
        async handler(newValue) {
          await this.fetchAppraisals();
          if (newValue) {
            this.updateSelectedAppraisal(0);
          }
        },
        deep: true,
        immediate: true,
      },
      // Watch for changes in formData
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      items: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      existAppraisal() {
        return this.items.length > 0;
      },
      noAppraisalForCalculation() {
        // Check if there's no item with useAppraisalForCalculations set to true
        return this.items.length > 0 && !this.items.some((item) => item.useAppraisalForCalculations === true);
      },
    },
  };
</script>
