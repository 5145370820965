<template>
  <section class="border border-gray rounded-lg bg-white h-full">
    <div class="flex justify-between py-3 pl-2.5 pr-4">
      <h3 class="text-lg font-semibold text-dark">Quick Calculations</h3>
      <font-awesome-icon icon="xmark" class="cursor-pointer" @click="$emit('close')" />
    </div>
    <ul>
      <li>
        <p>Income</p>
        <span>$1,200.00</span>
      </li>
      <li>
        <p>Expenses</p>
        <span>$575.00</span>
      </li>
      <li>
        <p>Residual</p>
        <span>$725.00</span>
      </li>
      <li>
        <p>Family Size</p>
        <span>1</span>
      </li>
      <li>
        <p>Residual Income Required</p>
        <span>$589.00</span>
      </li>
      <li>
        <p>Residual Income Fulfilled</p>
        <span>120%</span>
      </li>
      <li>
        <p>Figures Verified By UW?</p>
        <span>Yes</span>
      </li>
    </ul>
    <h3 class="text-lg font-semibold text-dark p-2.5">Loan Proceeds</h3>
    <ul>
      <li>
        <p>Home Value</p>
        <span>$400,000.00</span>
      </li>
      <li>
        <p>Rate</p>
        <span>5.9%</span>
      </li>
      <li>
        <p>Margin</p>
        <span>1.875%</span>
      </li>
      <li>
        <p>Principal Limit</p>
        <span>$279,000.00</span>
      </li>
      <li>
        <p>Origination Fee</p>
        <span>$6,000.00</span>
      </li>
      <li>
        <p>IMIP</p>
        <span>$8,000.00</span>
      </li>
      <li>
        <p>Other Closing Costs</p>
        <span>$8,000.00</span>
      </li>
      <li>
        <p>Payoffs</p>
        <span>$5,000.00</span>
      </li>
      <li>
        <p>LESA</p>
        <span>None</span>
      </li>
      <li>
        <p>Cash to Borrower</p>
        <span>$50,000.00</span>
      </li>
      <li>
        <p>Line of Credit</p>
        <span>$75,000.00</span>
      </li>
    </ul>
    <h3 class="text-lg font-semibold text-white bg-primary text-center py-3">Go to Full Comparison</h3>
    <div class="flex items-center py-3 pl-2.5 pr-4 cursor-pointer" @click="openDialog">
      <h3 class="text-lg font-semibold text-dark mr-2">Loan Parties</h3>
      <font-awesome-icon icon="arrow-right" class="cursor-pointer" />
    </div>
    <ul>
      <li>
        <p>LO:</p>
      </li>
      <li>
        <p>Credit Analyst:</p>
      </li>
      <li>
        <p>Processor:</p>
      </li>
      <li>
        <p>Pricing Analyst:</p>
      </li>
    </ul>
  </section>
  <Dialog :title="'Edit Loan Parties'" :isOpen="isDialogOpen" @close="closeDialog" @confirm="handleConfirm">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
      <div>
        <InputField label="Loan Officer" id="loanOfficer" ref="loanOfficer" :value="formData.loanOfficer" v-model.trim="formData.loanOfficer" :validation="{ required: true }" />
      </div>

      <div>
        <InputField label="Processor" id="processor" ref="processor" :value="formData.processor" v-model.trim="formData.processor" :validation="{ required: true }" />
      </div>
      <div>
        <InputField
          label="Pricing Analyst"
          id="pricingAnalyst"
          ref="pricingAnalyst"
          :value="formData.pricingAnalyst"
          v-model.trim="formData.pricingAnalyst"
          :validation="{ required: true }"
        />
      </div>
      <div>
        <InputField label="Underwriter" id="underwriter" ref="underwriter" :value="formData.underwriter" v-model.trim="formData.underwriter" :validation="{ required: true }" />
      </div>
      <div>
        <InputField label="Closer" id="closer" ref="closer" :value="formData.closer" v-model.trim="formData.closer" :validation="{ required: true }" />
      </div>
      <div>
        <InputField label="Post Closer" id="postCloser" ref="postCloser" :value="formData.postCloser" v-model.trim="formData.postCloser" :validation="{ required: true }" />
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <Button variant="primary" @click="handleConfirm">Close</Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from './../../../../components/Dialog/index.vue';
  import InputField from './../../../../components/FormInput/InputField/index.vue';
  import Button from './../../../../components/Button/index.vue';
  export default {
    name: 'QuickCalculations',
    components: { Dialog, InputField, Button },
    data() {
      return {
        isDialogOpen: false,
        formData: {
          loanOfficer: '',
          creditAnalyst: '',
          processor: '',
          pricingAnalyst: '',
          underwriter: '',
          closer: '',
          postCloser: '',
        },
      };
    },
    methods: {
      openDialog() {
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.isDialogOpen = false;
      },
      handleConfirm() {
        this.isDialogOpen = false;
      },
    },
  };
</script>

<style scoped>
  ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }

  ul li p,
  ul li span {
    font-size: 14px;
  }

  ul li:nth-child(odd) {
    background: rgba(229, 229, 229, 0.4);
  }
</style>
