<template>
  <Card title="Flood Zone Details">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField
          label="Flood Certificate Number"
          id="floodCertificateNumber"
          ref="floodCertificateNumber"
          :value="formData.floodCertificateNumber"
          v-model.trim="formData.floodCertificateNumber"
          :disable-validation="true"
        />
      </div>

      <div>
        <DateInput
          label="Flood Determination Date"
          id="floodDeterminationDate"
          ref="floodDeterminationDate"
          v-model="formData.floodDeterminationDate"
          :value="formatDate(formData.floodDeterminationDate)"
          :disable-validation="true"
        />
      </div>
      <div>
        <RadioInput
          label="Flood Insurance Required?"
          id="floodInsuranceRequired"
          :value="formData.floodInsuranceRequired"
          :options="radioInputOptions"
          v-model="formData.floodInsuranceRequired"
        />
      </div>
      <div>
        <SelectInput label="Flood Zone" :options="floodZoneOptions" v-model="formData.floodZone" :value="formData.floodZone" :disable-validation="true" />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FloodZone',
    components: {
      Card,
      InputField,
      SelectInput,
      RadioInput,
      DateInput,
      Button,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        localData: {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        },
        formData: {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        },
        floodZoneOptions: [
          { label: 'Sample Legal Vesting', value: 'Sample Legal Vesting' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchFloodZone() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/flood_zones/by-property/${this.propertyId}/`);
          this.localData = response.data;
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/flood_zones/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/flood_zones/`, {
              property: this.propertyId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Fllod Zone information has been updated successfully!`, 'success');
          await this.fetchFloodZone();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      resetLocalData() {
        this.localData = {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        };
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
    },
    watch: {
      propertyId: {
        handler() {
          this.fetchFloodZone();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
