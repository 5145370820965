<template>
  <Card class="welcome-card bg-cover bg-no-repeat bg-left-bottom relative">
    <h2 class="text-white font-bold text-lg leading-6">
      Welcome ,<br />
      Laura Collins !
    </h2>
    <p class="text-white text-sm mt-2 w-[60%]">You have earned 54% more than last month which is great thing.</p>
  </Card>
</template>

<script>
  import Card from './../../../../components/Card/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'WelcomeCard',
    components: { Card },
  };
</script>
<style scoped>
  .welcome-card {
    background-image: url('./../img/wc-bg.png');
    z-index: 1;
    border: none;
  }
  .welcome-card::before {
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(238, 157, 74, 0.9) 13.5%, rgba(231, 135, 36, 0.9) 100%);
  }
  .welcome-card::after {
    background-image: url('./../img/meeting.png');
    content: '';
    width: 178px;
    height: 125px;
    position: absolute;
    border-radius: 8px;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
</style>
