import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
// Add solid icons to the library
library.add(fas);

// Create the Vue app
const app = createApp(App);

app.use(router);
app.use(store);

// Register FontAwesome component globally
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
