<template>
  <div class="flex">
    <button v-if="showBack" @click="goBack" class="mr-3">
      <font-awesome-icon icon="arrow-left" />
    </button>
    <h1 class="text-lg font-bold">{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',
    props: {
      title: {
        type: String,
        required: true,
      },
      showBack: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      goBack() {
        this.$router.back();
      },
    },
  };
</script>
