<template>
  <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-[600px]">
      <div class="relative px-4 py-4 border-b border-gray-200">
        <h3 class="text-lg text-dark font-semibold">{{ title }}</h3>
        <button @click="close" class="w-8 h-8 rounded bg-[#E5E7EB] flex justify-center items-center absolute right-4 top-4">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.88139 5.0014L9.80943 1.07321C9.86905 1.01563 9.91661 0.946747 9.94932 0.87059C9.98204 0.794433 9.99926 0.712524 9.99998 0.629641C10.0007 0.546758 9.9849 0.464561 9.95352 0.387847C9.92213 0.311133 9.87578 0.241438 9.81717 0.182829C9.75856 0.124219 9.68887 0.0778693 9.61215 0.0464831C9.53544 0.015097 9.45324 -0.000696663 9.37036 2.35686e-05C9.28748 0.0007438 9.20557 0.0179636 9.12941 0.0506782C9.05325 0.0833928 8.98437 0.130947 8.92679 0.190566L4.9986 4.11861L1.07056 0.190566C0.953064 0.0756557 0.794997 0.0117148 0.630657 0.0126198C0.466317 0.0135249 0.308964 0.0792028 0.192746 0.1954C0.0765278 0.311598 0.0108224 0.468939 0.00988831 0.633279C0.00895421 0.797619 0.072867 0.955697 0.187757 1.07321L4.11595 5.0014L0.187757 8.9296C0.128733 8.98733 0.0817466 9.05619 0.0495195 9.1322C0.0172923 9.20821 0.000464139 9.28986 9.46651e-06 9.37242C-0.000445206 9.45498 0.0154827 9.53681 0.0468707 9.61318C0.0782587 9.68954 0.124483 9.75891 0.182868 9.81729C0.241252 9.87566 0.310637 9.92188 0.387005 9.95325C0.463372 9.98462 0.545205 10.0005 0.627765 10.0001C0.710325 9.9996 0.791971 9.98276 0.867977 9.95052C0.943983 9.91827 1.01284 9.87128 1.07056 9.81224L4.9986 5.8842L8.92679 9.81224C9.04867 9.93412 9.20847 9.99514 9.36811 9.99514C9.52776 9.99514 9.68771 9.93412 9.80943 9.81224C9.92646 9.69519 9.9922 9.53644 9.9922 9.37092C9.9922 9.2054 9.92646 9.04666 9.80943 8.9296L5.88139 5.0014Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
      <div class="p-15 max-h-[80vh] overflow-y-auto">
        <slot></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Dialog',
    props: {
      title: {
        type: String,
        required: true,
      },
      isOpen: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      confirm() {
        this.$emit('confirm');
      },
    },
  };
</script>

<style scoped>
  /* Add any additional styling if necessary */
</style>
