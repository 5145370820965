<template>
  <Card>
    <!-- Active Borrower Selection -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4" v-if="existBorrower">
      <div>
        <div v-if="this.items.length > 1">
          <SelectInput label="Active Borrower" id="activeBorrower" :options="activeBorrowerOptions" v-model="activeBorrower" :value="activeBorrower" :disable-validation="true" />
        </div>
      </div>
      <div></div>
      <div class="flex justify-end">
        <Button variant="primary" @click="openAddDialog" style="width: fit-content"> Add {{ activeBorrowerOptions.length > 1 ? 'Borrower' : 'Co-Borrower' }} </Button>
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300" v-if="existBorrower"></div>
    <!-- Borrower Details Form -->
    <div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <InputField label="First Name" id="firstName" ref="firstName" v-model.trim="formData.firstName" :value="formData.firstName" :disable-validation="true" />
          <span v-if="!formData.firstName && validationFormErrors.firstName" class="text-sm text-red">
            {{ validationFormErrors.firstName }}
          </span>
        </div>
        <div>
          <InputField label="Middle Name" id="middleName" ref="middleName" v-model.trim="formData.middleName" :value="formData.middleName" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Last Name" id="lastName" ref="lastName" v-model.trim="formData.lastName" :value="formData.lastName" :disable-validation="true" />
          <span v-if="!formData.lastName && validationFormErrors.lastName" class="text-sm text-red">
            {{ validationFormErrors.lastName }}
          </span>
        </div>
        <div>
          <DateInput label="Date of Birth" id="dob" ref="dob" v-model="formData.dob" :value="formatDate(formData.dob)" :disable-validation="true" />
          <span v-if="!formData.dob && validationFormErrors.dob" class="text-sm text-red">
            {{ validationFormErrors.dob }}
          </span>
        </div>
        <div>
          <SSNInputField label="SSN" id="ssn" ref="ssn" v-model.trim="formData.ssn" :value="formData.ssn" :disable-validation="true" />
        </div>
        <div>
          <SelectInput
            label="Immigration Status"
            id="immigrationStatus"
            :options="immigrationStatusOptions"
            v-model="formData.immigrationStatus"
            :value="formData.immigrationStatus"
            :disable-validation="true"
          />
        </div>
        <div>
          <SelectInput
            label="Marital Status"
            id="maritalStatus"
            :options="maritalStatusOptions"
            v-model="formData.maritalStatus"
            :value="formData.maritalStatus"
            :disable-validation="true"
          />
        </div>
        <div>
          <PhoneNumberInputField
            label="Home Phone"
            id="homePhone"
            ref="homePhone"
            v-model.trim="formData.homePhone"
            :value="formData.homePhone"
            @input="() => validateNumber(formData.homePhone)"
            :disable-validation="true"
          />
          <span v-if="validationFormErrors.homePhone" class="text-sm text-red">
            {{ validationFormErrors.homePhone }}
          </span>
        </div>
        <div>
          <PhoneNumberInputField
            label="Mobile Phone"
            id="mobilePhone"
            ref="mobilePhone"
            v-model.trim="formData.mobilePhone"
            :value="formData.mobilePhone"
            @input="() => validateNumber(formData.mobilePhone)"
            :disable-validation="true"
          />
          <span v-if="validationFormErrors.mobilePhone" class="text-sm text-red">
            {{ validationFormErrors.mobilePhone }}
          </span>
        </div>

        <div v-if="activeBorrowerOptions?.length > 0" class="form-group">
          <SelectInput
            label="Borrower Type"
            id="borrowerType"
            :options="borrowerTypeOptions"
            v-model="formData.borrowerType"
            :value="formData.borrowerType"
            :disable-validation="true"
          />
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE">
          <SelectInput label="NBS Type" id="nbsType" :options="nbsTypeOptions" v-model="formData.nbsType" :value="formData.nbsType" :disable-validation="true" />
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <InputField label="NBS Address" id="nbsAddress" ref="nbsAddress" v-model.trim="formData.nbsAddress" :value="formData.nbsAddress" :disable-validation="true" />
        </div>
      </div>
      <div class="flex justify-end gap-4 mt-5">
        <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft && existBorrower">
          {{ existBorrower ? 'Save Changes' : 'Add Borrower' }}
        </Button>
        <Button variant="secondary" @click="handleOpenDeleteConfirmation" style="width: fit-content" :disabled="removeDisabled" v-if="existBorrower"> Remove Borrower </Button>
      </div>
    </div>
    <!-- Tabs and Content -->
    <div v-if="items?.length > 0">
      <TabCard class="my-15">
        <ul class="tabs flex items-center">
          <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
            {{ tabOption.title }}
          </li>
        </ul>
      </TabCard>
      <div class="content-container">
        <component :is="activeTabComponent" v-model:borrowerId="formData.id" />
      </div>
    </div>
  </Card>
  <!-- Add Co-Borrower Dialog -->
  <Dialog :title="'Add New Co-Borrower'" :isOpen="isAddDialogOpen" @confirm="handleConfirmAddDialog" @close="handleCloseAddDialog">
    <form @submit.prevent="handleConfirmAddDialog">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div class="form-group">
          <InputField label="First Name" id="firstName-add" ref="firstName-add" v-model.trim="addFormData.firstName" :value="addFormData.firstName" :disable-validation="true" />
          <span v-if="!addFormData.firstName && validationAddFormErrors.firstName" class="text-sm text-red">{{ validationAddFormErrors.firstName }}</span>
        </div>
        <div class="form-group">
          <InputField
            label="Middle Name"
            id="middleName-add"
            ref="middleName-add"
            v-model.trim="addFormData.middleName"
            :value="addFormData.middleName"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <InputField label="Last Name" id="lastName-add" ref="lastName-add" v-model.trim="addFormData.lastName" :value="addFormData.lastName" :disable-validation="true" />
          <span v-if="!addFormData.lastName && validationAddFormErrors.lastName" class="text-sm text-red">{{ validationAddFormErrors.lastName }}</span>
        </div>
        <div class="form-group">
          <DateInput label="Date of Birth" id="dob-add" ref="dob-add" v-model="addFormData.dob" :value="addFormData.dob" :disable-validation="true" />
          <span v-if="!addFormData.dob && validationAddFormErrors.dob" class="text-sm text-red">{{ validationAddFormErrors.dob }}</span>
        </div>
        <div class="form-group">
          <InputField label="SSN" id="ssn-add" ref="ssn-add" v-model.trim="addFormData.ssn" :value="addFormData.ssn" :disable-validation="true" />
        </div>
        <div class="form-group">
          <SelectInput
            label="Immigration Status"
            id="immigrationStatus-add"
            :options="immigrationStatusOptions"
            v-model="addFormData.immigrationStatus"
            :value="addFormData.immigrationStatus"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <SelectInput
            label="Marital Status"
            id="maritalStatus-add"
            :options="maritalStatusOptions"
            v-model="addFormData.maritalStatus"
            :value="addFormData.maritalStatus"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <PhoneNumberInputField
            label="Home Phone"
            id="homePhone-add"
            ref="homePhone-add"
            v-model.trim="addFormData.homePhone"
            :value="addFormData.homePhone"
            :disable-validation="true"
            @input="() => validateNumber(addFormData.homePhone)"
          />
          <span v-if="validationAddFormErrors.homePhone" class="text-sm text-red">{{ validationAddFormErrors.homePhone }}</span>
        </div>
        <div class="form-group">
          <PhoneNumberInputField
            label="Mobile Phone"
            id="mobilePhone-add"
            ref="mobilePhone-add"
            v-model.trim="addFormData.mobilePhone"
            :value="addFormData.mobilePhone"
            :disable-validation="true"
            @input="() => validateNumber(addFormData.mobilePhone)"
          />
          <span v-if="validationAddFormErrors.mobilePhone" class="text-sm text-red">{{ validationAddFormErrors.mobilePhone }}</span>
        </div>
        <div class="form-group">
          <SelectInput
            label="Borrower Type"
            id="borrowerType-add"
            :options="borrowerTypeOptions"
            v-model="addFormData.borrowerType"
            :value="addFormData.borrowerType"
            :disable-validation="true"
          />
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE">
          <SelectInput label="NBS Type" id="nbsType-add" :options="nbsTypeOptions" v-model="addFormData.nbsType" :value="addFormData.nbsType" :disable-validation="true" />
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Address"
            id="nbsAddress-add"
            ref="nbsAddress-add"
            v-model.trim="addFormData.nbsAddress"
            :value="addFormData.nbsAddress"
            :disable-validation="true"
          />
        </div>
      </div>
      <!-- Divider -->
      <div class="w-full h-px my-3 bg-gray-300"></div>
      <!-- Dialog Buttons -->
      <div class="flex justify-end gap-4">
        <Button variant="primary" type="submit" style="width: fit-content"> Add Borrower </Button>
        <Button variant="danger" @click="handleCloseAddDialog" style="width: fit-content"> Close </Button>
      </div>
    </form>
  </Dialog>
  <!-- Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this borrower?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import SSNInputField from '@/components/FormInput/SSNInputField/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import { BORROWER_TYPES } from '@/constants';
  import DemographicInfo from './components/DemographicInfo/index.vue';
  import Income from './components/Income/index.vue';
  import Credit from './components/Credit/index.vue';
  import Assets from './components/Assets/index.vue';
  import Declaration from './components/Declaration/index.vue';
  import AlternateContact from './components/AlternateContact/index.vue';

  const initialData = {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    ssn: '',
    immigrationStatus: '',
    maritalStatus: '',
    homePhone: '',
    mobilePhone: '',
    borrowerType: BORROWER_TYPES.CO_BOROWER,
    nbsType: 'Eligible',
    nbsAddress: '',
  };

  export default {
    name: 'activeBorrower',
    components: {
      InputField,
      PhoneNumberInputField,
      SSNInputField,
      DateInput,
      SelectInput,
      Card,
      Confirmation,
      TabCard,
      Dialog,
      DemographicInfo,
      Income,
      Button,
      Credit,
      Assets,
      Declaration,
      AlternateContact,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    setup() {
      return {
        BORROWER_TYPES,
      };
    },
    data() {
      return {
        items: [],
        isDraft: false,
        isAddDialogOpen: false,
        isDeleteConfirmationOpen: false,
        tabOptions: [
          { title: 'Demographic Info', component: DemographicInfo },
          { title: 'Income', component: Income },
          { title: 'Credit Report', component: Credit },
          { title: 'Assets', component: Assets },
          { title: 'Declaration', component: Declaration },
          { title: 'Alternate Contact', component: AlternateContact },
        ],
        activeTab: { title: 'Demographic Info', isActive: true },
        formData: { ...initialData },
        addFormData: { ...initialData },
        validationAddFormErrors: {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
        },
        validationFormErrors: {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
        },
        maritalStatusOptions: [
          { label: 'Married', value: 'Married' },
          { label: 'Unmarried', value: 'Unmarried' },
          { label: 'Separated', value: 'Separated' },
        ],
        immigrationStatusOptions: [
          { label: 'US Citizen', value: 'US Citizen' },
          { label: 'Lawful Permanent Alien', value: 'Lawful Permanent Alien' },
          { label: 'Other', value: 'Other' },
        ],
        borrowerTypeOptions: [
          { label: 'Co-Borrower', value: 'Co-Borrower' },
          { label: 'Non-Borrowing Spouse', value: 'Non-Borrowing Spouse' },
        ],
        nbsTypeOptions: [
          { label: 'Eligible', value: 'Eligible' },
          { label: 'Ineligible', value: 'Ineligible' },
        ],
        activeBorrowerOptions: [],
        activeBorrower: '',
      };
    },
    async created() {
      await this.fetchBorrowers();
      this.updateActiveBorrower(0);
    },
    methods: {
      ...mapActions(['setLoading']),
      // Fetch all borrowers from the API
      async fetchBorrowers() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/borrowers/', {
            loan: this.loanId,
          });
          this.items = response.data;
          this.updateActiveBorrowerOptions();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Update Active Borrower options for the select input
      updateActiveBorrowerOptions() {
        this.activeBorrowerOptions = this.items.map((el) => ({
          label: `${el.firstName} ${el.lastName}`,
          value: el.id.toString(),
        }));
      },
      // Update the Active Borrower based on mode
      updateActiveBorrower(mode) {
        const item = this.items.length > 0 ? (mode === 0 ? this.items[0] : this.items[this.items.length - 1]) : null;
        this.activeBorrower = item ? item.id.toString() : '';
      },
      // Change active tab
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
      // Open add co-borrower dialog
      openAddDialog() {
        this.resetAddForm();
        this.isAddDialogOpen = true;
      },
      // Validate add form data
      validateAddForm() {
        this.validationAddFormErrors = {
          firstName: !this.addFormData.firstName ? 'First name is required.' : '',
          lastName: !this.addFormData.lastName ? 'Last name is required.' : '',
          dob: !this.addFormData.dob ? 'Date of birth is required.' : '',
          homePhone: this.validateNumber(this.addFormData.homePhone) ? '' : 'Invalid phone number format. Expected format: (XXX) XXX-XXXX.',
          mobilePhone: this.validateNumber(this.addFormData.mobilePhone) ? '' : 'Invalid mobile phone number format. Expected format: (XXX) XXX-XXXX.',
        };
        return (
          !this.validationAddFormErrors.firstName &&
          !this.validationAddFormErrors.lastName &&
          !this.validationAddFormErrors.dob &&
          !this.validationAddFormErrors.homePhone &&
          !this.validationAddFormErrors.mobilePhone
        );
      },
      // Validate form data
      validateForm() {
        this.validationFormErrors = {
          firstName: !this.formData.firstName ? 'First name is required.' : '',
          lastName: !this.formData.lastName ? 'Last name is required.' : '',
          dob: !this.formData.dob ? 'Date of birth is required.' : '',
          homePhone: this.validateNumber(this.formData.homePhone) ? '' : 'Invalid phone number format. Expected format: (XXX) XXX-XXXX.',
          mobilePhone: this.validateNumber(this.formData.mobilePhone) ? '' : 'Invalid mobile phone number format. Expected format: (XXX) XXX-XXXX.',
        };
        return (
          !this.validationFormErrors.firstName &&
          !this.validationFormErrors.lastName &&
          !this.validationFormErrors.dob &&
          !this.validationFormErrors.homePhone &&
          !this.validationFormErrors.mobilePhone
        );
      },
      // Reset add form data
      resetAddForm() {
        this.addFormData = { ...initialData };
        this.validationAddFormErrors = {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
        };
      },
      resetFormErrors() {
        this.validationFormErrors = {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
        };
      },
      // Validate Number in the forms
      validateNumber(number) {
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        return number == '' || phonePattern.test(number);
      },
      // Handle add dialog confirmation
      async handleConfirmAddDialog() {
        if (!this.validateAddForm()) {
          return;
        }
        // Add the new borrower
        let newBorrower = {
          ...this.addFormData,
          loan: this.$route.params.id,
        };

        if (this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE) {
          newBorrower.isNBS = true;
          if (this.addFormData.nbsType == 'Ineligible') {
            newBorrower.isEligible = false;
            newBorrower.nbsAddress = this.addFormData.nbsAddress;
          } else {
            newBorrower.isEligible = true;
            newBorrower.nbsAddress = '';
          }
        } else {
          this.addFormData.isNBS = false;
          this.addFormData.nbsType = '';
          this.addFormData.nbsAddress = '';
        }

        this.setLoading(true);
        try {
          await apiService.post('/loan/borrowers/', newBorrower);
          this.$root.showSnackbar(`New borrower has been created successfully!`, 'success');
          await this.fetchBorrowers();
          this.updateActiveBorrower(1);
          this.handleCloseAddDialog();
        } catch (error) {
          this.$root.showSnackbar(`Failed to create borrower: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Close add dialog
      handleCloseAddDialog() {
        this.isAddDialogOpen = false;
      },
      // Open delete confirmation dialog
      handleOpenDeleteConfirmation() {
        this.isDeleteConfirmationOpen = true;
      },
      // Handle delete confirmation
      async handleConfirmDeleteConfirmation() {
        try {
          this.setLoading(true);
          if (this.activeBorrower != '') {
            await apiService.delete(`/loan/borrowers/${this.activeBorrower}/`);
            await this.fetchBorrowers();
            this.updateActiveBorrower(0);
            this.$root.showSnackbar('Borrower deleted successfully', 'success');
          } else {
            throw new Error('No borrower selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete borrower: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Close delete confirmation dialog
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE) {
            this.formData.isNBS = true;
            if (this.formData.nbsType == 'Ineligible') {
              this.formData.isEligible = false;
            } else {
              this.formData.isEligible = true;
              this.formData.nbsAddress = '';
            }
          } else {
            this.formData.isNBS = false;
            this.formData.nbsType = '';
            this.formData.nbsAddress = '';
          }

          if (this.formData.id) {
            await apiService.patch(`/loan/borrowers/${this.formData.id}/`, this.formData);
          } else {
            await apiService.post(`/loan/borrowers/`, { ...this.formData, loan: this.$route.params.id });
          }
          this.$emit('change');
          await this.fetchBorrowers();
          this.updateFormDataFromItems();
          if (!this.formData.id) this.updateActiveBorrower(0);
          this.$root.showSnackbar('Borrower information has been updated successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.resetFormErrors();
          this.setLoading(false);
        }
      },
      checkIfDraft() {
        const selectedItem = this.items?.find((item) => item.id == this.activeBorrower);
        if (isEqual(this.formData, selectedItem) || !selectedItem) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      updateFormDataFromItems() {
        const selectedItem = this.items.find((item) => item.id == this.activeBorrower);
        if (selectedItem) {
          let formData = {
            ...selectedItem,
            borrowerType: selectedItem.isNBS ? BORROWER_TYPES.NON_BORROWING_SPOUSE : BORROWER_TYPES.CO_BOROWER,
          };
          if (selectedItem.isNBS) {
            formData.nbsType = selectedItem.isEligible ? 'Eligible' : 'Ineligible';
            if (!selectedItem.isEligible) formData.nbsAddress = selectedItem.nbsAddress;
          }
          this.formData = { ...formData };
        } else {
          this.formData = { ...initialData };
        }
      },
    },
    watch: {
      loanId: {
        async handler(newValue, oldValue) {
          if (newValue && oldValue) {
            await this.fetchBorrowers();
            this.updateActiveBorrower(0);
          }
        },
        immediate: true,
      },
      // Watch for changes in activeBorrower to update formData
      activeBorrower() {
        console.log(this.addFormData);
        this.updateFormDataFromItems();
      },
      // Watch for changes in formData
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      items: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      // Get the active tab component
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
      existBorrower() {
        return this.activeBorrowerOptions.length > 0;
      },
      removeDisabled() {
        // Disabled to remove borrower button when there is only one borrower or no active borrower.
        return this.activeBorrower === '' || this.items.length == 1;
      },
    },
  };
</script>
