<template>
  <Dialog :title="'Edit Fee'" :isOpen="isOpen" @close="closeDialog" @confirm="emitConfirm">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <SelectInput
          label="Tolerance"
          id="tolerance"
          ref="tolerance"
          :options="toleranceOptions"
          v-model.trim="formData.tolerance"
          :value="formData.tolerance"
          :disable-validation="true"
        />
      </div>
      <div>
        <InputField label="HUD Line #" id="hudLine" ref="hudLine" v-model.trim="formData.hudLine" :value="formData.hudLine" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Fee Name" id="name" ref="name" v-model.trim="formData.name" :value="formData.name" :disable-validation="true" />
      </div>
      <div>
        <RadioInput
          label="Is Finance Charge?"
          id="isFinanceCharge"
          ref="isFinanceCharge"
          :options="radioInputOptions"
          v-model="formData.isFinanceCharge"
          :value="formData.isFinanceCharge"
        />
      </div>
      <div>
        <InputField label="Payee" id="payee" ref="payee" v-model.trim="formData.payee" :value="formData.payee" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Amount" id="amount" ref="amount" v-model="formData.amount" :value="formData.amount" :disable-validation="true" />
      </div>
      <div>
        <RadioInput
          label="Exclude from Wire?"
          id="excludeFromWire"
          ref="excludeFromWire"
          :options="radioInputOptions"
          v-model="formData.excludeFromWire"
          :value="formData.excludeFromWire"
        />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="emitConfirm" :disabled="!isValidForm">Save Changes</Button>
      <Button variant="secondary" @click="closeDialog">Cancel</Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';

  export default {
    name: 'FeeDialog',
    components: {
      Dialog,
      InputField,
      SelectInput,
      RadioInput,
      MoneyInput,
      Button,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      editItem: {
        type: Object,
        required: true,
      },
      toleranceOptions: {
        type: Array,
        required: true,
      },
      radioInputOptions: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        formData: {
          tolerance: null,
          hudLine: null,
          name: null,
          isFinanceCharge: null,
          payee: null,
          amount: null,
          excludeFromWire: null,
        },
      };
    },
    watch: {
      editItem: {
        handler(newValue) {
          this.formData = { ...newValue };
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      isValidForm() {
        return (
          this.formData.tolerance &&
          this.formData.hudLine &&
          this.formData.name &&
          this.formData.isFinanceCharge !== null &&
          this.formData.payee &&
          this.formData.amount !== null &&
          this.formData.excludeFromWire !== null
        );
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close');
      },
      emitConfirm() {
        this.$emit('confirm', this.formData);
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
