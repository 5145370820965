<template>
  <div class="flex items-center justify-center">
    <form @submit.prevent="handleSubmit" class="relative w-full max-w-md flex min-w-[270px]">
      <input v-model="searchQuery" type="text" class="bg-white rounded-lg text-sm text-black-300 py-3 px-4" placeholder="Search" />
      <button type="submit" class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
        <font-awesome-icon icon="magnifying-glass" class="text-[#A8A8A8]" />
      </button>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'SearchInput',
    data() {
      return {
        searchQuery: '',
      };
    },
    methods: {
      handleSubmit() {
        this.$emit('submit-search', this.searchQuery);
      },
    },
  };
</script>

<style scoped>
  /* Add any additional styling here if needed */
</style>
