<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <div class="flex items-center justify-end py-2 mx-2">
        <Button variant="primary" :disabled="tableData.length > 0" @click="handleQuoteAPI">Obtain Title Fees</Button>
      </div>
      <div>
        <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction">
          <template v-slot:custom="{ row }">
            <Dropdown>
              <template v-slot:button>
                <button class="icon-button bg-gray-300">
                  <font-awesome-icon icon="fa-solid fa-ellipsis" />
                </button>
              </template>
              <button class="text-nowrap" @click="handleGetCreditReportCost(row.id)">Get Credit Report Cost</button>
            </Dropdown>
          </template>
        </DataTable>
      </div>
    </div>
    <div class="border rounded-lg">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 gap-4 border-b border-gray p-15">
        <div>
          <SelectInput
            label="Tolerance"
            id="tolerance"
            ref="tolerance"
            :options="toleranceOptions"
            :value="formData.tolerance"
            v-model.trim="formData.tolerance"
            :disable-validation="true"
          />
        </div>
        <div>
          <InputField label="HUD Line #" id="hudLine" ref="hudLine" :value="formData.hudLine" v-model.trim="formData.hudLine" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Fee Name" id="name" ref="name" :value="formData.name" v-model.trim="formData.name" :disable-validation="true" />
        </div>
        <div>
          <RadioInput
            label="Is Finance Charge?"
            id="isFinanceCharge"
            ref="isFinanceCharge"
            :options="radioInputOptions"
            :value="formData.isFinanceCharge"
            v-model="formData.isFinanceCharge"
          />
        </div>
        <div>
          <AutoCompleteField label="Payee" id="payee" ref="payee" :items="payees" :value="formData.payee" v-model.trim="formData.payee" :disable-validation="true" />
        </div>
        <div>
          <MoneyInput label="Amount" id="amount" ref="amount" v-model="formData.amount" :value="formData.amount" :disable-validation="true" />
        </div>
        <div>
          <RadioInput
            label="Exclude from Wire?"
            id="excludeFromWire"
            ref="excludeFromWire"
            :options="radioInputOptions"
            :value="formData.excludeFromWire"
            v-model="formData.excludeFromWire"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <Button variant="primary" class="mr-15" @click="handleAddFee" :disabled="!isValidForm">Add Fee</Button>
    </div>
    <!-- Edit Dialog Component -->
    <FeeDialog
      :is-open="isDialogOpen"
      :edit-item="editItem"
      :tolerance-options="toleranceOptions"
      :radio-input-options="radioInputOptions"
      @close="closeDialog"
      @confirm="handleEditFee"
    />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import DataTable from '@/components/DataTable/index.vue';
  import Card from '@/components/Card/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import AutoCompleteField from '@/components/FormInput/AutoCompleteField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import FeeDialog from './components/FeeDialog/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Fees',
    components: {
      AutoCompleteField,
      Card,
      DataTable,
      Dropdown,
      InputField,
      SelectInput,
      RadioInput,
      MoneyInput,
      Button,
      FeeDialog,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        tableHeaders: [
          { key: 'tolerance', label: 'Tolerance' },
          { key: 'hudLine', label: 'Hud Line #' },
          { key: 'name', label: 'Fee Name' },
          { key: 'isFinanceCharge', label: 'Is Finance Charge?' },
          { key: 'payee', label: 'Payee' },
          { key: 'amt', label: 'Amount' },
          { key: 'excludeFromWire', label: 'Exclude from wire?' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        payees: [],
        formData: {
          tolerance: null,
          hudLine: null,
          name: null,
          isFinanceCharge: null,
          payee: null,
          amount: null,
          excludeFromWire: null,
        },
        toleranceOptions: [
          { label: '0%', value: '0%' },
          { label: '10%', value: '10%' },
          { label: 'Unlimited', value: 'Unlimited' },
        ],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        isDialogOpen: false,
        editItem: null,
      };
    },
    async created() {
      await this.fetchPayees();
      await this.fetchFees();
    },
    computed: {
      isValidForm() {
        return (
          this.formData.tolerance &&
          this.formData.hudLine &&
          this.formData.name &&
          this.formData.isFinanceCharge !== null &&
          this.formData.payee &&
          this.formData.amount !== null &&
          this.formData.excludeFromWire !== null
        );
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      resetFormData() {
        this.formData = {
          tolerance: null,
          hudLine: null,
          name: null,
          isFinanceCharge: null,
          payee: null,
          amount: null,
          excludeFromWire: null,
        };
      },
      async fetchPayees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/payees/`);
          this.payees = response.data.map((el) => el.name);
        } catch (error) {
          this.payees = []; // reset table data
          // if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      async fetchFees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/fees/`, {
            loan: this.loanId,
          });
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            amt: `$${item.amount}`,
            actions: ['edit', 'delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      async handleAddFee() {
        this.setLoading(true);
        try {
          await apiService.post(`/loan/fees/`, {
            ...this.formData,
            loan: this.loanId,
          });
          this.$root.showSnackbar(`Fee data has been added successfully!`, 'success');
          await this.fetchFees();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleQuoteAPI() {
        this.setLoading(true);
        try {
          await apiService.post('/loan/greenlight/quote', {
            loan_id: this.loanId,
          });
          await this.fetchFees();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.error ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleGetCreditReportCost(id) {
        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/total-cost-for-borrower/', {
            user_name: 'TPLACK',
            mcl_interface: 'SouthRiverMortgage02252022',
            first_name: null,
            last_or_company_name: null,
            ref_number: null,
            ssn: '069268684',
          });
          const transactions = response?.data?.response?.transactions ?? [];
          const reportCost = transactions.reduce((total, element) => {
            const charge = element?.charge ? parseFloat(element.charge.replace(/[^0-9.-]+/g, '')) : 0;
            return total + charge;
          }, 0);

          // Update fee amount with credit report cost
          try {
            await apiService.patch(`/loan/fees/${id}/`, {
              amount: reportCost,
            });
            this.$root.showSnackbar(`Fee data has been updated with credit report cost successfully!`, 'success');
            await this.fetchFees();
          } catch (error) {
            this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Failed to get credit report cost.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleEditFee(data) {
        if (!this.editItem) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/fees/${this.editItem.id}/`, {
            ...data,
          });
          this.$root.showSnackbar(`Fee data has been updated successfully!`, 'success');
          this.closeDialog();
          await this.fetchFees();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/fees/${item.id}/`);
            this.$root.showSnackbar('Fee data has been deleted successfully', 'success');
            await this.fetchFees();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete fee data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
      openDialog(item = null) {
        this.editItem = item;
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = false;
      },
    },
    watch: {
      loanId: {
        handler(newValue, oldValue) {
          if (newValue && oldValue) {
            this.fetchFees();
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }

  .dropdown-container {
    padding: 0 !important;
  }
</style>
