<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <textarea :id="id" v-model="internalValue" @input="handleInput" :class="{ invalid: !isValid && isDirty }" rows="5" style="height: 97px"></textarea>
    <span v-if="!isValid && isDirty" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'TextareaInput',
    props: {
      label: String,
      id: String,
      value: String,
    },
    data() {
      return {
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.internalValue.trim() !== '';
      },
      handleInput(event) {
        this.internalValue = event.target.value;
        this.$emit('update:modelValue', this.internalValue);
        this.validateInput();
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }
</style>
