// src/apiService.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to refresh token using refresh token
async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');

    window.location.href = '/login';
    return null;
  }

  try {
    const response = await apiClient.post('/auth/token/', {
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    });

    const { access_token } = response.data;
    localStorage.setItem('auth_token', access_token);
    return access_token;
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');

    window.location.href = '/login';
    return null;
  }
}

// Add a request interceptor
apiClient.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return apiClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default {
  get(resource, params = {}, options = {}) {
    return apiClient.get(resource, { params, ...options });
  },
  post(resource, data, headers) {
    return apiClient.post(resource, data, headers);
  },
  put(resource, data, headers) {
    return apiClient.put(resource, data, headers);
  },
  patch(resource, data, headers) {
    return apiClient.put(resource, data, headers);
  },
  delete(resource) {
    return apiClient.delete(resource);
  },
  login(username, password) {
    return apiClient.post('/auth/token/', {
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      username: username,
      password: password,
      grant_type: 'password',
    });
  },
  refreshToken(refreshToken) {
    return apiClient.post('/auth/token/', {
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    });
  },
  revokeToken(token) {
    return apiClient.post('/auth/revoke_token/', {
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      token: token,
    });
  },
};
