<template>
  <Card>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <SelectInput
          label="Application Taken By"
          id="applicationTakenBy"
          ref="applicationTakenBy"
          :options="applicationTakenByOptions"
          v-model.trim="formData.applicationTakenBy"
          :value="formData.applicationTakenBy"
          :disable-validation="true"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <RadioInput
          label="Ethnicity collected by visual observation?"
          id="ethnicityObservation"
          :options="radioInputOptions"
          v-model="formData.isEthnicityVisual"
          :value="formData.isEthnicityVisual"
        />
      </div>
      <div>
        <RadioInput
          label="Race collected by visual observation?"
          id="raceCollectedObservation"
          :options="radioInputOptions"
          v-model="formData.isRaceVisual"
          :value="formData.isRaceVisual"
        />
      </div>
      <div>
        <RadioInput
          label="Sex collected by visual observation?"
          id="sexCollectedObservation"
          :options="radioInputOptions"
          v-model="formData.isGenderVisual"
          :value="formData.isGenderVisual"
        />
      </div>
    </div>

    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Ethnicity</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput label="Not Applicable" id="ethnicityNotApplicable" :value="formData.ethnicity == 'Not Applicable'" @change="updateField('ethnicity', 'Not Applicable')" />
        </div>
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="ethnicityDoesNotWishToProvide"
            :value="formData.ethnicity == 'Does Not Wish To Provide'"
            @change="updateField('ethnicity', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput
            label="Hispanic or Latino"
            id="ethnicityHispanicOrLatino"
            :value="formData.ethnicity == 'Hispanic or Latino'"
            @change="updateField('ethnicity', 'Hispanic or Latino')"
          />
        </div>
        <div>
          <CheckboxInput
            label="Not Hispanic or Latino"
            id="ethnicityNotHispanicOrLatino"
            :value="formData.ethnicity == 'Not Hispanic or Latino'"
            @change="updateField('ethnicity', 'Not Hispanic or Latino')"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Race</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput label="Not Applicable" id="raceNotApplicable" :value="formData.race == 'Not Applicable'" @change="updateField('race', 'Not Applicable')" />
        </div>
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="raceDoesNotWishToProvide"
            :value="formData.race == 'Does Not Wish To Provide'"
            @change="updateField('race', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput
            label="American Indian or Alaska Native"
            id="raceAmericanIndianOrAlaskaNative"
            :value="formData.race == 'American Indian or Alaska Native'"
            @change="updateField('race', 'American Indian or Alaska Native')"
          />
        </div>
        <div>
          <CheckboxInput label="Asian" id="raceAsian" :value="formData.race == 'Asian'" @change="updateField('race', 'Asian')" />
        </div>
        <div>
          <CheckboxInput
            label="Black or African American"
            id="raceBlackOrAfricanAmerican"
            :value="formData.race == 'Black or African American'"
            @change="updateField('race', 'Black or African American')"
          />
        </div>
        <div>
          <CheckboxInput
            label="Native Hawaiian or Pacific Islander"
            id="raceNativeHawaiianOrPacificIslander"
            :value="formData.race == 'Native Hawaiian or Pacific Islander'"
            @change="updateField('race', 'Native Hawaiian or Pacific Islander')"
          />
        </div>
        <div>
          <CheckboxInput label="White" id="raceWhite" :value="formData.race == 'White'" @change="updateField('race', 'White')" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Sex</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput label="Not Applicable" id="sexNotApplicable" :value="formData.sex == 'Not Applicable'" @change="updateField('sex', 'Not Applicable')" />
        </div>
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="sexDoesNotWishToProvide"
            :value="formData.sex == 'Does Not Wish To Provide'"
            @change="updateField('sex', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput label="Male" id="sexMale" :value="formData.sex == 'Male'" @change="updateField('sex', 'Male')" />
        </div>
        <div>
          <CheckboxInput label="Female" id="sexFemale" :value="formData.sex == 'Female'" @change="updateField('sex', 'Female')" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import Button from '@/components/Button/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';

  export default {
    name: 'DemographicInfo',
    components: {
      Button,
      Card,
      RadioInput,
      CheckboxInput,
      SelectInput,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        localData: {
          applicationTakenBy: null,
          isEthnicityVisual: null,
          isRaceVisual: null,
          isGenderVisual: null,
          ethnicity: null,
          race: null,
          sex: null,
        },
        formData: {
          applicationTakenBy: null,
          isEthnicityVisual: null,
          isRaceVisual: null,
          isGenderVisual: null,
          ethnicity: null,
          race: null,
          sex: null,
        },
        applicationTakenByOptions: [
          { label: 'Face-to-Face Interview', value: 'Face-to-Face Interview' },
          { label: 'Telephone Interview', value: 'Telephone Interview' },
          { label: 'Fax or Email', value: 'Fax or Email' },
          { label: 'Email or Internet', value: 'Email or Internet' },
        ],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      updateField(field, value) {
        if (this.formData[field] == value) {
          this.formData[field] = null;
        } else {
          this.formData[field] = value;
        }
      },
      async fetchDemographicInfo() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/demographic_infos/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      async handleSaveChanges() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          if (this.localData.id) await apiService.patch(`/loan/demographic_infos/${this.localData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/demographic_infos/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Demographic information has been updated successfully!`, 'success');
          await this.fetchDemographicInfo();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      resetLocalData() {
        this.localData = {
          applicationTakenBy: null,
          isEthnicityVisual: null,
          isRaceVisual: null,
          isGenderVisual: null,
          ethnicity: null,
          race: null,
          sex: null,
        };
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchDemographicInfo();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
