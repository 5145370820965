<template>
  <Card title="RevenueAnalytics">
    <div class="chart-container">
      <BarChart :chartData="data" :chartOptions="options" />
    </div>
  </Card>
</template>

<script>
  import Card from './../../../../components/Card/index.vue';
  import BarChart from './../BarChart/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RevenueAnalytics',
    components: { Card, BarChart },
    data() {
      return {
        data: {
          labels: ['2019', '2020', '2021', '2022', '2023', '24'],
          datasets: [
            {
              label: 'Sales',
              backgroundColor: ['#E78724', '#104862', '#E78724', '#104862', '#E78724', '#104862'],
              data: [48, 20, 73, 80, 48, 73, 39],
              barThickness: 30,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                stepSize: 25, // Set the step size for y-axis ticks
                callback: function (value) {
                  return value; // Ensure the ticks display correctly
                },
              },
            },
          },
        },
      };
    },
  };
</script>
<style scoped>
  .chart-container > div {
    height: 200px !important;
  }
</style>
