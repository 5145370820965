<template>
  <Card title="Loan Products">
    <template v-slot:action>
      <Button @click="openAddDialog">Add Product</Button>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <!-- add or edit loan product -->
  <Dialog :title="`${isOpenDialog} Loan Product`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div class="form-group">
        <InputField label="Name *" :maxLength="50" :value="formData.name" v-model="formData.name" :disable-validation="true" />
        <span v-if="validationFormErrors.name.length" class="text-sm text-red">
          {{ validationFormErrors.name }}
        </span>
      </div>
      <div class="form-group">
        <SelectInput label="Interest Rate Type" :value="formData.interestRateType" v-model="formData.interestRateType" :options="interestRateTypeOptions" />
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed'">
        <SelectInput label="Initial Index Name" :value="formData.initialIndexName" v-model="formData.initialIndexName" :options="indexNameOptions" />
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed'">
        <SelectInput label="Expected Index Name" :value="formData.expectedIndexName" v-model="formData.expectedIndexName" :options="indexNameOptions" />
      </div>
      <div class="form-group" v-if="formData.interestRateType === 'fixed'">
        <FloatInput label="Fixed rate (%)" :value="formData.fixedRate" v-model="formData.fixedRate" />
      </div>
      <div class="form-group">
        <MoneyInput label="Lending Limit" :value="formData.lendingLimit" v-model="formData.lendingLimit" />
      </div>
      <div class="form-group">
        <FloatInput label="MIP Rate (%)" :value="formData.mipRate" v-model="formData.mipRate" />
      </div>
      <div class="form-group">
        <MoneyInput label="Monthly Servicing Fee (0 ~ 30)" :value="formData.monthlyServicingFee" v-model="formData.monthlyServicingFee" />
        <span v-if="validationFormErrors.monthlyServicingFee.length" class="text-sm text-red">
          {{ validationFormErrors.monthlyServicingFee }}
        </span>
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed'">
        <FloatInput label="Interest Rate Cap (%)" :value="formData.interestRateCap" v-model="formData.interestRateCap" />
      </div>
      <div class="form-group">
        <CheckboxInput label="Will be used for calculation as default?" :value="formData.default" v-model="formData.default" />
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog">Save Product</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Product Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this product?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import FloatInput from '@/components/FormInput/FloatInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { INDEX_NAMES, INTEREST_RATE_TYPES, INTEREST_RATE_TYPE_OPTIONS, INDEX_NAMES_OPTIONS } from '@/constants';
  import { formatNumberWithCommas } from '@/utils/index.js';
  import Confirmation from '@/components/Confirmation/index.vue';

  const initialData = {
    name: '',
    interestRateType: 'fixed',
    initialIndexName: '1_month',
    expectedIndexName: '1_month',
    lendingLimit: 0,
    fixedRate: 0,
    mipRate: 0,
    monthlyServicingFee: 0,
    interestRateCap: 0,
    default: false,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoanProducts',
    components: {
      Button,
      Card,
      CheckboxInput,
      DataTable,
      Dialog,
      InputField,
      SelectInput,
      MoneyInput,
      FloatInput,
      Confirmation,
    },
    data() {
      return {
        tableHeaders: [
          { key: 'name', label: 'Name' },
          { key: 'interestRateType', label: 'Interest Rate Type' },
          { key: 'fixedRate', label: 'Fixed Rate' },
          { key: 'initialIndexName', label: 'Initial Index Name' },
          { key: 'expectedIndexName', label: 'Expected Index Name' },
          { key: 'lendingLimit', label: 'Lending Limit' },
          { key: 'mipRate', label: 'MIP Rate' },
          { key: 'monthlyServicingFee', label: 'Monthly Servicing Fee' },
          { key: 'interestRateCap', label: 'Interest Rate Cap' },
          { key: 'default', label: 'Default Use for Calculation?' },
          { key: 'action', label: 'Action' },
        ],
        products: [],
        tableData: [],
        isOpenDialog: '',
        interestRateTypeOptions: INTEREST_RATE_TYPE_OPTIONS,
        indexNameOptions: INDEX_NAMES_OPTIONS,
        formData: {},
        validationFormErrors: {
          name: '',
          monthlyServicingFee: '',
        },
        selectedProduct: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      await this.fetchProducts();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchProducts() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/products/`);
          this.products = response.data;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.products.find((el) => el.id == id);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      validateForm() {
        this.validationFormErrors = {
          name: !this.formData.name?.length ? 'This field is required.' : '',
          monthlyServicingFee: !(Number(this.formData.monthlyServicingFee) >= 0 && Number(this.formData.monthlyServicingFee) <= 30)
            ? 'This field must be in the range of 0 to 30.'
            : '',
        };
        return !this.validationFormErrors.name.length && !this.validationFormErrors.monthlyServicingFee.length;
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;

        const data = { ...this.formData };
        if (data.interestRateType === 'fixed') {
          data.initialIndexName = null;
          data.expectedIndexName = null;
          data.interestRateCap = null;
        } else {
          data.fixedRate = null;
        }

        this.setLoading(true);
        try {
          if (data.id) await apiService.patch(`/loan/products/${data.id}/`, { ...data });
          else
            await apiService.post(`/loan/products/`, {
              ...data,
            });
          this.$root.showSnackbar(`Loan product information has been updated successfully!`, 'success');
          await this.fetchProducts();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
      },
      updateTableData() {
        let newTableData = [];
        this.products.forEach((product) => {
          newTableData.push({
            ...product,
            fixedRate: product.fixedRate ? `${product.fixedRate}%` : 'Not Applicable',
            interestRateType: INTEREST_RATE_TYPES[product.interestRateType],
            initialIndexName: INDEX_NAMES[product.initialIndexName ?? 'not_applicable'],
            expectedIndexName: INDEX_NAMES[product.expectedIndexName ?? 'not_applicable'],
            lendingLimit: `$${this.formattedNumber(product.lendingLimit)}`,
            mipRate: `${product.mipRate}%`,
            monthlyServicingFee: `$${this.formattedNumber(product.monthlyServicingFee)}`,
            interestRateCap: product.interestRateCap ? `${this.formattedNumber(product.interestRateCap)}%` : 'Not Applicable',
            actions: ['edit', 'delete'],
          });
        });
        this.tableData = newTableData;
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedProduct = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/products/${this.selectedProduct.id}/`);
          this.$root.showSnackbar('Loan product information has been deleted successfully', 'success');
          await this.fetchProducts();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete loan product information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      formattedNumber(value) {
        return formatNumberWithCommas(value);
      },
    },
    computed: {},
    watch: {
      products: {
        handler() {
          this.updateTableData();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }
</style>
