<template>
  <Card title="Data Collection">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <IntegerInput label="Family size" :value="formData.familySize" v-model.trim="formData.familySize" :disable-validation="true" />
        <span v-if="validationFormErrors.familySize" class="text-sm text-red">
          {{ validationFormErrors.familySize }}
        </span>
      </div>
      <div>
        <!-- <InputField
          label="Residual Income Required"
          id="residualIncomeRequired"
          :value="formData.residualIncomeRequired"
          v-model.trim="formData.residualIncomeRequired"
          :placeholder="'Auto calculated values.'"
          :disable-validation="true"
          :disabled="true"
        /> -->
      </div>
      <div>
        <RadioInput
          label="Mortgage or Rent Free?"
          id="mortgageOrRentFree"
          :options="radioInputOptions"
          :value="formData.mortgageOrRentFree"
          v-model="formData.mortgageOrRentFree"
        />
      </div>
      <div>
        <div v-if="formData.mortgageOrRentFree">
          <IntegerInput label="The period being mortgage/rent free months" :value="formData.freeMonths" v-model.trim="formData.freeMonths" :disable-validation="true" />
        </div>
      </div>
      <div>
        <RadioInput
          label="12-Month Housing Payment History is Acceptable?"
          id="housingPaymentAcceptable"
          :options="radioInputOptions"
          :value="formData.housingPaymentAcceptable"
          v-model="formData.housingPaymentAcceptable"
        />
      </div>
      <div>
        <RadioInput label="Mortgage was modified?" id="mortgageModified" :options="radioInputOptions" :value="formData.mortgageModified" v-model="formData.mortgageModified" />
      </div>
      <div>
        <RadioInput
          label="Are property taxes delinquent in the last 24 months?"
          id="delinquent"
          :options="radioInputOptions"
          :value="formData.delinquent"
          v-model="formData.delinquent"
        />
      </div>
      <div>
        <RadioInput
          label="Hazard insurance in place last 12 months? "
          id="hazardInsurance"
          :options="radioInputOptions"
          :value="formData.hazardInsurance"
          v-model="formData.hazardInsurance"
        />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  // import InputField from '@/components/FormInput/InputField/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DataCollection',
    components: {
      Button,
      Card,
      // InputField,
      IntegerInput,
      RadioInput,
    },
    props: {
      financialId: {
        type: String,
        required: true,
      },
      loanId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        localData: {
          familySize: null,
          residualIncomeRequired: null,
          mortgageOrRentFree: null,
          freeMonths: null,
          housingPaymentAcceptable: null,
          mortgageModified: null,
          delinquent: null,
          hazardInsurance: null,
        },
        formData: {
          familySize: null,
          residualIncomeRequired: null,
          mortgageOrRentFree: null,
          freeMonths: null,
          housingPaymentAcceptable: null,
          mortgageModified: null,
          delinquent: null,
          hazardInsurance: null,
        },
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        validationFormErrors: {
          familySize: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchDataCollection() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/data_collections/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) this.localData = response.data[0];
          else {
            this.resetLocalData();
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Validate form data: Family Size is required fields for Calculation.
      validateForm() {
        this.validationFormErrors = {
          familySize: !this.formData.familySize || this.formData.familySize == 0 ? 'This should be greater than 0.' : '',
        };
        return !this.validationFormErrors.familySize;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/data_collections/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/data_collections/`, {
              loan: this.loanId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Data collection information has been updated successfully!`, 'success');
          await this.fetchDataCollection();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
        this.validationFormErrors = {
          familySize: '',
        };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      resetLocalData() {
        this.localData = {
          familySize: null,
          residualIncomeRequired: null,
          mortgageOrRentFree: null,
          freeMonths: null,
          housingPaymentAcceptable: null,
          mortgageModified: null,
          delinquent: null,
          hazardInsurance: null,
        };
        this.validationFormErrors = {
          familySize: '',
        };
      },
    },
    watch: {
      financialId: {
        handler() {
          this.fetchDataCollection();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
