<template>
  <InputField label="Full Property Address" id="address" ref="address" :value="formData.address" v-model.trim="formData.address" :disable-validation="true" />
  <div class="my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
    <div>
      <SelectInput label="Status" id="status" ref="status" :options="statusOptions" v-model="formData.status" :value="formData.status" :disable-validation="true" />
    </div>
    <div>
      <InputField label="Account #" id="account" ref="account" :value="formData.account" v-model.trim="formData.account" :disable-validation="true" />
    </div>
  </div>
  <div class="my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
    <div>
      <InputField label="Creditor Name" id="creditor" ref="creditor" :value="formData.creditor" v-model.trim="formData.creditor" :disable-validation="true" />
    </div>
    <div>
      <MoneyInput label="Loan Balance" id="balance" ref="balance" :value="formData.balance" v-model.trim="formData.balance" :disable-validation="true" />
    </div>
  </div>
  <div class="flex justify-end gap-4 mt-5">
    <Button variant="primary" @click="emitConfirm" style="width: fit-content" :disabled="!isDraft || !isValidForm"> Save FHA Loan </Button>
    <Button variant="secondary" @click="emitClose" style="width: fit-content"> Cancel </Button>
  </div>
</template>
<script>
  import { isEqual } from 'lodash';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Button from '@/components/Button/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ExistingLoanForm',
    components: {
      InputField,
      MoneyInput,
      SelectInput,
      Button,
    },
    props: {
      itemData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        isValidForm: false,
        formData: {
          address: null,
          status: null,
          account: null,
          creditor: null,
          balance: null,
        },
        localData: {
          address: null,
          status: null,
          account: null,
          creditor: null,
          balance: null,
        },
        statusOptions: [
          { label: 'Sold', value: 'Sold' },
          { label: 'Rental', value: 'Rental' },
          { label: 'Pending', value: 'Pending' },
        ],
      };
    },
    methods: {
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      checkIfValidForm() {
        const requiredFields = ['address', 'status', 'account', 'creditor', 'balance'];

        this.isValidForm = requiredFields.every((field) => this.formData[field]?.length > 0);
      },
      emitConfirm() {
        this.$emit('confirm', this.formData, this.itemData?.id);
      },
      emitClose() {
        this.$emit('close');
      },
    },
    watch: {
      formData: {
        handler() {
          this.checkIfDraft();
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
      itemData: {
        handler(newVal) {
          if (newVal) this.localData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
