<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goBack" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Trustees</h1>
      </div>
    </template>
    <template v-slot:action>
      <Button @click="openAddDialog">Add Trustee</Button>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <!-- add or edit trustee -->
  <Dialog :title="`${isOpenDialog} Trustee`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div class="form-group">
        <InputField label="Name *" :maxLength="50" :value="formData.name" v-model="formData.name" :disable-validation="true" />
        <span v-if="validationFormErrors.name.length" class="text-sm text-red">
          {{ validationFormErrors.name }}
        </span>
      </div>
      <div class="form-group">
        <InputField label="Email" :value="formData.email" v-model="formData.email" :disable-validation="true" />
      </div>
      <div class="form-group">
        <InputField label="Address 1" :value="formData.address1" v-model="formData.address1" :disable-validation="true" />
      </div>
      <div class="form-group">
        <InputField label="Address 2" :value="formData.address2" v-model="formData.address2" :disable-validation="true" />
      </div>
      <div class="form-group">
        <InputField label="City" :value="formData.city" v-model="formData.city" :disable-validation="true" />
      </div>
      <div class="form-group">
        <SelectInput label="State" id="state" :options="stateOptions" v-model="formData.state" :value="formData.state" :disable-validation="true" />
      </div>
      <div class="form-group">
        <InputField label="ZipCode" :value="formData.zipCode" v-model="formData.zipCode" :disable-validation="true" />
      </div>
      <div v-if="isSuperUser()" class="form-group">
        <SelectInput label="Company" :value="formData.company" v-model="formData.company" :options="companyOptions" />
        <span v-if="validationFormErrors.company.length" class="text-sm text-red">
          {{ validationFormErrors.company }}
        </span>
      </div>
      <div class="form-group flex items-center">
        <CheckboxInput label="Active" :value="formData.is_active" v-model="formData.is_active" />
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog">Save Trustee</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Trustee Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this trustee?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import { US_STATES } from '@/constants';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';

  const initialData = {
    name: '',
    email: '',
    phone: '',
    fax: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    license: '',
    company: null,
    is_active: false,
    is_delete: false,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Trustees',
    components: {
      Button,
      Card,
      CheckboxInput,
      DataTable,
      Dialog,
      InputField,
      SelectInput,
      Confirmation,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    data() {
      return {
        trustees: [],
        tableHeaders: [
          { key: 'name', label: 'Name' },
          { key: 'email', label: 'Email' },
          { key: 'address1', label: 'Address 1' },
          { key: 'address2', label: 'Address 2' },
          { key: 'city', label: 'City' },
          { key: 'state', label: 'State' },
          { key: 'zipCode', label: 'Zip' },
          { key: 'company', label: 'Company' },
          { key: 'is_active', label: 'Active' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        isOpenDialog: '',
        formData: {},
        stateOptions: US_STATES,
        companyOptions: [],
        validationFormErrors: {
          name: '',
          company: '',
        },
        selectedTrustee: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      if (isCompanyUser()) {
        this.redirectToDashboard();
        return;
      }
      await this.fetchCompanies();
      await this.fetchTrustees();
    },
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      async fetchCompanies() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/company/companies/');
          this.companyOptions = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        } catch (error) {
          this.companyOptions = [];
          this.$root.showSnackbar(`Failed to fetch company data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchTrustees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/trustees/`);
          this.trustees = response.data;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.trustees.find((el) => el.id == id);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      validateForm() {
        this.validationFormErrors = {
          name: !this.formData.name?.length ? 'This field is required.' : '',
          company: !this.formData?.company && isSuperUser() ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.name.length && !this.validationFormErrors.company.length;
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;
        if (this.isCompanyAdmin) {
          delete this.formData.company;
        }
        this.setLoading(true);
        try {
          if (this.formData.id) {
            await apiService.patch(`/loan/trustees/${this.formData.id}/`, { ...this.formData });
          } else {
            await apiService.post(`/loan/trustees/`, {
              ...this.formData,
            });
          }
          this.$root.showSnackbar(`Trustee information has been updated successfully!`, 'success');
          await this.fetchTrustees();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
        this.validationFormErrors = {
          name: '',
          company: '',
        };
      },
      updateTableData() {
        let newTableData = [];
        this.trustees.forEach((trustee) => {
          newTableData.push({
            ...trustee,
            company: this.companyOptions.find((company) => company.value === trustee.company)?.label,
            actions: ['edit', 'delete'],
          });
        });
        this.tableData = newTableData;
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedTrustee = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/trustees/${this.selectedTrustee.id}/`);
          this.$root.showSnackbar('Trustee information has been deleted successfully', 'success');
          await this.fetchTrustees();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete trustee information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      goBack() {
        this.$router.push({ name: 'Settings' });
      },
    },
    computed: {},
    watch: {
      trustees: {
        handler() {
          this.updateTableData();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }
</style>
