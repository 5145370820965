<template>
  <div v-if="loadFailed" class="flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
      <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
      <h2 class="text-2xl font-semibold text-gray-800 mb-2">Failed to load template</h2>
      <p class="text-gray-600 mb-4">It's failed to load existing template data.</p>
      <div class="flex justify-center gap-4">
        <Button @click="backToTemplateList">Go to list</Button>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col h-full">
    <div class="flex justify-between items-center border border-gray bg-white rounded-lg p-2 mb-2">
      <div class="flex items-center">
        <button @click="backToTemplateList" class="mr-3">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <PageHeader :title="`${isUpdate ? 'Update' : 'Create'} Template`" />
      </div>
    </div>
    <div class="flex flex-col min-h-0 grow border border-gray bg-white rounded-lg p-3">
      <div class="flex flex-col">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <InputField
              label="Template Title *"
              id="name"
              ref="name"
              placeholder="Enter tempalte title"
              v-model.trim="formData.name"
              :value="formData.name"
              :disable-validation="true"
            />
          </div>
          <div class="flex justify-between items-end gap-2">
            <Button variant="primary" @click="handleSave" style="width: fit-content; height: 39px" :disabled="disabledSave"> {{ isUpdate ? 'Save Changes' : 'Create' }} </Button>
          </div>
        </div>
      </div>

      <div class="flex flex-col grow min-h-0 border-t border-gray-200 mt-4">
        <CustomEditor v-model:pagesData="pages" v-model:fieldsData="fields" v-model:paperSize="paperSize" v-model:pageMargins="pageMargins" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import CustomEditor from '../../components/CustomEditor/index.vue';
  import { PAGE_MARGINS } from '../../components/const.js';

  export default {
    name: 'PDFTemplateForm',
    components: {
      Button,
      PageHeader,
      InputField,
      CustomEditor,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
    },
    data() {
      return {
        isUpdate: typeof this.$route.params.id !== 'undefined',
        loadFailed: false,
        formData: {
          name: '',
        },

        // Sync with child component
        fields: [],
        pages: [''],
        paperSize: 'LEGAL',
        pageMargins: PAGE_MARGINS.SLIM,
      };
    },
    computed: {
      disabledSave() {
        return !this.formData.name.length;
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      backToTemplateList() {
        this.$router.push({ name: 'DocumentTemplate' });
      },
      async fetchTemplate() {
        this.setLoading(true);
        try {
          const templateId = this.$route.params.id;
          const response = await apiService.get(`/documents/templates/${templateId}/`);
          this.loadFailed = false; // Load template data succeed
          this.handleTemplateData(response.data);
        } catch (error) {
          this.loadFailed = true; // Load template data failed
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTemplateData(template) {
        this.formData = {
          name: template.name,
        };
        this.fields = template.fields || [];
        this.pages = template?.jsonContent?.pages || [''];
        this.paperSize = template?.jsonContent?.paperSize || 'LEGAL';
        this.pageMargins = template?.jsonContent?.pageMargins || PAGE_MARGINS.SLIM;
      },
      async handleSave() {
        this.setLoading(true);
        try {
          const data = new FormData();
          data.append('type', 'CUSTOM');
          data.append('name', this.formData.name);
          data.append('fields', JSON.stringify(this.fields));
          data.append(
            'jsonContent',
            JSON.stringify({
              pages: this.pages,
              paperSize: this.paperSize,
              pageMargins: this.pageMargins,
            })
          );

          if (this.isUpdate) {
            await apiService.patch(`/documents/templates/${this.$route.params.id}/`, data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          } else {
            await apiService.post('/documents/templates/', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          }
          this.$root.showSnackbar(`Document template ${this.isUpdate ? 'updated' : 'created'} successfully`, 'success');
          if (!this.isUpdate) this.backToTemplateList();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const firstErrorKey = Object.keys(error.response.data)[0];
            const firstErrorMessage = error.response.data[firstErrorKey][0];
            this.$root.showSnackbar(`Failed to ${this.isUpdate ? 'update' : 'create'} document template: ${firstErrorMessage}`, 'error');
          } else {
            this.$root.showSnackbar(
              `Failed to ${this.isUpdate ? 'update' : 'create'} document template: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`,
              'error'
            );
          }
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      '$route.params.id': {
        handler(newId, oldId) {
          if (newId !== oldId) {
            this.fetchTemplate();
          }
        },
        immediate: true,
      },
    },
  };
</script>
