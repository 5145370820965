<template>
  <Card title="Company Profiles">
    <template v-slot:action>
      <div class="flex items-center">
        <div>
          <Button @click="handleAddCompany" class="btn btn-primary">Add Company</Button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" @action="handleAction" />
  </Card>
  <!-- Delete Company Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this company?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { getCompanyTypeFormatted } from '@/utils';
  import { USER_HIERARCHIES } from '@/constants';
  import { isSuperUser } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Companies',
    components: {
      Button,
      Card,
      Confirmation,
      DataTable,
    },
    data() {
      return {
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'company_type', label: 'Company Type' },
          { key: 'ein', label: 'EIN' },
          { key: 'fha_id', label: 'FHA ID' },
          { key: 'active', label: 'Active' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        selectedCompany: null,
        isDeleteConfirmationOpen: false,
        userHierarchies: USER_HIERARCHIES,
      };
    },
    async created() {
      // Only Super User can access to this page, if not, redirect to dashboard page
      if (!isSuperUser()) {
        this.redirectToDashboard();
        return;
      }
      await this.fetchCompanies();
    },
    computed: {},
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      async fetchCompanies() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/company/companies/');
          this.tableData = response.data.map((item) => ({
            id: item.id,
            name: item.name,
            company_type: getCompanyTypeFormatted(item.company_type),
            ein: item.ein,
            fha_id: item.fha_id === 0 ? '' : item.fha_id,
            nmls_id: item.nmls_id,
            company_nmls_id: item.company_nmls_id,
            active: item.is_active,
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleAddCompany() {
        this.$router.push({ name: 'AddCompany' });
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.$router.push({ name: 'CompanyDetail', params: { id: item.id } });
        } else if (action === 'delete') {
          this.selectedCompany = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/company/companies/${this.selectedCompany.id}/`);
          this.$root.showSnackbar('Company data has been deleted successfully', 'success');
          await this.fetchCompanies();
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
    },
  };
</script>
