<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="backToPackageList" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">{{ `${isUpdate ? 'Update' : 'Create'} Package` }}</h1>
      </div>
    </template>
    <div class="border border-gray bg-white rounded-lg p-5 grow overflow-y-auto">
      <div class="flex flex-col md:flex-row gap-3 items-center border-b border-gray-200 mb-4 pb-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <InputField
              label="Package Title *"
              id="name"
              ref="name"
              placeholder="Enter package title"
              v-model.trim="formData.name"
              :value="formData.name"
              :disable-validation="true"
            />
          </div>
        </div>
        <div class="flex mt-2 md:mt-0 md:ml-auto gap-4">
          <Button variant="primary" @click="handleSave" style="width: fit-content" :disabled="disabledSave"> {{ isUpdate ? 'Save Changes' : 'Create' }} </Button>
        </div>
      </div>

      <div v-if="noTemplatesLoaded" class="flex justify-center items-center">
        <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
          <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
          <h2 class="text-2xl font-semibold text-gray-800 mb-2">No Templates</h2>
          <p class="text-gray-600 mb-4">There is no created templates yet.</p>
          <div class="flex justify-center gap-4">
            <Button @click="navigateToCreateNew"><font-awesome-icon icon="fa-solid fa-plus" /> Create New Template</Button>
          </div>
        </div>
      </div>

      <div v-else class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
        <div class="relative" v-for="(template, index) in templates" :key="index">
          <!-- Something like toggle component -->
          <div class="absolute right-2 top-2 z-10 cursor-pointer" @click="toggleInclusion(template.id)">
            <font-awesome-icon v-if="isIncluded(template.id)" class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-check" />
            <font-awesome-icon v-else class="text-white bg-gray-300 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-circle" />
          </div>

          <DocumentCard :action="false" :title="template.name" :pdf-link="template.templateFileUrl" @edit="editTemplate(template.id)" @delete="deleteTemplate(template.id)" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import DocumentCard from '../../../../components/DocumentCard/index.vue';

  export default {
    name: 'PackageForm',
    components: {
      Button,
      Card,
      InputField,
      DocumentCard,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchInitialData();
    },
    data() {
      return {
        isUpdate: typeof this.$route.params.id !== 'undefined',
        templates: undefined,
        formData: {
          name: '',
          templateIds: [],
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      // Fetch package with its id
      async fetchInitialData() {
        this.setLoading(true);
        try {
          // Fetch Templates
          const response = await apiService.get('/documents/templates/');
          this.templates = response.data;
        } catch (error) {
          this.templates = undefined;
          this.$root.showSnackbar(`Failed to fetch document templates: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }

        // Fetch pacakge data when edit mode
        if (this.isUpdate) {
          try {
            const response = await apiService.get(`/documents/packages/${this.$route.params.id}/`);
            this.formData = {
              name: response.data.name,
              templateIds: response.data.templates.map((template) => template.id),
            };
          } catch (error) {
            this.$root.showSnackbar(`Failed to fetch package data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        }

        this.setLoading(false);
      },
      // handle action to create or update package data
      async handleSave() {
        this.setLoading(true);
        try {
          if (this.isUpdate) {
            await apiService.patch(`/documents/packages/${this.$route.params.id}/`, this.formData);
          } else {
            await apiService.post('/documents/packages/', this.formData);
          }
          this.$root.showSnackbar(`Document package ${this.isUpdate ? 'update' : 'create'} successfully`, 'success');
          this.backToPackageList();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const firstErrorKey = Object.keys(error.response.data)[0];
            const firstErrorMessage = error.response.data[firstErrorKey][0];
            this.$root.showSnackbar(`Failed to ${this.isUpdate ? 'update' : 'create'} document package: ${firstErrorMessage}`, 'error');
          } else {
            this.$root.showSnackbar(
              `Failed to ${this.isUpdate ? 'update' : 'create'} document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`,
              'error'
            );
          }
        } finally {
          this.setLoading(false);
        }
      },
      toggleInclusion(templateId) {
        const index = this.formData.templateIds.indexOf(templateId);
        if (index === -1) {
          this.formData.templateIds.push(templateId);
        } else {
          this.formData.templateIds.splice(index, 1);
        }
      },
      isIncluded(templateId) {
        return this.formData.templateIds.includes(templateId);
      },
      backToPackageList() {
        this.$router.push({ name: 'DocumentPackage' });
      },
      navigateToCreateNew() {
        this.$router.push({ name: 'PDFTemplateCreate' });
      },
    },
    computed: {
      noTemplatesLoaded() {
        return this.templates?.length == 0;
      },
      disabledSave() {
        return !this.formData.name.length || !this.formData.templateIds.length;
      },
    },
    watch: {},
  };
</script>
