<template>
  <Card title="Deal Status">
    <div class="flex items-end">
      <h3 class="text-black text-2xl font-semibold">4,289</h3>
      <div class="bg-[#E9F9F4] inline-block py-[2px] px-[5px] text-[#26BF94] text-sm rounded-[2px] mx-2">
        <span class="font-semibold mr-1">1.02</span><font-awesome-icon icon="caret-up" />
      </div>
      <p class="text-base text-[#8C9097] font-semibold">compared to last week</p>
    </div>
    <div class="progress-bar flex items-center mt-4">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <ul class="deal-listing mt-5">
      <li class="flex justify-between mb-1">
        <p class="text-dark font-semibold font-base">Successful Deals</p>
        <span class="text-[#8C9097] font-base">987 deals</span>
      </li>
      <li class="flex justify-between mb-1">
        <p class="text-dark font-semibold font-base">Pending Deals</p>
        <span class="text-[#8C9097] font-base">987 deals</span>
      </li>
      <li class="flex justify-between mb-1">
        <p class="text-dark font-semibold font-base">Rejected Deals</p>
        <span class="text-[#8C9097] font-base">987 deals</span>
      </li>
      <li class="flex justify-between">
        <p class="text-dark font-semibold font-base">Rejected Deals</p>
        <span class="text-[#8C9097] font-base">987 deals</span>
      </li>
    </ul>
  </Card>
</template>

<script>
  import Card from './../../../../components/Card/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DealStatus',
    components: { Card },
  };
</script>
<style scoped>
  .deal-listing li {
    padding-left: 15px;
    position: relative;
  }
  .deal-listing li::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-color: #845adf;
    border-radius: 50%;
    top: 8px;
    left: 0;
  }
  .progress-bar div {
    background-color: #845adf;
    height: 5px;
    width: 25%;
    border-radius: 5px;
  }

  .deal-listing li:nth-child(2):before,
  .progress-bar div:nth-child(2) {
    background-color: #49b6f5;
  }
  .deal-listing li:nth-child(3):before,
  .progress-bar div:nth-child(3) {
    background-color: #f5b849;
  }
  .deal-listing li:nth-child(4):before,
  .progress-bar div:nth-child(4) {
    background-color: #26bf94;
  }
</style>
