import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/components/Layout/layout.vue';
import Login from '@/services/Auth/Login/index.vue';
import ForgetPassword from '@/services/Auth/ForgetPassword/index.vue';
import Dashboard from '@/pages/Dashboard/index.vue';
import Analytics from '@/pages/Analytics/index.vue';
import Companies from '@/pages/Companies/index.vue';
import AddCompany from '@/pages/Companies/components/AddCompany/index.vue';
import CompanyDetails from '@/pages/Companies/components/CompanyDetails/index.vue';
import Users from '@/pages/Users/index.vue';
import AddUser from '@/pages/Users/components/AddUser/index.vue';
import UserDetails from '@/pages/Users/components/UserDetails/index.vue';
import Settings from '@/pages/Settings/index.vue';
import CompanySettings from '@/pages/Settings/pages/CompanySettings/index.vue';
import Fees from '@/pages/Settings/pages/Fees/index.vue';
import SettlementAgents from '@/pages/Settings/pages/SettlementAgents/index.vue';
import Trustees from '@/pages/Settings/pages/Trustees/index.vue';
import Payees from '@/pages/Settings/pages/Payees/index.vue';
import MyLicenses from '@/pages/MyLicenses/index.vue';
import FindLoan from '@/pages/FindLoan/index.vue';
import LoanDetail from '@/pages/LoanDetail/index.vue';
import Document from '@/pages/Document/index.vue';
import DocumentPackage from '@/pages/Document/pages/DocumentPackage/index.vue';
import PacakgeForm from '@/pages/Document/pages/DocumentPackage/pages/PackageForm/index.vue';
import DocumentTemplate from '@/pages/Document/pages/DocumentTemplate/index.vue';
import PDFTemplateForm from '@/pages/Document/pages/DocumentTemplate/pages/PDFTemplateForm/index.vue';
import CustomTemplateForm from '@/pages/Document/pages/DocumentTemplate/pages/CustomTemplateForm/index.vue';
import Profile from '@/pages/Profile/index.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: { title: 'Dashboard - ReversePilot' },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics,
        meta: { title: 'Analytics - ReversePilot' },
      },
      {
        path: 'companies',
        name: 'Companies',
        component: Companies,
        meta: { title: 'Companies - ReversePilot' },
      },
      {
        parent: 'companies',
        path: '/companies/add-company',
        name: 'AddCompany',
        component: AddCompany,
        meta: { title: 'Add Company - ReversePilot' },
      },
      {
        parent: 'companies',
        path: 'companies/company/:id',
        name: 'CompanyDetail',
        component: CompanyDetails,
        meta: { title: 'Company Detail - ReversePilot' },
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: { title: 'Users - ReversePilot' },
      },
      {
        parent: 'users',
        path: '/users/add-user',
        name: 'AddUser',
        component: AddUser,
        meta: { title: 'Add User - ReversePilot' },
      },
      {
        parent: 'users',
        path: 'users/user/:id',
        name: 'UserDetail',
        component: UserDetails,
        meta: { title: 'User Detail - ReversePilot' },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        meta: { title: 'Settings - ReversePilot' },
      },
      {
        path: 'comapny-settings',
        name: 'CompanySettings',
        component: CompanySettings,
        meta: { title: 'Company-Settings - ReversePilot' },
      },
      {
        path: 'fees',
        name: 'Fees',
        component: Fees,
        meta: { title: 'Fees - ReversePilot' },
      },
      {
        path: 'settlement-agents',
        name: 'SettlementAgents',
        component: SettlementAgents,
        meta: { title: 'Settlement Agents - ReversePilot' },
      },
      {
        path: 'trustees',
        name: 'Trustees',
        component: Trustees,
        meta: { title: 'Trustees - ReversePilot' },
      },
      {
        path: 'payees',
        name: 'Payees',
        component: Payees,
        meta: { title: 'Payees - ReversePilot' },
      },
      {
        path: 'licenses',
        name: 'Licenses',
        component: MyLicenses,
        meta: { title: 'My Licenses - ReversePilot' },
      },
      {
        path: 'find-loan',
        name: 'FindLoan',
        component: FindLoan,
        meta: { title: 'Find Loan - ReversePilot' },
      },
      {
        path: 'loan/:id',
        name: 'Loan',
        component: LoanDetail,
        meta: { title: 'Loan Detail - ReversePilot' },
      },
      {
        path: 'document',
        name: 'Document',
        component: Document,
        meta: { title: 'Document - ReversePilot' },
      },
      {
        path: 'document-package',
        name: 'DocumentPackage',
        component: DocumentPackage,
        meta: { title: 'Document Package - ReversePilot' },
      },
      {
        path: 'pacakge/create',
        name: 'PackageCreate',
        component: PacakgeForm,
        meta: { title: 'Package Create- ReversePilot' },
      },
      {
        path: 'pacakge/update/:id',
        name: 'PackageUpdate',
        component: PacakgeForm,
        meta: { title: 'Package Update- ReversePilot' },
      },
      {
        path: 'document-template',
        name: 'DocumentTemplate',
        component: DocumentTemplate,
        meta: { title: 'Document Template - ReversePilot' },
      },
      {
        path: 'pdf-template/create',
        name: 'PDFTemplateCreate',
        component: PDFTemplateForm,
        meta: { title: 'Template Create - ReversePilot' },
      },
      {
        path: 'pdf-template/update/:id',
        name: 'PDFTemplateUpdate',
        component: PDFTemplateForm,
        meta: { title: 'Template Update - ReversePilot' },
      },
      {
        path: 'custom-template/create',
        name: 'CustomTemplateCreate',
        component: CustomTemplateForm,
        meta: { title: 'Template Create - ReversePilot' },
      },
      {
        path: 'custom-template/update/:id',
        name: 'CustomTemplateUpdate',
        component: CustomTemplateForm,
        meta: { title: 'Template Update - ReversePilot' },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { title: 'Profile - ReversePilot' },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login - ReversePilot' },
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: { title: 'Forget Password - ReversePilot' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('auth_token');

  if ((to.name === 'Login' || to.name === 'ForgetPassword') && isAuthenticated) {
    next({ name: 'Dashboard' });
  } else if (!isAuthenticated && to.name !== 'Login' && to.name !== 'ForgetPassword') {
    next({ name: 'Login' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use the meta title if it exists
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
