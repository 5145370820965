<template>
  <Card title="Analytics">
    <NewTextEditor v-model="editFormData.template" />
    <Button class="mt-4" @click="submitForm">Submit</Button>
  </Card>
</template>

<script>
  import Card from './../../components/Card/index.vue';
  import NewTextEditor from './../../components/NewTextEditor/index.vue';
  import Button from './../../components/Button/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Analytics',
    components: {
      Card,
      NewTextEditor,
      Button,
    },
    data() {
      return {
        editFormData: {
          template: '',
        },
        placeholderValues: {
          firstname: '',
          lastname: '',
          surname: '',
        },
      };
    },
    watch: {
      'editFormData.template'(newVal) {
        console.log('Template updated:', newVal);
      },
    },
    methods: {
      submitForm() {
        const templateContent = this.editFormData.template;

        // Parse templateContent into a DOM structure
        const parser = new DOMParser();
        // eslint-disable-next-line no-unused-vars
        const doc = parser.parseFromString(templateContent, 'text/html');

        // Process placeholders
        const placeholders = {};
        const placeholderRegex = /\{\{(.*?)\}\}\s*:\s*(.*?)(?:<|$)/g;
        let match;
        while ((match = placeholderRegex.exec(templateContent)) !== null) {
          const key = match[1].trim();
          const value = match[2].trim();
          placeholders[key] = value;
        }

        const payload = {
          template: templateContent,
          placeholders: placeholders,
        };

        console.log('Submitted content:', payload);
        // Handle payload as needed, e.g., sending to an API or emitting an event
      },
    },
  };
</script>
