import store from '@/store/index.js';
import { COMPANY_TYPES, USER_HIERARCHIES } from '@/constants';

export const formatNumberWithCommas = (number) => {
  return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const getCompanyTypeFormatted = (type) => {
  return COMPANY_TYPES[type] || 'Broker';
};

export const isSuperUser = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.SUPER_USER;
};

export const isCompanyAdmin = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.COMPANY_ADMINISTRATOR;
};

export const isCompanyUser = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.COMPANY_USER;
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return ''; // Return an empty string or handle it as needed
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // Handle invalid date values if necessary
  }

  const isoString = date.toISOString();
  const [year, month, day] = isoString.split('T')[0].split('-');
  return `${year}-${month}-${day}`;
};
