<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <div class="relative flex items-center">
      <input
        type="text"
        :id="id"
        :value="inputValue"
        @input="validateInput"
        @keypress="validateNumber"
        class="pl-3"
        :class="['integer-input', { invalid: !isValid && !disableValidation }]"
      />
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'IntegerInput',
    props: {
      label: String,
      id: String,
      value: {
        type: [String, Number],
        default: 0,
      },
      disableValidation: Boolean,
    },
    data() {
      return {
        inputValue: this.value?.toString() || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput(event) {
        this.isDirty = true;

        this.inputValue = event.target.value.replace(/^0+(?!$)/, '');

        if (this.inputValue === '') {
          this.inputValue = '0';
        }

        const regex = /^\d+$/;
        this.isValid = regex.test(this.inputValue);
      },
      validateNumber(event) {
        const charCode = event.charCode;

        // Allow only numbers
        if (charCode < 48 || charCode > 57) {
          event.preventDefault();
        }
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required and must be a valid integer.`;
        }
        return '';
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue !== null && newValue !== undefined ? newValue.toString() : '';
      },
      inputValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    mounted() {
      this.inputValue = this.value ? this.value.toString() : '';
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  input {
    padding-left: 15px;
  }
</style>
