<template>
  <img src="../../../../assets/image/settings/settings.svg" class="w-9 h-9 p-2 rounded bg-[#EBF5FF]" />
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'SettingsIcon',
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>

<style scoped></style>
