<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <input
      :id="id"
      :type="type"
      :value="internalValue"
      :maxlength="maxLength"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="handleInput"
      :class="[{ invalid: !isValid && !disableValidation }, disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : '']"
    />
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'InputField',
    props: {
      label: String,
      type: {
        type: String,
        default: 'text',
      },
      id: String,
      value: [String, Number],
      disableValidation: Boolean,
      disabled: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.internalValue !== '';
      },
      handleInput(event) {
        this.internalValue = event.target.value;
        this.$emit('update:modelValue', this.internalValue);
        this.validateInput();
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
      },
    },
  };
</script>

<style>
  .invalid {
    border-color: red;
  }
  /* Add your custom styles here */
</style>
