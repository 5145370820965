<template>
  <div class="flex">
    <div class="w-1/2 h-screen">
      <Slider />
    </div>
    <div class="w-1/2 h-screen">
      <div class="p-10 h-screen relative flex justify-center items-center flex-col">
        <div class="absolute top-[40px] right-[40px]">
          <img src="../../../assets/image/brand-logo.svg" alt="login image" />
        </div>
        <div class="login-form-content mx-auto">
          <div class="text-center mb-8">
            <h3 class="text-dark text-4xl font-bold mb-2">Forgot password?</h3>
            <p class="text-dark-gray">
              Enter the email address you used when<br />
              you joined and we’ll send you instructions<br />
              to reset your password.
            </p>
          </div>
          <form @submit.prevent="submitForm">
            <div class="form-group mb-6">
              <label for="email">Email:</label>
              <input type="email" placeholder="@mail.com" id="email" v-model="formData.email" required />
              <span v-if="errors.email" class="error">{{ errors.email }}</span>
            </div>
            <button class="standard-btn" type="submit" :disabled="formInvalid">
              <span>Reset Password </span>
              <font-awesome-icon icon=" fa-solid fa-arrow-right" class="icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import Slider from './../Slider/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ForgetPassword',
    components: {
      Slider,
    },
    setup() {
      const formData = ref({
        email: '',
        password: '',
        rememberMe: false,
      });

      const errors = ref({
        email: '',
        password: '',
      });

      const validateForm = () => {
        let valid = true;

        if (!formData.value.email) {
          errors.value.email = 'Email is required';
          valid = false;
        } else {
          errors.value.email = '';
        }

        if (!formData.value.password) {
          errors.value.password = 'Password is required';
          valid = false;
        } else {
          errors.value.password = '';
        }

        return valid;
      };

      const submitForm = () => {
        if (validateForm()) {
          // Submit form logic here
          console.log('Form submitted');
        }
      };

      const formInvalid = () => {
        return !validateForm();
      };

      const showPassword = ref(false);

      const togglePasswordVisibility = () => {
        showPassword.value = !showPassword.value;
      };

      return {
        formData,
        errors,
        submitForm,
        formInvalid,
        showPassword,
        togglePasswordVisibility,
      };
    },
  };
</script>
<style scoped>
  .login-form-content {
    width: 480px;
  }
  label {
    color: #686868;
  }
  input::placeholder {
    color: #9a9a9a;
  }
  input {
    border-radius: 8px;
    background: #f9f9f9;
    border-color: #e6e6e6;
    padding: 17px 15px;
  }
  button {
    padding: 16px 15px;
    border-radius: 8px;
  }

  .standard-btn:hover .icon {
    margin-left: 20px;
  }
  .standard-btn .icon {
    margin-left: 15px;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 20px;
  }
  .toggle-password {
    color: #9a9a9a;
    position: absolute;
    top: 14px;
    font-size: 20px;
    right: 15px;
    cursor: pointer;
  }
</style>
