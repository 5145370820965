<template>
  <div :class="['border border-gray bg-white rounded-lg', customClass]">
    <div
      v-if="title || $slots.title || $slots.action"
      :class="[
        $slots.action ? 'flex items-center justify-between' : 'block',
        { 'py-2': $slots.action, 'py-4': !$slots.action },
        'card-header px-15 border-b border-gray shadow-card',
      ]"
    >
      <slot name="title"></slot>
      <h1 class="text-lg font-semibold leading-none" v-if="title">{{ title }}</h1>
      <slot name="action"></slot>
    </div>
    <div class="p-15">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Card',
    props: {
      title: {
        type: String,
        required: false,
      },
      customClass: {
        type: String,
        required: false,
        default: '',
      },
    },
  };
</script>
