<template>
  <Card title="Closing & Settlement">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField label="Trustee for Deed of Trust" id="trusteeForDeedOfTrust" :value="formData.trusteeForDeedOfTrust" v-model.trim="formData.trusteeForDeedOfTrust" />
      </div>
      <div>
        <InputField label="Settlement Agent Case #" id="settlementAgentCase" :value="formData.settlementAgentCase" v-model.trim="formData.settlementAgentCase" />
      </div>
      <div></div>
      <div class="flex justify-end items-end">
        <div class="mr-15">
          <Button variant="primary" @click="submitForm" class="w-fit" style="width: fit-content">Obtain MIN</Button>
        </div>
        <Button variant="secondary" @click="submitForm" class="w-fit" style="width: fit-content">Register MIN</Button>
      </div>
    </div>
    <div>
      <h3 class="mt-5 text-lg font-semibold">Dates</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
        <div>
          <DateInput label="RESPA Application Date" id="respaApplicationDate" v-model="formData.respaApplicationDate" :value="formData.respaApplicationDate" />
        </div>
        <div>
          <DateInput label="Application Date" id="applicationDate" v-model="formData.applicationDate" :value="formData.applicationDate" />
        </div>
        <div>
          <DateInput label="Case # Date" id="caseDate" v-model="formData.caseAssignedDate" :value="formData.caseAssignedDate" />
        </div>
        <div>
          <DateInput label="Closing Date" id="closingDate" v-model="formData.closingDate" :value="formData.closingDate" />
          <span v-if="!formData.closingDate && validationFormErrors.closingDate" class="text-sm text-red">
            {{ validationFormErrors.closingDate }}
          </span>
        </div>
        <div>
          <DateInput label="Re-Disclosure Date" id="reDisclosureDate" v-model="formData.reDisclosureDate" :value="formData.reDisclosureDate" />
        </div>
        <div>
          <DateInput label="Funding Date" id="fundingDate" v-model="formData.fundingDate" :value="formData.fundingDate" />
          <span v-if="!formData.fundingDate && validationFormErrors.fundingDate" class="text-sm text-red">
            {{ validationFormErrors.fundingDate }}
          </span>
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <Button variant="secondary" @click="handleCalcFundDate" class="w-fit" style="width: fit-content">Calculate Funding Date based on Closing Date</Button>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
        <div>
          <InputField label="MIN #:" id="min" :value="formData.min" v-model.trim="formData.min" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Signature Line" id="signatureLine" :value="formData.signatureLine" v-model.trim="formData.signatureLine" :disable-validation="true" />
        </div>
      </div>
      <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
        <div>
          <TextareaInput label="Aka" id="aka" :value="formData.aka" v-model.trim="formData.aka" :disable-validation="true" />
        </div>
        <div>
          <TextareaInput
            label="Notary Acknowledgement"
            id="notaryAcknowledgement"
            :value="formData.notaryAcknowledgement"
            v-model.trim="formData.notaryAcknowledgement"
            :disable-validation="true"
          />
        </div>
        <div>
          <InputField label="Legal Vesting" id="legalVesting" :value="formData.legalVesting" v-model.trim="formData.legalVesting" :disable-validation="true" />
        </div>
        <div>
          <SelectInput label="Living Trust" id="livingTrust" :options="livingTrustOptions" v-model="formData.livingTrust" :value="formData.livingTrust" />
        </div>
      </div>
      <div class="flex justify-end mt-5 gap-4">
        <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
        <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
      </div>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Closing',
    components: {
      Card,
      InputField,
      Button,
      DateInput,
      TextareaInput,
      SelectInput,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    async created() {
      await this.fetchCaseNumber();
      await this.fetchClosingSettlement();
    },
    data() {
      return {
        isDraft: false,
        formData: {
          trusteeForDeedOfTrust: '',
          settlementAgentCase: '',
          closingDate: null,
          reDisclosureDate: null,
          fundingDate: null,
          min: '',
          signatureLine: '',
          aka: '',
          notaryAcknowledgement: '',
          legalVesting: '',
          livingTrust: null,
        },
        localData: {
          trusteeForDeedOfTrust: '',
          settlementAgentCase: '',
          closingDate: null,
          reDisclosureDate: null,
          fundingDate: null,
          min: '',
          signatureLine: '',
          aka: '',
          notaryAcknowledgement: '',
          legalVesting: '',
          livingTrust: null,
        },
        livingTrustOptions: [
          { label: 'Sample Legal Vesting', value: 'Sample Legal Vesting' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        validationFormErrors: {
          closingDate: '',
          fundingDate: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCaseNumber() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/case_numbers/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) {
            this.localData = {
              ...this.localData,
              caseNumberId: response.data[0].id,
              respaApplicationDate: response.data[0].respaApplicationDate,
              applicationDate: response.data[0].applicationDate,
              caseAssignedDate: response.data[0].caseAssignedDate,
            };
          } else this.resetLocalData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchClosingSettlement() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/closing_settlements/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) {
            this.localData = {
              ...this.localData,
              ...response.data[0],
            };
          } else {
            this.resetLocalData();
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleCalcFundDate() {
        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/calculate-funding-date/', {
            closing_date: this.formData.closingDate,
          });
          this.formData.fundingDate = response.data.fundingDate;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Validate form data: Closing and Funding date are required.
      validateForm() {
        this.validationFormErrors = {
          closingDate: !this.formData.closingDate ? 'This field is required.' : '',
          fundingDate: !this.formData.fundingDate ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.closingDate && !this.validationFormErrors.fundingDate;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        try {
          this.setLoading(true);

          const caseNumberDates = {
            respaApplicationDate: this.formData.respaApplicationDate,
            applicationDate: this.formData.applicationDate,
            caseAssignedDate: this.formData.caseAssignedDate,
          };
          if (this.localData.caseNumberId)
            await apiService.patch(`/loan/case_numbers/${this.localData.caseNumberId}/`, {
              ...caseNumberDates,
            });
          else
            await apiService.post(`/loan/case_numbers/`, {
              ...caseNumberDates,
              loan: this.loanId,
            });

          if (this.localData.id)
            await apiService.patch(`/loan/closing_settlements/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/closing_settlements/`, {
              ...this.formData,
              loan: this.loanId,
            });
          this.$root.showSnackbar(`Closing settlement information has been updated successfully!`, 'success');

          await this.fetchCaseNumber();
          await this.fetchClosingSettlement();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
        this.resetValidationErrors();
      },
      resetLocalData() {
        this.localData = {
          trusteeForDeedOfTrust: '',
          settlementAgentCase: '',
          respaApplicationDate: null,
          applicationDate: null,
          cellDate: null,
          closingDate: null,
          reDisclosureDate: null,
          fundingDate: null,
          min: '',
          signatureLine: '',
          aka: '',
          notaryAcknowledgement: '',
          legalVesting: '',
          livingTrust: null,
        };
      },
      resetValidationErrors() {
        this.validationFormErrors = {
          closingDate: '',
          fundingDate: '',
        };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
    },
    watch: {
      loanId: {
        handler(newValue, oldValue) {
          if (newValue && oldValue) {
            this.fetchCaseNumber();
            this.fetchClosingSettlement();
          }
        },
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
