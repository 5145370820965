<template>
  <div class="inline-flex m-0 items-center font-medium leading-none p-2 rounded-full lg:!text-[10px] xl:!text-[12px] 2xl:!text-sm" :class="tagColor" v-if="shouldShowTitle">
    {{ title }}
  </div>
</template>
<script>
  export default {
    name: 'TagComponent',
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    computed: {
      shouldShowTitle() {
        return this.title && this.title.length > 0;
      },
      tagColor() {
        return this.getTagColor(this.title);
      },
    },
    methods: {
      getTagColor(status) {
        const colors = {
          Active: 'bg-[#1E90FF] text-white',
          Close: 'bg-[#FF4500] text-white',
          Sold: 'bg-[#32CD32] text-white',
          Rental: 'bg-[#4682B4] text-white',
          Pending: 'bg-[#FFD700] text-white',
          Adversed: 'bg-[#8A2BE2] text-white',
          InActive: 'bg-[#FF8C00] text-white',
          OnHold: 'bg-[#FF6347] text-white',
          Prospect: 'bg-[#20B2AA] text-white',
          Origination: 'bg-[#FFA500] text-white',
          ProcessingSetup: 'bg-[#696969] text-white',
          Processing: 'bg-[#FF4500] text-white',
          Underwriting: 'bg-[#DC143C] text-white',
          ClearToClose: 'bg-[#4169E1] text-white',
          Closing: 'bg-[#FF8C00] text-white',
          Funding: 'bg-[#FF8C00] text-white',
          Shipping: 'bg-[#FFD700] text-white',
          PrePurchaseQC: 'bg-[#DC143C] text-white',
          PrePurchase: 'bg-[#FF4500] text-white',
          PurchaseCompleted: 'bg-[#8B0000] text-white',
          Yes: 'bg-[#32CD32] text-white',
          No: 'bg-[#FF4500] text-white',
          Insured: 'bg-[#00CED1] text-white',
          'Submitted to Underwriting': 'bg-[#2F4F4F] text-white',
          'Post Closing': 'bg-[#FFD700] text-white',
        };
        return colors[status] || 'bg-orange-200 text-white';
      },
    },
  };
</script>
