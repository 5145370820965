<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">{{ label }}</label>
    <div class="custom-select-container">
      <select :id="id" v-model="selectedOption" @change="validateInput" :class="{ 'custom-invalid': !isValid && !disableValidation }">
        <option v-for="option in options" :value="option.value" :key="option.value" class="custom-option">
          {{ option.label }}
        </option>
      </select>
      <div class="custom-select-arrow">
        <font-awesome-icon icon="fa-solid fa-chevron-down" />
      </div>
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'SelectInput',
    props: {
      label: String,
      id: String,
      options: {
        type: Array,
        required: true,
      },
      value: String, // Bind value prop to selectedOption,
      disableValidation: Boolean,
    },
    data() {
      return {
        selectedOption: this.value || '', // Use selectedOption for v-model
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.selectedOption !== '';
        this.$emit('update:modelValue', this.selectedOption);
      },
    },
    watch: {
      value(newValue) {
        this.selectedOption = newValue;
        this.validateInput();
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .custom-select-container {
    position: relative;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    border: 1px solid #ccc;
    width: 100%;
    padding-right: 36px;
    background-color: transparent;
  }

  .custom-select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #757575;
  }

  .custom-option {
    background-color: #ffffff;
    color: #212121;
  }

  .custom-invalid {
    border-color: red;
  }
</style>
