import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      isLoading: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || null, // Load from localStorage
    };
  },
  mutations: {
    setLoading(state, status) {
      state.isLoading = status;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', JSON.stringify(userInfo)); // Save to localStorage
    },
    clearUserInfo(state) {
      state.userInfo = null;
      localStorage.removeItem('userInfo'); // Remove from localStorage
    },
  },
  actions: {
    setLoading(context, status) {
      context.commit('setLoading', status);
    },
    setUserInfo(context, userInfo) {
      context.commit('setUserInfo', userInfo);
    },
    clearUserInfo(context) {
      context.commit('clearUserInfo');
    },
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
  },
});

export default store;
