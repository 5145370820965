<template>
  <Card>
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="w-full">
        <h3 class="mb-3 text-lg font-semibold">Financial Assessment Findings</h3>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <SelectInput
              label="Residual Income Result"
              id="residualIncomeResult"
              :options="residualIncomeResultOptions"
              v-model="formData.residualIncomeResult"
              :value="formData.residualIncomeResult"
            />
          </div>
          <div>
            <SelectInput
              label="Credit History Result"
              id="creditHistoryResult"
              :options="creditHistoryResultOptions"
              v-model="formData.creditHistoryResult"
              :value="formData.creditHistoryResult"
            />
          </div>
          <div>
            <SelectInput
              label="Property Charge Payment History"
              id="propertyChargePaymentHistoryResult"
              :options="propertyChargePaymentHistoryResultOptions"
              v-model="formData.propertyChargePaymentHistoryResult"
              :value="formData.propertyChargePaymentHistoryResult"
            />
          </div>
          <div>
            <SelectInput
              label="Overall Financial Assessment Result"
              id="overallFinancialAssessmentResult"
              :options="overallFinancialAssessmentResultOptions"
              v-model="formData.overallFinancialAssessmentResult"
              :value="formData.overallFinancialAssessmentResult"
            />
          </div>
        </div>
        <div class="mt-4">
          <div>
            <TextareaInput
              label="Underwriting Analysis"
              id="underwritingAnalysis"
              ref="underwritingAnalysis"
              :value="formData.underwritingAnalysis"
              v-model.trim="formData.underwritingAnalysis"
              :disable-validation="true"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col">
        <h3 class="mb-3 text-lg font-semibold">Extenuating Circumstances</h3>
        <TextareaInput
          label="Description of Extenuating Circumstance"
          id="extenuatingDescription"
          ref="extenuatingDescription"
          :value="formData.extenuatingDescription"
          v-model.trim="formData.extenuatingDescription"
          :disable-validation="true"
        />
        <div class="flex justify-end gap-4 mt-auto">
          <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
          <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
        </div>
      </div>
    </div>
  </Card>
  <TabCard class="my-15">
    <ul class="tabs flex items-center">
      <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
        {{ tabOption.title }}
      </li>
    </ul>
  </TabCard>
  <div class="content-container">
    <component :is="activeTabComponent" :financialId="formData.id" :loanId="loanId" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import Income from './components/Income/index.vue';
  import AssetDissipation from './components/AssetDissipation/index.vue';
  import DataCollection from './components/DataCollection/index.vue';
  import Expenses from './components/Expenses/index.vue';
  import CashFlow from './components/CashFlow/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FinancialAssessment',
    components: {
      Card,
      TabCard,
      InputField,
      Button,
      SelectInput,
      RadioInput,
      TextareaInput,
      Income,
      AssetDissipation,
      DataCollection,
      Expenses,
      CashFlow,
    },
    props: {
      loanId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        formData: {
          id: null,
          residualIncomeResult: null,
          creditHistoryResult: null,
          propertyChargePaymentHistoryResult: null,
          overallFinancialAssessmentResult: null,
          underwritingAnalysis: null,
          extenuatingDescription: null,
        },
        localData: {
          id: null,
          residualIncomeResult: null,
          creditHistoryResult: null,
          propertyChargePaymentHistoryResult: null,
          overallFinancialAssessmentResult: null,
          underwritingAnalysis: null,
          extenuatingDescription: null,
        },
        residualIncomeResultOptions: [
          { label: 'Acceptable', value: 'Acceptable' },
          {
            label: 'Acceptable with Compensating Factors',
            value: 'Acceptable with Compensating Factors',
          },
          { label: 'Not Acceptable', value: 'Not Acceptable' },
        ],
        creditHistoryResultOptions: [
          { label: 'Acceptable', value: 'Acceptable' },
          {
            label: 'Acceptable with Extenuating Circumstances',
            value: 'Acceptable with Extenuating Circumstances',
          },
          { label: 'Not Acceptable', value: 'Not Acceptable' },
        ],
        propertyChargePaymentHistoryResultOptions: [
          { label: 'Acceptable', value: 'Acceptable' },
          {
            label: 'Acceptable with Extenuating Circumstance',
            value: 'Acceptable with Compensating Factors',
          },
          { label: 'Not Acceptable', value: 'Not Acceptable' },
        ],
        overallFinancialAssessmentResultOptions: [
          { label: 'Acceptable', value: 'Acceptable' },
          {
            label: 'Acceptable with Compensating Factors or Extenuating Circumstances',
            value: 'Acceptable with Compensating Factors or Extenuating Circumstances',
          },
          { label: 'Not Acceptable', value: 'Not Acceptable' },
        ],
        tabOptions: [
          { title: 'Income', component: Income },
          { title: 'Asset Dissipation', component: AssetDissipation },
          { title: 'Data Collection', component: DataCollection },
          { title: 'Expenses', component: Expenses },
          { title: 'Cash Flow', component: CashFlow },
        ],
        activeTab: { title: 'Income', isActive: true },
      };
    },
    async created() {
      await this.fetchFinancialAssessment();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchFinancialAssessment() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/financial_assessments/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) this.localData = response.data[0];
          else {
            this.resetLocalData();
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateForm() {
        return true;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        try {
          this.setLoading(true);
          if (this.localData.id)
            await apiService.patch(`/loan/financial_assessments/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/financial_assessments/`, {
              ...this.formData,
              loan: this.loanId,
            });
          this.$root.showSnackbar(`Financial assessments information has been updated successfully!`, 'success');
          await this.fetchFinancialAssessment();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      resetLocalData() {
        this.localData = {
          id: null,
          residualIncomeResult: null,
          creditHistoryResult: null,
          propertyChargePaymentHistoryResult: null,
          overallFinancialAssessmentResult: null,
          underwritingAnalysis: null,
          extenuatingDescription: null,
        };
      },
    },
    watch: {
      loanId: {
        handler(newValue, oldValue) {
          if (newValue && oldValue) {
            this.fetchFinancialAssessment();
          }
        },
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
    },
  };
</script>
