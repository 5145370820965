<template>
  <div class="header flex justify-between items-center py-[10px] px-[10px]">
    <font-awesome-icon icon="bars" @click="toggleSidebar" class="cursor-pointer" />
    <UserDropDown />
  </div>
</template>

<script>
  import UserDropDown from './components/UserDropDown.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Header',
    components: {
      UserDropDown,
    },
    methods: {
      toggleSidebar() {
        this.$emit('toggleSidebar');
      },
    },
  };
</script>
