<template>
  <div>
    <label class="font-semibold text-[#212121] mb-[10px]">{{ label }}</label>
    <div class="flex items-center">
      <div v-for="option in options" :key="option.value" class="flex items-center mr-15">
        <input
          type="radio"
          :id="`${id}-${option.value}`"
          :name="id"
          :value="option.value"
          v-model="inputValue"
          @change="onInputChange"
          class="custom-radio appearance-none outline-none relative p-0 rounded-full w-6 h-6 border-dark-gray border-2 cursor-pointer"
        />
        <p :for="`${id}-${option.value}`" class="m-0 ml-2">
          {{ option.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RadioInput',
    props: {
      label: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      options: {
        type: Array,
        required: true,
        validator: (options) => {
          return options.every((option) => 'label' in option && 'value' in option);
        },
      },
    },
    data() {
      return {
        inputValue: this.value,
      };
    },
    watch: {
      value(newVal) {
        this.inputValue = newVal;
      },
    },
    methods: {
      onInputChange() {
        this.$emit('update:modelValue', this.inputValue);
      },
    },
  };
</script>

<style>
  .custom-radio:checked {
    border-color: #215065;
  }
  .custom-radio:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #215065;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
