<template>
  <div class="autocomplete-field">
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">
      {{ label }}
    </label>
    <input
      :id="id"
      :type="type"
      :value="internalValue"
      :maxlength="maxLength"
      :class="{ invalid: !isValid && !disableValidation }"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="handleInput"
      @focus="showSuggestions = true"
      @blur="handleBlur"
    />
    <ul v-if="showSuggestions && filteredItems.length > 0" class="suggestions-list">
      <li v-for="(item, index) in filteredItems" :key="index" @mousedown.prevent="selectItem(item)" class="suggestion-item">
        {{ item }}
      </li>
    </ul>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'AutoCompleteField',
    props: {
      label: String,
      type: {
        type: String,
        default: 'text',
      },
      id: String,
      value: [String, Number],
      disableValidation: Boolean,
      disabled: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
        showSuggestions: false,
      };
    },
    computed: {
      filteredItems() {
        if (!this.internalValue) return this.items;
        return this.items.filter((item) => item.toLowerCase().includes(this.internalValue.toLowerCase()));
      },
      // eslint-disable-next-line vue/return-in-computed-property
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
      },
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.internalValue !== '';
      },
      handleInput(event) {
        this.internalValue = event.target.value;
        this.$emit('update:modelValue', this.internalValue);
        this.validateInput();
        this.showSuggestions = true; // Show suggestions as user types
      },
      handleBlur() {
        // Delay to allow selection of an item before hiding suggestions
        setTimeout(() => {
          this.showSuggestions = false;
        }, 100);
      },
      selectItem(item) {
        this.internalValue = item;
        this.$emit('update:modelValue', item);
        this.showSuggestions = false;
        this.validateInput();
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
    },
  };
</script>

<style>
  .autocomplete-field {
    position: relative;
  }
  .suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 100px;
    overflow-y: auto;
    z-index: 1000;
  }
  .suggestion-item {
    padding: 2px 4px;
    cursor: pointer;
  }
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  .invalid {
    border-color: red;
  }
</style>
