<template>
  <Card>
    <div class="overflow-x-auto border-b pb-15 mb-15">
      <table class="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Credit Report Opened By :</th>
            <td class="text-left px-6 py-4">
              {{ localData.creditReportOpenedBy }}
            </td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Credit Report Opened On :</th>
            <td class="text-left px-6 py-4">
              {{ localData.creditReportOpenedOn }}
            </td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Services Provider :</th>
            <td class="text-left px-6 py-4">
              {{ localData.servicesProvider }}
            </td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Experian</th>
            <td class="text-left px-6 py-4">{{ localData.experian }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Equifax</th>
            <td class="text-left px-6 py-4">{{ localData.equifax }}</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">TransUnion</th>
            <td class="text-left px-6 py-4">{{ localData.transunion }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end mt-2 gap-2">
        <Button variant="secondary" @click="handleOrderCreditReport"> Order Credit Report </Button>
        <Button variant="primary"> Credit Report PDF </Button>
      </div>
    </div>
    <PageHeader title="Credit Report" />
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 border-b pb-15 mb-15">
      <div>
        <InputField
          label="Opened By"
          id="creditReportOpenedBy"
          ref="creditReportOpenedBy"
          :value="formData.creditReportOpenedBy"
          v-model.trim="formData.creditReportOpenedBy"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Opened On"
          id="creditReportOpenedOn"
          ref="creditReportOpenedOn"
          :value="formData.creditReportOpenedOn"
          v-model.trim="formData.creditReportOpenedOn"
          :disable-validation="true"
        />
      </div>
      <div>
        <SelectInput
          label="Provider"
          id="servicesProvider"
          ref="servicesProvider"
          :options="serviceProviderOptions"
          :value="formData.servicesProvider"
          v-model.trim="formData.servicesProvider"
          :disable-validation="true"
        />
      </div>
      <div>
        <InputField label="Experian" id="experian" ref="experian" :value="formData.experian" v-model.trim="formData.experian" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Equifax" id="equifax" ref="equifax" :value="formData.equifax" v-model.trim="formData.equifax" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Transunion" id="transunion" ref="transunion" :value="formData.transunion" v-model.trim="formData.transunion" :disable-validation="true" />
      </div>
    </div>
    <PageHeader title="Account Information" />
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <InputField label="Account Name" id="accountName" ref="accountName" :value="formData.accountName" v-model.trim="formData.accountName" :disable-validation="true" />
      </div>
      <div>
        <SelectInput label="Account Status" id="accountStatus" ref="accountStatus" :options="statusOptions" :value="formData.accountStatus" v-model.trim="formData.accountStatus" />
        <span v-if="!formData.accountStatus && validationFormErrors.accountStatus" class="text-sm text-red">
          {{ validationFormErrors.accountStatus }}
        </span>
      </div>
      <div>
        <MoneyInput
          label="Unpaid Balance"
          id="unpaidBalance"
          ref="unpaidBalance"
          :value="formData.unpaidBalance"
          v-model.trim="formData.unpaidBalance"
          :disable-validation="true"
        />
      </div>
      <div>
        <SelectInput label="Account Type" id="accountType" ref="accountType" :options="accountTypeOptions" :value="formData.accountType" v-model.trim="formData.accountType" />
        <span v-if="!formData.accountType && validationFormErrors.accountType" class="text-sm text-red">
          {{ validationFormErrors.accountType }}
        </span>
      </div>
      <div>
        <MoneyInput
          label="Monthly Payment"
          id="monthlyPayment"
          ref="monthlyPayment"
          :value="formData.monthlyPayment"
          v-model.trim="formData.monthlyPayment"
          :disable-validation="true"
        />
        <span v-if="!formData.monthlyPayment && validationFormErrors.monthlyPayment" class="text-sm text-red">
          {{ validationFormErrors.monthlyPayment }}
        </span>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Button from '@/components/Button/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Credit',
    components: {
      Card,
      DateInput,
      SelectInput,
      MoneyInput,
      InputField,
      Button,
      PageHeader,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        formData: {
          creditReportOpenedBy: null,
          creditReportOpenedOn: null,
          servicesProvider: null,
          equifax: null,
          experian: null,
          transunion: null,
          accountName: null,
          accountStatus: null,
          unpaidBalance: null,
          accountType: null,
          monthlyPayment: null,
        },
        localData: {
          creditReportOpenedBy: null,
          creditReportOpenedOn: null,
          servicesProvider: null,
          equifax: null,
          experian: null,
          transunion: null,
          accountName: null,
          accountStatus: null,
          unpaidBalance: null,
          accountType: null,
          monthlyPayment: null,
        },
        serviceProviderOptions: [
          { label: 'CIC Credit', value: 'CIC Credit' },
          { label: 'Experian', value: 'Experian' },
          { label: 'TransUnion', value: 'TransUnion' },
        ],
        accountTypeOptions: [
          { label: 'Installment', value: 'Installment' },
          { label: 'Revolving', value: 'Revolving' },
          { label: 'Charge-Off', value: 'Charge-Off' },
          { label: 'Collection', value: 'Collection' },
          { label: 'Automobile', value: 'Automobile' },
          { label: 'Home Equity Line of Credit', value: 'Home Equity Line of Credit' },
          { label: 'Mortgage', value: 'Mortgage' },
        ],
        statusOptions: [
          { label: 'Open', value: 'Open' },
          { label: 'Paid', value: 'Paid' },
        ],
        validationFormErrors: {
          accountType: '',
          accountStatus: '',
          monthlyPayment: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      // Validate form data: Account Type, Account Status, Monthly payment amount.
      validateForm() {
        this.validationFormErrors = {
          accountType: !this.formData.accountType ? 'This field is required.' : '',
          accountStatus: !this.formData.accountStatus ? 'This field is required.' : '',
          monthlyPayment: !this.formData.monthlyPayment ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.accountType && !this.validationFormErrors.accountStatus && !this.validationFormErrors.monthlyPayment;
      },
      async fetchCredit() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/credit_reports/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.borrowerId) return;
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.localData.id) await apiService.patch(`/loan/credit_reports/${this.localData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/credit_reports/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Credit information has been updated successfully!`, 'success');
          await this.fetchCredit();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
        this.resetValidationErrors();
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      resetLocalData() {
        this.localData = {
          creditReportOpenedBy: null,
          creditReportOpenedOn: null,
          servicesProvider: null,
          equifax: null,
          experian: null,
          transunion: null,
          accountName: null,
          accountStatus: null,
          unpaidBalance: null,
          accountType: null,
          monthlyPayment: null,
        };
      },
      resetValidationErrors() {
        this.validationFormErrors = {
          accountType: '',
          accountStatus: '',
          monthlyPayment: '',
        };
      },
      handleOrderCreditReport() {},
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchCredit();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
