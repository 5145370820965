<template>
  <div>
    <table class="table">
      <!-- Table headers -->
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.key">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <!-- Table body -->
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td :colspan="headers.length" class="!text-center">
            <div class="icon">
              <img src="../../assets//image/info.svg" alt="icon" class="mx-auto mb-1" />
              <span>No data available</span>
              <slot name="no-data"></slot>
            </div>
          </td>
        </tr>
        <tr v-for="row in paginatedData" :key="row.id">
          <td v-for="header in headers" :key="header.key">
            <!-- Boolean Tag -->
            <span v-if="typeof row[header.key] == 'boolean'">
              <TagComponent :title="row[header.key] ? 'Yes' : 'No'" />
            </span>
            <!-- Status Tag -->
            <span v-else-if="header.key === 'status'">
              <TagComponent :color="row[header.key]" :title="row[header.key]" />
            </span>
            <!-- Active Checkbox -->
            <div v-else-if="header.key === 'active'">
              <CheckboxInput :value="row[header.key]" />
            </div>
            <!-- Date Input -->
            <div v-else-if="header.key === 'date'">
              <input type="date" :value="formattedDate(row.date)" />
            </div>
            <div v-else-if="header.type === 'money'">
              <span class="word-break"> ${{ row[header.key] }} </span>
            </div>
            <!-- Action Buttons -->
            <div class="flex items-center gap-2" v-else-if="header.key === 'action'">
              <button class="icon-button bg-primary" v-if="row.actions?.includes('edit')" @click="$emit('action', 'edit', row)">
                <font-awesome-icon icon="fas fa-pen-to-square" />
              </button>
              <button class="icon-button bg-secondary" v-if="row.actions?.includes('print')" @click="$emit('action', 'print', row)">
                <font-awesome-icon icon="print" />
              </button>
              <button class="icon-button bg-primary" v-if="row.actions?.includes('view')" @click="$emit('action', 'view', row)">
                <font-awesome-icon icon="arrow-up-right-from-square" />
              </button>
              <button class="icon-button bg-red" v-if="row.actions?.includes('delete')" @click="$emit('action', 'delete', row)">
                <font-awesome-icon icon="trash-can" />
              </button>
              <slot name="custom" v-bind:row="row"></slot>
            </div>
            <!-- Default Cell -->
            <span class="word-break" v-else>
              {{ row[header.key] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div v-if="totalPages > 1" class="pagination mt-4">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span v-for="page in displayedPages" :key="page">
        <button @click="goToPage(page)" :class="{ active: currentPage === page }">
          {{ page }}
        </button>
      </span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
  import TagComponent from './../TagComponent/index.vue';
  import CheckboxInput from './../FormInput/Checkbox/index.vue';

  export default {
    name: 'DataTable',
    components: {
      TagComponent,
      CheckboxInput,
    },
    props: {
      headers: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        currentPage: 1,
        itemsPerPage: 5,
        maxDisplayedPages: 5,
      };
    },
    computed: {
      formattedDate() {
        return (dateString) => {
          return new Date(dateString).toISOString().slice(0, 10);
        };
      },
      paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.data.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.data.length / this.itemsPerPage);
      },
      displayedPages() {
        const pageCount = this.totalPages;
        const currentPage = this.currentPage;
        const maxDisplayedPages = this.maxDisplayedPages;
        let startPage = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
        let endPage = Math.min(pageCount, startPage + maxDisplayedPages - 1);

        if (endPage - startPage < maxDisplayedPages - 1) {
          startPage = Math.max(1, endPage - maxDisplayedPages + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
      },
    },
    methods: {
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
    },
  };
</script>

<style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table tr:nth-child(2n) {
    background-color: #f6f9fc;
  }

  .table th,
  .table td {
    padding: 11px 15px;
    font-size: 14px;
    text-align: left;
    border: 1px solid #f3f3f3;
  }

  .pagination button {
    border: 1px solid #ebebeb;
    padding: 7px 15px;
    margin-right: -1px;
  }

  .pagination span button {
    padding: 7px 12px;
  }

  .pagination .active {
    color: #fff;
    border-color: #e78724;
    background-color: #e78724;
  }

  .pagination {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    color: #000;
  }

  .word-break {
    word-break: break-word;
  }
</style>
