const initialFormData = {
  useAppraisalForCalculations: null,
  numberOfUnits: null,
  appraisedValue: null,
  appraisalLessLand: null,
  appraisalCostNew: null,
  propertySquareFootage: null,
  propertyAcreage: null,
  monthBuilt: null,
  yearBuilt: null,
  structureAge: null,
  remainingEconomicLife: null,
  dwellingType: null,
  livingUnits: null,
  bedrooms: null,
  bathrooms: null,
  totalRooms: null,
  livingAreaSquareFootage: null,
  parkingFacility: null,
  foundationType: null,
  buildingStructure: null,
  airConditioning: null,
  floodZoneCode: null,
  neighborhoodType: null,
  averageDwellingPrice: null,
  percentSingleFamily: null,
  percent2To4Unit: null,
  percentMultiFamily: null,
  percentCommercial: null,
  presidentialDeclaredArea: null,
  damaged: null,
  dangerThresholdMet: null,
  decliningMarketValue: null,
  firstName: null,
  lastName: null,
  middleName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zip: null,
  mobileNumber: null,
  email: null,
  license: null,
  representsFairAnalysis: null,
  representsFairAnalysisExplanation: null,
  comparablesAcceptable: null,
  comparablesAcceptableExplanation: null,
  adjustmentsAcceptable: null,
  adjustmentsAcceptableExplanation: null,
  valueAcceptableForFhaHudPurposes: null,
  valueAcceptableForFhaHudPurposesExplanation: null,
  ifNotShouldItBeCorrected: null,
  ifNotShouldItBeCorrectedExplanation: null,
  valueForFhaHudPurposes: null,
  valueForFhaHudPurposesExplanation: null,
  repairConditions: null,
  otherComments: null,
};

export { initialFormData };
