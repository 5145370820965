<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pt-3 mt-3 gap-3">
    <div>
      <InputField label="Creditor Name" id="creditorName" ref="creditorName" :value="formData.creditorName" v-model.trim="formData.creditorName" :disable-validation="true" />
      <span v-if="validationFormErrors.creditorName.length > 0" class="text-sm text-red">
        {{ validationFormErrors.creditorName }}
      </span>
    </div>
    <div>
      <InputField label="Account Number" id="accountNumber" ref="accountNumber" :value="formData.accountNumber" v-model.trim="formData.accountNumber" :disable-validation="true" />
    </div>
    <div>
      <MoneyInput label="Balance Due" id="balanceDue" ref="balanceDue" :value="formData.balanceDue" v-model="formData.balanceDue" :disable-validation="true" />
    </div>
    <div>
      <MoneyInput label="Per Diem" id="perDiem" ref="perDiem" :value="formData.perDiem" v-model="formData.perDiem" :disable-validation="true" />
    </div>
    <div>
      <SelectInput label="Type" id="type" v-model="formData.type" :value="formData.type" :options="typeOptions" :disable-validation="true" />
    </div>
    <div>
      <DateInput label="Expiration Date" id="expirationDate" ref="expirationDate" v-model="formData.expirationDate" :value="formData.expirationDate" :disable-validation="true" />
      <span v-if="validationFormErrors.expirationDate.length > 0" class="text-sm text-red">
        {{ validationFormErrors.expirationDate }}
      </span>
    </div>
  </div>
  <div class="flex justify-end gap-4 mt-5 pt-5 border-t">
    <Button variant="primary" style="width: fit-content" @click="handleSaveChanges"> Save Changes </Button>
    <Button variant="secondary" style="width: fit-content" @click="handleDeletePayoff"> Delete </Button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import { PAYOFF_TYPES } from '@/constants/index.js';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Payoff',
    components: {
      Button,
      InputField,
      MoneyInput,
      SelectInput,
      DateInput,
    },
    props: {
      payoffId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        formData: {},
        localData: {},
        typeOptions: PAYOFF_TYPES,
        validationFormErrors: {
          creditorName: '',
          expirationDate: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      validateForm() {
        this.validationFormErrors = {
          creditorName: !this.formData.creditorName ? 'This field is required.' : '',
          expirationDate: !this.formData.expirationDate ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.creditorName.length && !this.validationFormErrors.expirationDate.length;
      },
      // Fetch Payoffs data by using API service
      async fetchPayoff() {
        if (!this.payoffId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/payoffs/${this.payoffId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          this.localData = {};
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.payoffs = [];
        } finally {
          this.setLoading(false);
        }
      },
      // Save Changes by using API service
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/payoffs/${this.payoffId}/`, {
            ...this.formData,
          });
          this.$root.showSnackbar(`Payoff information has been updated successfully!`, 'success');
          this.$emit('refresh');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Delete by using API service
      handleDeletePayoff() {
        // Call parent's delete methods with payoffId
        this.$emit('delete');
      },
    },
    watch: {
      payoffId: {
        async handler() {
          await this.fetchPayoff();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
