<template>
  <div>
    <label v-if="label" :for="id" class="font-semibold text-[#212121]">{{ label }}</label
    ><input type="date" :id="id" v-model="inputValue" @input="validateInput" :class="{ invalid: !isValid && !disableValidation }" />
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">{{ errorMessage }}</span>
  </div>
</template>

<script>
  export default {
    name: 'DateInput',
    props: {
      label: String,
      id: String,
      value: String, // Bind value prop to display default value
      disableValidation: Boolean,
    },
    data() {
      return {
        inputValue: this.value,
        isValid: true,
        isDirty: false,
      };
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue;
      },
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.inputValue !== '';
        this.$emit('update:modelValue', this.inputValue);
      },
      openCalendar(event) {
        event.target.showPicker(); // This opens the date picker on click or focus
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
        return '';
      },
    },
  };
</script>

<style>
  .invalid {
    border-color: red;
  }
  /* Add your custom styles here */
</style>
