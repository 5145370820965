<template>
  <div>
    <div class="flex items-center">
      <input type="checkbox" :id="id" :checked="isChecked" @change="handleChange" class="custom-checkbox" />
      <label :for="id" class="ml-[10px] text-[#212121] mb-0">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CheckboxInput',
    props: {
      label: String,
      id: String,
      value: Boolean,
    },
    data() {
      return {
        isChecked: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.isChecked = newValue;
      },
    },
    methods: {
      handleChange(event) {
        this.isChecked = event.target.checked;
        this.$emit('update:modelValue', this.isChecked);
      },
    },
  };
</script>

<style scoped>
  .custom-checkbox {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid #757575;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
  }

  .custom-checkbox:checked {
    background-color: #e78724;
    border-color: #e78724;
  }

  .custom-checkbox:checked::before {
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='10.5' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 10.5px 12px; /* Set size based on SVG dimensions */
    background-position: center; /* Center the icon */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
