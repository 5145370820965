<template>
  <div v-if="isLoanFailed" class="flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
      <img src="../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
      <h2 class="text-2xl font-semibold text-gray-800 mb-2">Invalid Loan ID</h2>
      <p class="text-gray-600 mb-4">The loan ID you entered is not valid or the loan is not associated with your account.</p>
      <div class="flex justify-center gap-4">
        <Button @click="goBack">Go Back</Button>
        <Button variant="secondary" @click="goToDashboard">Go to Dashboard</Button>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-[10px]" v-if="isLoanLoaded">
    <div v-if="showQuickCalculations" class="col-span-2">
      <QuickCalculations @close="toggleQuickCalculations" />
    </div>
    <div :class="showQuickCalculations ? 'col-span-10' : 'col-span-12'">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <button v-if="!showQuickCalculations" @click="toggleQuickCalculations">
            <font-awesome-icon icon="bars" />
          </button>
          <PageHeader :title="`${loan.primaryBorrower ? `${loan.primaryBorrower.firstName} ${loan.primaryBorrower.lastName}` : ''} #${loan.id}`" />
        </div>
        <div class="flex items-center">
          <!-- Print Document -->
          <Dropdown title="Print Document" className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full">
            <div
              class="cursor-pointer text-nowrap p-2 rounded-full bg-slate-100 hover:bg-slate-200"
              v-for="packageItem in packages"
              :key="packageItem.name"
              @click="handleDownloadPackage(packageItem.id)"
            >
              {{ packageItem.name }}
            </div>
          </Dropdown>

          <!-- Loan Status -->
          <Dropdown>
            <template v-slot:button>
              <TagComponent :title="loan.status" />
            </template>
            <div class="cursor-pointer text-nowrap" v-for="option in statusOptions" :key="option.value" @click="changeStatus(option.value)">
              <TagComponent :title="option.label" />
            </div>
          </Dropdown>
        </div>
      </div>
      <TabCard>
        <div class="flex items-center justify-between">
          <ul class="tabs flex items-center">
            <li
              v-for="tabOption in tabOptions"
              :key="tabOption.title"
              class="tab"
              :class="{ active: tabOption.title === activeTab.title }"
              @click="changeActiveTab(tabOption.title)"
            >
              {{ tabOption.title }}
            </li>
          </ul>
          <div class="flex items-center py-3 pl-2.5 pr-4 cursor-pointer" @click="goBack">
            <font-awesome-icon icon="arrow-left" class="cursor-pointer" />
          </div>
        </div>
      </TabCard>
      <div v-if="loan.id" class="content-container mt-15">
        <component :is="activeTabComponent" v-model:loanId="loan.id" @change="handleLoanDataUpdated" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import PageHeader from '@/components/PageHeader/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import Button from '@/components/Button/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import TagComponent from '@/components/TagComponent/index.vue';
  import QuickCalculations from './components/QuickCalculations/index.vue';
  import ActiveBorrower from './components/ActiveBorrower/index.vue';
  import Property from './components/Property/index.vue';
  import Fees from './components/Fees/index.vue';
  import CaseNumber from './components/CaseNumber/index.vue';
  import FinancialAssessment from './components/FinancialAssessment/index.vue';
  import Closing from './components/Closing/index.vue';
  import Comparison from './components/Comparison/index.vue';
  import Condition from './components/Condition/index.vue';

  export default {
    name: 'LoanDetail',
    components: {
      QuickCalculations,
      PageHeader,
      Button,
      Dropdown,
      TagComponent,
      TabCard,
      ActiveBorrower,
      Property,
      Fees,
      CaseNumber,
      FinancialAssessment,
      Closing,
      Comparison,
      Condition,
    },
    created() {
      this.fetchPackages();
    },
    data() {
      return {
        loan: undefined,
        packages: [],
        tabOptions: [
          { title: 'Borrowers', component: ActiveBorrower },
          { title: 'Property', component: Property },
          { title: 'Fees', component: Fees },
          { title: 'Case Number', component: CaseNumber },
          { title: 'Financial Assessment', component: FinancialAssessment },
          { title: 'Closing', component: Closing },
          { title: 'Comparison', component: Comparison },
          { title: 'Condition', component: Condition },
        ],
        statusOptions: [
          { label: 'Adversed', value: 'Adversed' },
          { label: 'Prospect', value: 'Prospect' },
          { label: 'Origination', value: 'Origination' },
          { label: 'Processing', value: 'Processing' },
          { label: 'Underwriting', value: 'Underwriting' },
          { label: 'Submitted to Underwriting', value: 'Submitted to Underwriting' },
          { label: 'Clear to Close', value: 'Clear to Close' },
          { label: 'Closing', value: 'Closing' },
          { label: 'Funding', value: 'Funding' },
          { label: 'Post Closing', value: 'Post Closing' },
          { label: 'PrePurchaseQC', value: 'PrePurchaseQC' },
          { label: 'PrePurchase', value: 'PrePurchase' },
          { label: 'Sold', value: 'Sold' },
          { label: 'Insured', value: 'Insured' },
        ],
        activeTab: { title: 'Borrowers', isActive: true },
        showQuickCalculations: true, // Show QuickCalculations by default
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      changeStatus(status) {
        if (status != this.loan.status) this.updateLoanStatus(status);
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      toggleQuickCalculations() {
        this.showQuickCalculations = !this.showQuickCalculations;
      },
      async fetchPackages() {
        try {
          const response = await apiService.get('/documents/packages/');
          this.packages = response.data;
        } catch (error) {
          this.packages = [];
          this.$root.showSnackbar(`Failed to fetch document packages: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchLoan() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/loans/${this.$route.params.id}/`);
          this.loan = response.data;
        } catch (error) {
          this.loan = {};
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async updateLoanStatus(newStatus) {
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/loans/${this.loan.id}/`, {
            status: newStatus,
          });
          this.$root.showSnackbar(`Loan status has been updated successfully!`, 'success');
          await this.fetchLoan();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.status ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleDownloadPackage(packageId) {
        this.setLoading(true);
        try {
          const response = await apiService.get(
            `/documents/packages/${packageId}/print-package/${this.$route.params.id}/`,
            {},
            {
              responseType: 'blob', // Expect a blob response from the server
            }
          );

          if (!response || !response.data) {
            throw new Error('Failed to receive valid data from the server.');
          }

          const packageName = this.packages.find((item) => item.id == packageId).name;
          let fileName = `${packageName}.pdf`;

          // Access the Content-Disposition header
          const contentDisposition = response.headers['content-disposition'];
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length === 2) {
              fileName = fileNameMatch[1];
            }
          }

          if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1];
            }
          }

          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);

          this.$root.showSnackbar('Document package downloaded successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`Failed to download document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      goBack() {
        this.$router.push({ name: 'FindLoan' }); // Go back to the find loan
      },
      goToDashboard() {
        this.$router.push({ name: 'Dashboard' }); // Navigate to the Dashboard
      },
      handleLoanDataUpdated() {
        this.fetchLoan();
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
      isLoanFailed() {
        return !!this.loan && Object.keys(this.loan).length === 0;
      },
      isLoanLoaded() {
        return !!this.loan && Object.keys(this.loan).length > 0;
      },
    },
    watch: {
      '$route.params.id': {
        handler(newId, oldId) {
          if (newId !== oldId) {
            this.fetchLoan();
          }
        },
        immediate: true,
      },
      loan: {
        handler(newVal) {
          if (newVal.id) {
            const firstBorrower = newVal.borrowers?.[0] ?? null;
            const newTitleName = firstBorrower ? `${firstBorrower.firstName} ${firstBorrower.lastName}` : 'New Loan';
            const formattedId = String(newVal.id).padStart(6, '0');
            document.title = `${newTitleName} # ${formattedId}`;
          }
        },
      },
    },
  };
</script>

<style scoped></style>
