<template>
  <Card title="Recent Transactions">
    <ul class="relative pl-5">
      <li class="mb-4 font-medium relative">
        <div class="w-5 h-5 rounded-full flex items-center justify-center" style="background-color: rgba(16, 72, 98, 0.1)">
          <div class="w-2 h-2 rounded-full bg-primary"></div>
        </div>
        <p>
          Update of calendar events &
          <span class="text-primary">Added new events in next week.</span>
        </p>
      </li>
      <li class="mb-4 font-medium relative">
        <div class="w-5 h-5 rounded-full flex items-center justify-center" style="background-color: rgba(231, 135, 36, 0.1)">
          <div class="w-2 h-2 rounded-full bg-[#E78724]"></div>
        </div>
        <p>
          Update of calendar events &
          <span class="text-primary">Added new events in next week.</span>
        </p>
      </li>
      <li class="mb-4 font-medium relative">
        <div class="w-5 h-5 rounded-full flex items-center justify-center" style="background-color: rgba(132, 90, 223, 0.1)">
          <div class="w-2 h-2 rounded-full bg-[#845ADF]"></div>
        </div>
        <p>
          Update of calendar events &
          <span class="text-primary">Added new events in next week.</span>
        </p>
      </li>
    </ul>
  </Card>
</template>

<script>
  import Card from './../../../../components/Card/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RecentTransactions',
    components: { Card },
  };
</script>
<style scoped>
  ul:before {
    content: '';
    width: 1px;
    border: 1px dashed #e5e5e5;
    height: 75%;
    position: absolute;
    left: 0;
    top: 10px;
  }
  li > div {
    position: absolute;
    left: -29px;
    top: 2px;
  }
</style>
