<template>
  <div class="flex grow h-full">
    <!-- Suggested Fields Section -->
    <div v-if="isLeftSidebarOpen" class="relative w-1/6 p-2 bg-gray-100 overflow-y-auto">
      <h3 class="text-md font-semibold mb-2">Suggested Fields</h3>
      <div v-for="(field, index) in fields" :key="index" class="py-1 mb-1">
        <div class="mb-1">**{{ field.title }}</div>
        <div
          v-for="(item, index) in field.items"
          :key="index"
          class="text-sm bg-gray-200 p-1 mb-1 cursor-pointer rounded"
          draggable="true"
          @dragstart="dragStart(item, field.title, field.key)"
        >
          {{ item.title }}
        </div>
      </div>
      <div></div>
    </div>

    <!-- Close left sidebar button -->
    <div v-else class="relative">
      <button class="absolute top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-r-md z-10" @click="toggleSidebar('left')">
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>
    </div>

    <!-- PDF Area Section -->
    <div
      :class="{
        'w-2/3': isLeftSidebarOpen && isRightSidebarOpen,
        'w-5/6': !isLeftSidebarOpen ^ !isRightSidebarOpen,
        'w-full': !isLeftSidebarOpen && !isRightSidebarOpen,
      }"
      class="h-full bg-white border relative"
      @click="handleAreaClick"
    >
      <!-- Zoom In and Out buttons -->
      <div class="absolute flex gap-1 z-10">
        <button @click="zoomOut" class="py-1 px-2 bg-white text-black rounded">
          <font-awesome-icon icon="fa-search-minus" />
        </button>
        <button @click="zoomIn" class="py-1 px-2 bg-white rounded">
          <font-awesome-icon icon="fa-search-plus" />
        </button>
      </div>

      <!-- Buttons to close left & right sidebars -->
      <button v-if="isLeftSidebarOpen" @click="toggleSidebar('left')" class="absolute top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-r-md z-10">
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
      <button v-if="isRightSidebarOpen" @click="toggleSidebar('right')" class="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-l-md z-10">
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>

      <div ref="dropArea" class="flex h-full bg-gray-300 overflow-auto py-2 relative" @drop.prevent="drop" @dragover.prevent>
        <!-- PDF Canvas Area -->
        <div ref="pdfContainer" class="flex flex-col gap-3 mx-auto">
          <!-- This is where the PDF pages will be rendered -->
        </div>

        <!-- Dropped fields -->
        <template v-if="!loading">
          <div
            v-for="(field, index) in droppedFields"
            :key="index"
            class="bg-yellow-200 shadow-lg absolute cursor-move border border-dashed select-none -translate-y-full"
            :style="{
              top: percentToPosition(field).y + 'px',
              left: percentToPosition(field).x + 'px',
              fontSize: field.fontSize * zoomLevel + 'px',
              borderColor: selectedFieldIndex === index ? 'black' : 'transparent',
            }"
            @mousedown="startDragExistingField($event, index, percentToPosition(field))"
          >
            <div class="relative leading-none">
              {{ field.title }}
              <div
                v-if="selectedFieldIndex === index"
                class="absolute flex justify-center items-center w-6 h-6 left-1/2 -top-2 -translate-x-1/2 -translate-y-full bg-red text-xs text-white rounded-md cursor-pointer"
                @click.stop="removeField(index)"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Properties Section -->
    <div v-if="isRightSidebarOpen" class="relative w-1/6 p-2 bg-gray-100 overflow-y-auto">
      <!-- Selected field's setting -->
      <div v-if="selectedFieldIndex !== null">
        <h3 class="text-md font-semibold mb-2 pb-2 border-b border-gray-300">{{ droppedFields[selectedFieldIndex].parentTitle }}</h3>
        <!-- Field's Name -->
        <h4 class="text-sm font-semibold mb-2">{{ droppedFields[selectedFieldIndex].title }} Property</h4>

        <div class="mb-2 pl-2">
          <!-- Font Setting -->
          <div @click="toggleSection('fontSetting')" class="w-full mb-2 cursor-pointer text-xs text-left font-semibold">Font Setting</div>
          <!-- Size Setting -->
          <div v-if="isSectionOpen.fontSetting" class="flex items-center gap-1 px-2 mb-2">
            <div class="text-xs block">Size:</div>
            <input type="number" v-model="droppedFields[selectedFieldIndex].fontSize" class="text-xs w-full p-1 border border-gray-300 rounded" />
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-sm">Select a field in the PDF area to adjust its properties.</p>
      </div>
    </div>

    <!-- Close right sidebar button -->
    <div v-else class="relative">
      <button @click="toggleSidebar('right')" class="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-l-md z-10">
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
    </div>
  </div>
</template>

<script>
  import * as pdfjsLib from 'pdfjs-dist/webpack';
  import { MODEL_FIELDS, DEFAULT_FONT_SIZE, DOCUMENT_GAP, PAGE_GAP } from '../const.js';
  export default {
    name: 'PDFEditor',
    props: {
      pdfLink: {
        type: [ArrayBuffer, String],
        required: true,
        default: () => '',
      },
      fieldsData: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        pdf: false,
        fields: MODEL_FIELDS,
        loading: true,
        dragInfo: null,
        zoomLevel: 1.0,
        droppedFields: this.fieldsData,
        selectedFieldIndex: null,
        isLeftSidebarOpen: true,
        isRightSidebarOpen: true,
        isSectionOpen: {
          fontSetting: true,
        },
      };
    },
    methods: {
      getPageXY() {
        const container = this.$refs.pdfContainer;
        const dropAreaRect = this.$refs.dropArea.getBoundingClientRect();
        const pageXY = [];
        const containerWidth = container.offsetWidth;
        const left = container.scrollLeft + DOCUMENT_GAP + dropAreaRect.width > containerWidth ? (dropAreaRect.width - containerWidth) / 2 : 0;
        for (const canvas of container.children) {
          if (pageXY.length === 0) {
            pageXY.push({
              left: left,
              top: DOCUMENT_GAP,
              width: canvas.width,
              height: canvas.height,
            });
          } else {
            const lastPage = pageXY[pageXY.length - 1];
            pageXY.push({
              left: left,
              top: lastPage.top + lastPage.height + PAGE_GAP,
              width: canvas.width,
              height: canvas.height,
            });
          }
        }
        return pageXY;
      },
      percentToPosition(dropField) {
        const pageXY = this.getPageXY();
        if (pageXY.length <= dropField.page) {
          return {
            x: 0,
            y: 0,
          };
        }
        const pageData = pageXY[dropField.page];
        return {
          x: pageData.left + dropField.x * pageData.width,
          y: pageData.top + dropField.y * pageData.height,
        };
      },
      // Zoom In or Out
      async zoomIn() {
        this.zoomLevel = Math.min(this.zoomLevel + 0.1, 2.0);
        await this.zoomCallback();
      },
      async zoomOut() {
        this.zoomLevel = Math.max(this.zoomLevel - 0.1, 0.5);
        await this.zoomCallback();
      },
      async zoomCallback() {
        await this.renderPdfContent();
        await this.reRenderDroppedFields();
      },
      // Handle dragging start for the new field from left sidebar
      dragStart(item, parentTitle, parentKey) {
        this.dragInfo = {
          field: {
            ...item,
            parentTitle,
            parentKey,
            fontSize: DEFAULT_FONT_SIZE,
          },
          type: 'new',
        };
      },
      startDragExistingField(event, index, { x, y }) {
        const dropAreaRect = this.$refs.dropArea.getBoundingClientRect();
        this.dragInfo = {
          index: index,
          offsetX: event.clientX - dropAreaRect.left - (x - this.$refs.dropArea.scrollLeft),
          offsetY: event.clientY - dropAreaRect.top - (y - this.$refs.dropArea.scrollTop),
          type: 'existing',
        };
        this.selectedFieldIndex = index;
        document.addEventListener('mousemove', this.dragging);
        document.addEventListener('mouseup', this.endDrag);
      },
      dragging(event) {
        if (!this.dragInfo || this.dragInfo.type !== 'existing') return;

        const pageXY = this.getPageXY();
        const dropAreaRect = this.$refs.dropArea.getBoundingClientRect();
        let page = 0;
        let posX = 0,
          posY = 0;
        const x = event.clientX - dropAreaRect.left + this.$refs.dropArea.scrollLeft - this.dragInfo.offsetX;
        const y = event.clientY - dropAreaRect.top + this.$refs.dropArea.scrollTop - this.dragInfo.offsetY;
        for (let i = pageXY.length - 1; i >= 0; i--) {
          if (y >= pageXY[i].top) {
            page = i;
            posX = (x - pageXY[i].left) / pageXY[i].width;
            posY = (y - pageXY[i].top) / pageXY[i].height;
            break;
          }
        }

        this.droppedFields[this.dragInfo.index] = {
          ...this.droppedFields[this.dragInfo.index],
          x: posX,
          y: posY,
          page: page,
        };
      },
      drop(event) {
        if (!this.dragInfo) return;

        if (this.dragInfo.type === 'new') {
          const pageXY = this.getPageXY();
          const dropAreaRect = this.$refs.dropArea.getBoundingClientRect();
          let page = 0;
          let posX = 0,
            posY = 0;
          const x = event.clientX - dropAreaRect.left + this.$refs.dropArea.scrollLeft;
          const y = event.clientY - dropAreaRect.top + this.$refs.dropArea.scrollTop;
          for (let i = pageXY.length - 1; i >= 0; i--) {
            if (y >= pageXY[i].top) {
              page = i;
              posX = (x - pageXY[i].left) / pageXY[i].width;
              posY = (y - pageXY[i].top) / pageXY[i].height;
              break;
            }
          }

          this.droppedFields.push({
            ...this.dragInfo.field,
            page: page,
            x: posX,
            y: posY,
          });
        }

        this.dragInfo = null;
        this.endDrag();
      },
      endDrag() {
        this.emitUpdateDroppedFields(); // Emit event to update pdf fields data of parent component
        this.dragInfo = null;
        document.removeEventListener('mousemove', this.dragging);
        document.removeEventListener('mouseup', this.endDrag);
      },
      removeField(index) {
        this.droppedFields.splice(index, 1);
        this.selectedFieldIndex = null;
        this.emitUpdateDroppedFields(); // Emit event to update pdf fields data of parent component after removing field
      },
      async emitUpdateDroppedFields() {
        console.log(this.droppedFields[0].x, this.droppedFields[0].y);
        await this.$nextTick();
        this.$emit('update:fieldsData', this.droppedFields);
      },
      toggleSection(section) {
        this.isSectionOpen[section] = !this.isSectionOpen[section];
      },
      async toggleSidebar(side) {
        if (side === 'left') {
          this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
        } else if (side === 'right') {
          this.isRightSidebarOpen = !this.isRightSidebarOpen;
        }
        await this.reRenderDroppedFields(); // Re-render dropped fields when sidebar actions
      },
      async reRenderDroppedFields() {
        await this.$nextTick();
        this.droppedFields = [...this.droppedFields];
      },
      handleAreaClick(event) {
        if (!event.target.closest('.bg-yellow-200')) {
          this.selectedFieldIndex = null;
        }
      },
      loadPdfContent() {
        if (!this.pdfLink) return;
        this.loading = true;
        try {
          pdfjsLib.getDocument(this.pdfLink).promise.then((pdf) => {
            this.pdf = () => pdf;
            this.renderPdfContent();
          });
        } catch (error) {
          console.error('Error loading PDF:', error);
          this.$root.showSnackbar(`Error loading PDF: ${error}`, 'error');
        }
      },
      async renderPdfContent() {
        if (!this.pdf || !this.$refs.pdfContainer) return;
        const container = this.$refs.pdfContainer;
        container.innerHTML = '';
        for (let pageNum = 1; pageNum <= this.pdf().numPages; pageNum++) {
          const page = await this.pdf().getPage(pageNum);
          const viewport = page.getViewport({ scale: this.zoomLevel });

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          container.appendChild(canvas);

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
        }
        this.loading = false;
      },
    },
    watch: {
      pdfLink: {
        async handler(newValue, prevValue) {
          this.zoomLevel = 1.0; // initialize the zoom level when the pdf link updated
          await this.loadPdfContent();
          if (newValue && prevValue) {
            // This means the case when load new pdf file at update case
            this.droppedFields = [];
            // Emit with newly initialized dropped fields
            this.emitUpdateDroppedFields();
          }
        },
        immediate: true,
      },
      fieldsData: {
        handler(newValue) {
          this.droppedFields = newValue;
        },
        immediate: true,
        depp: true,
      },
    },
  };
</script>

<style></style>
