<template>
  <div class="border border-gray p-15 bg-white rounded-lg shadow-card">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'TabCard',
  };
</script>
